<template>
    <v-card flat class="fill-height" v-bind="$attrs">
        <v-card-text>
            <v-layout :row="!vertical" :column="vertical">
                <v-flex v-if="showDocument">
                    <template v-if="isImage">
                        <v-layout row>
                            <v-spacer></v-spacer>
                            <img :src="fileUrl" :style="{'max-height': size + 'px'}"/>
                            <v-spacer></v-spacer>
                        </v-layout>
                        <v-layout row>
                        <v-spacer></v-spacer>
                            <div :style="fileNameStyle">
                                {{fileName}}
                            </div>
                        <v-spacer></v-spacer>
                    </v-layout>
                    </template>
                    <template v-else>
                        <v-layout row v-if="!isOpeningFile">
                            <v-spacer></v-spacer>
                            <v-icon :size="size" >{{ fileIcon }}</v-icon>
                            <v-spacer></v-spacer>
                        </v-layout>
                        <v-layout column justify-center align-center v-else :style="{height:size+'px'}">
                            <img :src="loaderGif" style="width:50px; height:50px;" />
                            <div>
                                {{$gettext("Opening file")}}
                            </div>
                        </v-layout>
                        <v-layout row>
                            <v-spacer></v-spacer>
                            <div :style="fileNameStyle">
                                {{fileName}}
                            </div>
                            <v-spacer></v-spacer>
                        </v-layout>
                    </template>
                </v-flex>
                <v-flex >
                    <v-layout v-if="isLoaded && !isOpeningFile" :column="!vertical" :row="vertical">
                        <template v-if="!vertical">
                            <v-btn color="info" v-if="isImage || isPdf" @click="openDialog" >
                                <av-icon light small left>{{ isImage? 'fa-expand': 'far fa-eye'}}</av-icon>
                                {{isImage ? $gettext("expand") : $gettext("view") }}
                            </v-btn>

                            <v-btn color="info" @click="downloadFile" >
                                <av-icon  light small left>fas fa-download</av-icon>
                                {{$gettext("download")}}
                            </v-btn>
                            <slot name="appendButton"></slot>
                        </template>
                        <template v-else>
                            <v-spacer v-if="!largeIcons"></v-spacer>
                            <v-btn flat icon small  v-if="isImage || isPdf" @click="openDialog" :class="largeIcons? 'mr-4': ''">
                                <av-icon color="info" :small="!largeIcons">{{ isImage && showDocument? 'fa-expand':'far fa-eye'}}</av-icon>
                            </v-btn>

                            <v-btn flat icon small @click="downloadFile" :class="largeIcons? 'mr-4': ''">
                                <av-icon color="info" :small="!largeIcons">fas fa-download</av-icon>
                            </v-btn>
                            <slot name="appendButton"></slot>
                            <v-spacer></v-spacer>
                        </template>
                    </v-layout>
                    <v-layout row v-else-if="loading">
                        <img :src="loaderGif" style="width:50px; height:50px;" />
                        <label class="subheading ma-3">{{$gettext("loading")}}</label>
                    </v-layout>
                    <v-layout row v-else-if="error">
                        <av-icon color="error">fas fa-times</av-icon>
                        <label class="title ml-2" style="color: red">{{$gettext("error")}}</label>
                    </v-layout>
                </v-flex>
            </v-layout>

            <v-dialog
                    v-model="dialogPreview"
                    :width="dialogWidth" persistent>

                <v-card >
                    <v-card-title class="grey lighten-2 pa-0">
                        <v-spacer></v-spacer>
                        <v-btn flat icon color="black" @click="closeDialog">
                            <v-icon>fas fa-times</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text>
                        <v-layout row v-if="isImage">
                            <v-spacer></v-spacer>
                                <img  :src="fileUrl" :style="{height:dialogHeight - 120+'px'}"/>
                            <v-spacer></v-spacer>
                        </v-layout>
                        <iframe v-if="isPdf" ref="pdfViewer" :style="{width:(dialogWidth - 30) +'px',height:(dialogHeight - 170) +'px'}" />
                    </v-card-text>

                </v-card>
            </v-dialog>

        </v-card-text>
    </v-card>
</template>

<script>

    import OrchestratorAPI from '@/api/orchestrator'
    import Microservices from '@/api/microservices'

    export default {
        name: "av-file-viewer",
        props: {
            type: {
                required: true,
                default: () => {
                    return ""
                }
            },
            data: {
                required: true,
                default: () => {
                    return ""
                }
            },
            fileName: {
                required: true,
                default: () => {
                    return ""
                }
            },
            mediaId: {
                required: true,
                default: () => {
                    return ""
                }
            },
            isBase64: {
                required: false,
                default: () => {
                    return true
                }
            },
            medium:{
                required:false,
                default:()=>{
                    return true
                }
            },
            small:{
                required:false,
                default:()=>{
                    return false
                }
            },
            extraSmall:{
                required:false,
                default:()=>{
                    return false
                }
            },
            vertical:{
                required:false,
                default:()=>{
                    return false
                }
            },
            showDocument:{
                required:false,
                default:()=>{
                    return true
                }
            },
            largeIcons: {
                required:false,
                default:()=>{
                    return false
                }
            }
        },
        data:()=>{
            return {
                dialogPreview:false,
                dialogHeight:0,
                dialogWidth:0,
                defaultSize:150,
                fileTypeIcons: {
                    html: 'fab fa-html5',
                    js: 'fab fa-node-js',
                    json: 'fa-file-code',
                    md: 'fab fa-markdown',
                    pdf: 'fa-file-pdf',
                    png: 'fa-file-image',
                    jpeg: 'fa-file-image',
                    jpg: 'fa-file-image',
                    bmp: 'fa-file-image',
                    raw: 'fa-file-image',
                    zip: 'fa-file-archive',
                    "7z": 'fa-file-archive',
                    rar: 'fa-file-archive',
                    txt: 'fa-file-alt',
                    xls: 'fa-file-excel',
                    storage: 'fa-hdd',
                    folder:'fa-folder',
                    exe:'fa-window-maximize',
                    pptx:'fa-file-powerpoint',
                    file:'fa-file'
                },
                error: false,
                loading: false,
                openingFile: false,
            }
        },
        computed:{
            loaderGif() { return require('@/assets/avloader.png') },
            isLoaded() {
                return this.data !== ""
            },
            fileUrl() {
                if (this.isPdf){
                    return URL.createObjectURL( this.pdfBlobConversion(this.data, 'application/pdf'));
                }
                return this.isBase64 ? "data:" + this.type + ";base64," + this.data : this.data
            },
            isImage(){
                return this.type.indexOf("image")>-1
            },
            isPdf(){
                return this.type.indexOf("pdf")>-1
            },
            fileIcon(){
                return this.getFileIcon(this.fileName.split('.')[this.fileName.split('.').length-1])
            },
            size(){
                if(this.extraSmall){
                    return this.defaultSize /3
                }
                if (this.small){
                    return this.defaultSize / 3 * 2
                }
                if (this.medium){
                    return this.defaultSize
                }
            },
            fontSize(){
                if(this.extraSmall){
                    return "0.5em"
                }
                if (this.small){
                    return "0.8em"
                }
                if (this.medium){
                    return "1em"
                }
            },
            fileNameWidth(){
                if(this.extraSmall){
                    return 200 /3
                }
                if (this.small){
                    return 200 / 3 * 2
                }
                if (this.medium){
                    return 200
                }
            },
            fileNameStyle(){
                return {
                    'max-width': this.fileNameWidth + 'px',
                    'text-align': 'center',
                    'overflow-x': 'hidden',
                    'overflow-y': 'hidden',
                    'word-wrap': 'break-word',
                    //"white-space":'pre-wap',
                    'font-size':this.fontSize
                }
            },
            isOpeningFile(){
                return this.openingFile
            }
        },
        mounted(){

            window.addEventListener("resize", this.handleResize)
            this.handleResize()
            let self = this;
            if(!this.data && this.mediaId) {
                this.loading = true;
                OrchestratorAPI.proxyCall('get', Microservices.documentsUrl + '/doc/media/default/' + this.mediaId)
                    .then((media) => {
                        if(Array.isUseful(media))
                            self.data = media[0].Media;
                        else throw("no data")
                    })
                    .catch((err) => {
                        self.error = true;
                        if(Microservices.isIndexEmptyError(err))
                            self.$root.showErrorNotification(self.$gettext("Unable to find file {0} content").format(self.fileName), true);
                        else self.$root.showErrorNotification(self.$gettext("An error occurred while retrieving file {0} content. Please try reloading page.").format(self.fileName), true);
                    })
                    .finally(() => {
                        self.loading = false;
                    })
            }
        },
        methods:{
            downloadFile(){
                const downloadLink = document.createElement("a");
                downloadLink.href = this.fileUrl;
                downloadLink.download = this.fileName;
                downloadLink.click();
            },
            getFileIcon(fileExtension){
                return this.fileTypeIcons[fileExtension]?this.fileTypeIcons[fileExtension]:this.fileTypeIcons['file'];
            },
            openDialog(){
                if (!this.isPdf){
                    this.dialogPreview=true
                    return
                }
                this.openingFile=true
                let self=this
                setTimeout(()=>{
                    self.openPdfFile()
                }, 200)

            },
            closeDialog() {
                if (this.isPdf) {
                    this.$refs.pdfViewer.src = ""
                }
                this.dialogPreview = false
            },
            async openPdfFile(){
                let blob=await this.pdfBlobConversion(this.data, 'application/pdf');
                this.$refs.pdfViewer.src=URL.createObjectURL(blob);

                this.dialogPreview=true
                this.openingFile=false
            },
            pdfBlobConversion(b64Data, contentType) {
                return new Promise((resolve,reject)=>{
                    contentType = contentType || '';
                    var sliceSize = 512;
                    b64Data = b64Data.replace(/^[^,]+,/, '');
                    b64Data = b64Data.replace(/\s/g, '');
                    var byteCharacters = window.atob(b64Data);
                    var byteArrays = [];

                    for ( var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize ) {
                        var slice = byteCharacters.slice(offset, offset + sliceSize);

                        var byteNumbers = new Array(slice.length);
                        for (var i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                        }

                        var byteArray = new Uint8Array(byteNumbers);

                        byteArrays.push(byteArray);
                    }
                    resolve(new Blob(byteArrays, { type: contentType }))
                })


            },
            handleResize(){
                this.dialogHeight=document.getElementById("app").clientHeight - 50;
                this.dialogWidth=document.getElementById("app").clientWidth - 150;
            },

        }
    }
</script>

<style scoped>

    iframe::-webkit-scrollbar {
        width: 1em;
    }

    iframe::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    iframe::-webkit-scrollbar-thumb {
        background-color: var(--av-lightblue);
        outline: 1px solid slategrey;
    }

    iframe::-webkit-scrollbar {
        width: 8px;
        height: 8px
    }

    iframe::-webkit-scrollbar-thumb:hover {
        background: var(--av-blue) !important;
    }

</style>