import OrchestratorAPI from "./orchestrator";
import Vue from 'vue'

let defaultGrants= {
    notifications: {
        lineStoppage: false,
        workOrderChange: false,
        newShift: false
    },
    operations: {
        workOrderSheduling: false,
        shiftChange: false,
        shiftScheduling: false,
        timeTrackingChange: false
    },
    dataAdjustments: {
        workOrderScheduling: false,
        shifts: false,
        counters: false,
        timeTracking: false,
    },
    settings: {
        lineIdentification: false,
        nominalSpeeds: false,
        standardOpeningTime: false,
        shifts: false,
        lineStatusThresholds: false,
        lineStopCausesEdit: false,
        lineStopCausesDelete: false,
        avionicsSystem: false,
        notifications: false,
        translations: false,
        alarmsDescriptions: false,
        opcUa: false,
        canEditOperationModes: false,
        machineSettings: false,
        sublinesSettings: false
    },
    dataExploration: {
        dataExplorer: false,
        reports: false,
        rules: false,
        rulesPriority:false,
        forms: false,
        entities: false,
        orphanTransactions: false,
        canManageActiveProductionData: false,
        CanEditTablesVisualizationProfiles: false,
    },
    recipes: {
        canEditTemplateRecipes: false,
        canEditMachineRecipes: false,
        canEditLineRecipes: false,
        canRequestActivation: false,
        canActivate: false
    },
    maintenance: {
        canVisualizeAssetsRecords: false,
        canVisualizeMaintenanceRecords: false,
        canModifyMaintenanceRecords: false,
        canModifyAssetsRecords: false,
        canDeleteMaintenanceRecords: false,
        canDeleteAssetsRecords: false,
    },
    activities: {
        canVisualizeActivities: false,
        canCreateActivities: false,
        canRescheduleActivities: false,
    },
    dashboards: {
        edit: false
    },
    info: {
        audits: false,
        system: false
    },
    groupsandprivileges:{
        canadmin:false
    },
    documentationPage: {
        canVisualizeDocumentationPage: false,
        canUserManageDocuments: false,
    },
    workordersPage: {
        canResetWorkorder: false,
        canSelectOperationMode: false
    }
};
let grantsUrl= "/api/v1/auth/groupsandprivileges/";

export default {
    grants:undefined,
    levels:[],
    defaultAdministratorKey:'av.administrator',
    init(){
        this.grants = {};
        for (let s in defaultGrants){
            for (let a in defaultGrants[s]){
                if (!this.grants[s])
                    this.grants[s]={};
                this.grants[s][a]=this.hasGrant(s,a)
            }
        }
        this.loadProfiles()
    },
    get(){
        return this.grants ? this.grants : defaultGrants
    },
    ready() {
        if(this.grants)
            return true;
        return false;
    },
    clear(){
        this.grants = undefined;
    },
    getLevel() {
        return Vue.prototype.$store.state.shared.profiles;
    },
    getLevels() {
        return this.levels;
    },
    loadProfiles() {
        OrchestratorAPI.proxyCall('get', grantsUrl + 'profiles')
            .then(result => {
                this.levels=result.map(p=>{
                    return {
                        key:p.Key,
                        show:p.Name
                    }
                })
            })
            .catch(error => {
                debugger
                console.error(error)
            });
    },
    hasLineSettings() {
        return (this.get().settings.lineIdentification || this.get().settings.nominalSpeeds ||
            this.get().settings.shifts || this.get().settings.lineStatusThresholds)
    },
    hasNotifications() {
        return (this.get().notifications.lineStoppage || this.get().notifications.newShift || this.get().notifications.workOrderChange);
    },
    hasWorkflow() {
        return (this.get().settings.notifications);
    },
    hasInfo() {
        return (this.get().info.audits || this.get().info.system);
    },
    hasRecipes() {
        return (this.get().recipes.canEditTemplateRecipes || this.get().recipes.canEditMachineRecipes || this.get().recipes.canEditLineRecipes);
    },
    hasMaintenance() {
        return (this.get().maintenance.canVisualizeAssetsRecords || this.get().maintenance.canVisualizeMaintenanceRecords);
    },
    isOldGrantsProfile(profiles) {
        if(Array.isUseful(profiles)) {
            if(profiles[0].hasOwnProperty("level"))
                return true;
        }
        return false;
    },
    isGrantedForAll(profiles) {
        return !Array.isUseful(profiles) || (profiles.length === 1 && profiles[0] === 'all')
    },
    enabledForVisible(selectedLevels) {
        if (this.isGrantedForAll(selectedLevels) || this.isOldGrantsProfile(selectedLevels))
            return true;
        return selectedLevels.some(user => this.getLevel().find(p=>{return p.key === user}));
    },
    enabledForEditable(selectedLevels) {
        if (this.isGrantedForAll(selectedLevels) || this.isOldGrantsProfile(selectedLevels))
            return true;
        return selectedLevels.some(user => this.getLevel().find(p=>{return p.key === user}));
    },
    hasGrant(section, action) {
        if (!Vue.prototype.$store.state.shared.modules[section]) {
            return false
        }
        let find = Vue.prototype.$store.state.shared.modules[section].find(i => {
            return i.Action === action
        });
        return find !== undefined
    },
    hasMatchingProfile(profiles) {
        if(!Array.isUseful(profiles))
            return false;
        let levels = this.getLevel().map(level => level.key);
        return profiles.some(profile => levels.includes(profile));
    },
    isAdmin() {
        return this.hasMatchingProfile([this.defaultAdministratorKey]);
    }
}
