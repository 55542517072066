<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer" class="page-container" grid-list-md text-xs-center
                 pa-3 ma-0 style="max-width: 100%;">
        <v-card class="mt-0 av-card" elevation="0" style="height: 80px;">
            <v-layout row wrap>
                <v-select class="ma-4 std-control" :items="languages" v-model="language" item-text="show" return-object :label="$gettext('Select language')" @change="loadLanguage($event)"/>
                <v-spacer></v-spacer>
                <v-btn class="my-4" @click="addLanguage()">
                    <av-icon color="#4caf50" left>fas fa-language</av-icon>
                    <translate>New language</translate>
                </v-btn>
                <v-btn class="my-4 ml-2" v-if="language && !language.predefined" @click="deleteLanguage()">
                    <av-icon color="red" left>fas fa-trash fa-5x</av-icon>
                    Delete language
                </v-btn>
                <ImportExportExcel class="ml-2 mr-4" style="margin-top: 22px" :text-icon-visible="true"
                                   :horizontal="true" :headers="exportFields" :data-collection="translations"
                                   :work-sheet="exportWorksheet" :file-name="exportFileName" @fileLoaded="importExcel"/>
            </v-layout>
        </v-card>
        <v-card class="mt-3 av-card" elevation="0" style="height: calc(100% - 96px)">
            <v-data-table ref="table" v-if="translations" :items="translations" :headers="headers" hide-actions
                          class="elevation-0 fixed-header v-table__overflow"
                          :style="'max-height: calc(100vh - 50px); backface-visibility: hidden; scrollTop: ' + scrollTop">
                <template v-slot:headers="props">
                    <tr style="border-bottom: none;">
                        <th class="text-xs-left title" v-for="header in props.headers">
                            {{ header.name }}
                        </th>
                    </tr>
                </template>
                <template slot="items" slot-scope="values">
                    <td style="border-bottom: none;" class="text-xs-left body-1" v-for="header in headers" @click="edit(values.index, header)">
                        <label :style="values.item.custom ? 'color: blue' : ''">{{ values.item[header.dataProperty] }}</label>
                    </td>
                </template>
            </v-data-table>
            <av-input-box id="av-inputbox-alarms" ref="inputBox"/>
        </v-card>
    </v-container>
</template>

<script>
    import UploadButton from 'vuetify-upload-button';
    import Vue from 'vue'
    import SourceStringsList from '@/translations/sourceStringsList.json'
    import ImportExportExcel from "@/components/utilities/ImportExportExcel";

    export default {
        name: "Translations",
        components: {'upload-btn': UploadButton, ImportExportExcel},
        data() {
            return {
                translations: [],
                userTranslations: null,
                languages: [],
                language: "",
                scroll: 100000,
                importedHeaders: null,
                importedRecords: null,
                actionTrail: "",
                actionTrailId: "",
                nextValTrail: "",
                previousValTrail: "",
            }
        },
        mounted: function () {
            if (this.$root.startedUp)
                this.loadTranslations();
        },
        computed: {
            exportFields() {
                if (Array.isUseful(this.translations)) {
                    let returning = [{label: "Source string", field: "source"}];
                    returning.push({label: this.language.key + ' - ' + this.language.show, field: "translation"});
                    return returning;
                }
                return [{}];
            },
            exportWorksheet() {
                return "{appName} translations".format({appName: this.$config.appName})
            },
            exportFileName() {
                return "{appName} {lang} translation {time}".format({appName: this.$config.appName, lang: this.language.show, time: (new Date()).format()});
            },
            scrollTop() {
                return this.scroll;
            },
            headers() {
                if (Array.isUseful(this.translations)) {
                    return [{
                        name: "Source string",
                        dataProperty: "source"
                    }, {name: this.language.key + " - " + this.language.show, dataProperty: "translation"}];
                }
                return [];
            }
        },
        methods: {
            loadTranslations() {
                for (let lang in this.$language.available) {
                    let predefinedLanguage = this.$i18n.getPredefinedLanguagesAvaliable().hasOwnProperty(lang);
                    this.languages.push({
                        key: lang,
                        show: this.$language.available[lang],
                        predefined: predefinedLanguage
                    })
                }
                let self = this;
                this.$settings.load(this.$settings.Translations)
                    .then(translations => {
                        if (translations)
                            self.userTranslations = translations;
                    })
                    .catch(() => {
                        debugger
                        self.userTranslations = null;
                    })
            },
            loadLanguage(lang) {
                let untranslated = [];
                let vtl = Vue.$translations[lang.key];
                if (!vtl)
                    Vue.$translations[lang.key] = {};
                this.translations.clear();
                for (let key in Vue.$translations[lang.key]) {
                    this.translations.push({source: key, translation: Vue.$translations[lang.key][key]});
                }
                for (let string of SourceStringsList) {
                    if (!Vue.$translations[lang.key][string] && !string.toLowerCase().includes("characters are not allowed")) //Special strings that contains bad handled escapes
                        untranslated.push({source: string, translation: ""});
                }
                untranslated = untranslated.sortOnProperty("source");
                this.translations = this.translations.sortOnProperty("source");
                this.translations = untranslated.concat(this.translations);
                //Mark custom user translations
                if (this.userTranslations) {
                    let keysList = this.translations.map((item) => {
                        return item.source
                    });
                    for (let key in this.userTranslations[lang.key]) {
                        let index = keysList.indexOf(key);
                        if (index >= 0)
                            this.translations[index]["custom"] = true;
                    }
                } //TODO Notify orphan custom translations
            },
            edit(index) {
                let self = this;
                this.$refs.inputBox.ShowInputBox(this.$gettext("Enter new translation for text: {0}").format(this.translations[index].source), "", this.translations[index]["translation"], "OK", function (value) {
                    self.textChanged(index, value)
                }, "Cancel", null)
            },
            textChanged(index, value) {
                //audits parameters
                this.actionTrail = this.$audits.items().translationEdited;
                this.actionTrailId = this.translations[index].source;
                this.previousValTrail = this.translations[index]["translation"];
                this.nextValTrail = value;

                this.translations[index]["translation"] = value;
                this.translations[index]["custom"] = true;
                let vtl = Vue.$translations;
                if (this.translations[index]["translation"] === '') {
                    delete Vue.$translations[this.language.key][this.translations[index]["source"]];
                }
                vtl = Vue.$translations;
                this.save();
            },
            save() {
                if(!this.userTranslations)
                    this.userTranslations = {};
                this.userTranslations[this.language.key] = {};
                for (let translation of this.translations)
                    if (translation.custom && translation.translation) {
                        this.userTranslations[this.language.key][translation.source] = translation.translation;
                        Vue.$translations[this.language.key][translation.source] = translation.translation;
                    }

                this.$settings.save(this.userTranslations, this.$settings.Translations)
                    .then(() => {
                        this.$audits.save(this.$root.userName, this.actionTrail, this.previousValTrail, this.nextValTrail, this.actionTrailId)
                            .catch(err => {
                                debugger
                                this.$root.showErrorNotification(this.$gettext("An error occurred while saving audits to DB"), true);
                            });
                    })
                    .catch(err => {
                        this.$root.showErrorNotification(this.$gettext("Error saving translations to DB."), true);
                    }).finally(() => {
                    this.languages.clear();
                    this.loadTranslations()
                })
            },
            addLanguage() {
                let self = this;
                this.$refs.inputBox.ShowInputBox(this.$gettext("Enter identifier of new language"), this.$gettext("Identifier must be a two digit language code like 'en' or 'de'"), "xx", "OK", function (value) {
                    self.addLanguageString(value)
                }, "Cancel", null);
            },
            addLanguageString(code) {
                let self = this;
                if (code.length !== 2) {
                    this.$root.showDialogBox(this.$gettext("Language code must be two digits long."), "", "OK", null, "", null);
                    return;
                }
                if (Vue.$translations[code])
                    this.$root.showDialogBox(this.$gettext("Requested language code {langCode} already available in {appName}").format({langCode:code, appName: this.$config.appName}), "", "OK", null, "", null);
                else
                    this.$refs.inputBox.ShowInputBox(this.$gettext("Enter display name of new language that will appear in the language selection dropdown"), this.$gettext("For example: Italiano or Français"), "Custom", "OK", function (value) {
                        self.createLanguage(code, value)
                    }, "Cancel", null);
            },
            createLanguage(code, show) {
                let self = this;

                //audits parameters
                this.actionTrail = this.$audits.items().translationNewLanguageAdded;
                this.actionTrailId = '';
                this.previousValTrail = '';
                this.nextValTrail = show;

                this.language = {key: code, show: show};
                this.translations.clear();
                for (let string of SourceStringsList)
                    this.translations.push({source: string, translation: "", custom: true});
                this.$language.available[this.language.key] = this.language.show;
                this.$settings.save(this.$language.available, this.$settings.Languages, this.$settings.Available)
                    .then(() => {
                        Vue.$translations[code] = {};
                        self.save();
                    })
                    .catch(() => {
                        debugger
                        this.$root.showErrorNotification(this.$gettext("Error saving translations to DB."), true);
                    });
            },
            importExcel(result) {
                let self = this;
                if (result.records) {
                    if (Object.isUseful(result.headers)) {
                        let langKey = result.headers[1].slice(0, 2);
                        if (langKey === "en")
                            langKey = "default";
                        if (self.$language.available.hasOwnProperty(langKey)) {
                            self.importedHeaders = result.headers;
                            self.importedRecords = result.records;
                            self.$root.showDialogBox(self.$gettext("Do you wish to override current translations with imported from file?"), "", "OK", self.showImported, "CANCEL", null);
                        } else {
                            self.$root.showErrorNotification(self.$gettext("Language with code {0} is not available, create one and then import the file").format(langKey), true);
                        }
                    }
                } else {
                    debugger
                    //TODO
                }
            },
            showImported() {
                if (this.importedHeaders[0].toLowerCase().trim() !== "source string")
                    return this.$root.showErrorNotification(this.$gettext("Unable to use imported excel. First column header must be <<Source string>>"));
                let langToks = this.importedHeaders[1].split("-");
                if ((langToks.length !== 2 || langToks[0].trim().length !== 2 || !langToks[1].trim()) && langToks[0].trim() !== 'default')
                    return this.$root.showErrorNotification(this.$gettext("Unable to use imported excel. Second column header must be a valid language identifier in the form <<langId - langName>>. For example: <<fr - Français>> or <<it - Italiano>>"));
                this.language = {key: langToks[0].trim(), show: langToks[1].trim()};
                this.translations.clear();
                for (let record of this.importedRecords)
                    this.translations.push({
                        source: record[this.importedHeaders[0]],
                        translation: record[this.importedHeaders[1]],
                        custom: true
                    }); //TODO find only changed strings to mark them as custom

                //audits parameters
                this.actionTrail = this.$audits.items().translationImported;
                this.actionTrailId = '';
                this.previousValTrail = '';
                this.nextValTrail = '';

                this.save()
            },
            deleteLanguage() {
                this.$root.showDialogBox(this.$gettext("Are you sure you want to delete this language?"), null, "Yes", this.delete, "CANCEL", null);
            },
            delete() {
                let self = this;

                //audits parameters
                this.actionTrail = this.$audits.items().translationLanguageDeleted;
                this.actionTrailId = '';
                this.previousValTrail = this.language.show;
                this.nextValTrail = "";

                delete this.$language.available[this.language.key];
                delete Vue.$translations[self.language.key];
                this.$settings.save(this.$language.available, this.$settings.Languages, this.$settings.Available)
                    .then(() => {
                        self.language = {};
                        self.translations.clear();
                        self.save();
                    })
                    .catch(() => {
                        debugger
                        this.$root.showErrorNotification("Error saving translations to DB.", true);
                    });
            }

        }
    }
</script>

<style>

    .theme--dark.v-table thead th {
        background-color: #424242;
    }

    .theme--light.v-table thead th {
        background-color: #fff;
    }

    /* Theme */
    .fixed-header {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .fixed-header table {
        /*table-layout: fixed;*/
    }

    .fixed-header th {
        position: sticky;
        top: 0;
        z-index: 5;
    }

    .fixed-header th:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }

    .fixed-header tr.v-datatable__progress th {
        height: 1px;
    }

    .fixed-header .v-table__overflow {
        flex-grow: 1;
        flex-shrink: 1;
        overflow-x: auto;
        overflow-y: auto;
    }

    .fixed-header .v-datatable.v-table {
        flex-grow: 0;
        flex-shrink: 1;
    }

    .fixed-header .v-datatable.v-table .v-datatable__actions {
        /*flex-wrap: nowrap;*/
    }

    .fixed-header .v-datatable.v-table .v-datatable__actions .v-datatable__actions__pagination {
        /*white-space: nowrap;*/
    }

</style>