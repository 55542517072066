<template>
    <iframe v-bind:src="dashToLoad" width="100%" height="100%" frameBorder="0">
    </iframe>
</template>

<script>
    export default {
        name: "ExternalDash",
        mounted: function () {
            //this.$root.setCurrentPageTitle("Data exploration");
        },
        computed: {
            dashToLoad() {
                return this.$route.params.routeId.replace(/£/g, "/").replace(/@/g, "#");
            },
            dashTitle() {
                this.$root.setCurrentPageTitle(this.$route.params.lineName.replace(/£/g, "/").replace(/@/g, "#"));
                return this.$route.params.lineName.replace(/£/g, "/").replace(/@/g, "#");
            }
        },
        watch: {
            dashTitle: {
                handler: function () {
                    this.$root.setCurrentPageTitle(this.$route.params.lineName.replace(/£/g, "/").replace(/@/g, "#"));
                }
            }
        }
    }
</script>

<style scoped>

</style>