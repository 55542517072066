<template>
    <v-dialog v-model="showDialog" width="720px" :fullscreen="$vuetify.smAndDown" :persistent="true" id="antares-annotation-dialog">
        <v-card id="antares-annotation-dialog-card" style="height: 600px">
            <v-card-title class="headline grey lighten-2">
                {{title}}
            </v-card-title>
           <v-card-text class="av-card-content" style="height: calc(100% - 130px); overflow-y: auto" >
               <label v-if="!error && confirmed" class="title font-weight-regular" >{{ getItemLabel() }}</label>
               <v-tabs v-else>
                   <v-spacer></v-spacer>
                   <v-tab>
                       {{$gettext('Workorder parameter')}}
                   </v-tab>
                   <v-tab v-if="recipeVariables.length>0">
                       {{$gettext('Recipe parameter')}}
                   </v-tab>
                   <v-spacer></v-spacer>
                   <v-tab-item>
                       <v-layout column pa-3>

                           <v-text-field v-if="!confirmed && setted" class="ma-4 osk" style="max-width: 300px;" :label="$gettext('Quantity to produce')"
                                         :value="workorder.QuantityToProduce" type="number" @change="activeNextButton(); changeQuantityToProduce(workorder, $event, 'QuantityToProduce')"></v-text-field>

                       </v-layout>

                   </v-tab-item>
                   <v-tab-item>
                       <v-list expand>
                            <v-list-group v-for="states in recipeVariables" >
                                <template v-slot:activator>
                                    <v-list-tile>
                                        <v-list-tile-title><h3>{{states.title}}</h3></v-list-tile-title>
                                    </v-list-tile>
                                </template>
                                    <v-list-tile v-for="variable in states.children">
                                        <v-list-tile-content class="pt-5 mb-2">
                                            <field v-model="variable.value" :variable="variable" :label="variable.label" :id="variable.name" @onVariableChange="activeNextButton" ></field>
                                        </v-list-tile-content>
                                    </v-list-tile>
                            </v-list-group>

                       </v-list>
                   </v-tab-item>
               </v-tabs>
           </v-card-text>
            <v-divider> </v-divider>
            <v-card-actions id="antares-annotation-dialog-card-actions">
                <v-spacer></v-spacer>
                <v-btn v-show="enableNextButton" color="green darken-1" flat="flat" @click="activeOkButton">
                    <translate>NEXT</translate>
                </v-btn>

                <v-btn v-show="enableOkButton" color="green darken-1" flat="flat" @click="goBack">
                    <translate>Back</translate>
                </v-btn>
                <v-btn v-show="enableOkButton" color="green darken-1" flat="flat" @click="callBack(true)">
                    <translate>OK</translate>
                </v-btn>
                <v-btn  color="red darken-1" flat="flat" @click="callBack(false)">
                    <translate>Cancel</translate>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import recipes from '@/api/recipes'
    import Field from '@/components/dynamic-elements/forms/Field'

    export default {
        name: "WorkorderFieldsEditingDialog",
        components:{Field},
        data () {
            return {
                showDialog: false,
                user: "",
                callerInstance: null,
                workorderCallback: null,
                title: this.$gettext("Edit workorder fields"),
                workorder: {},
                error: "",
                showLoader: false,
                message: "",
                picker: false,
                edited: false,
                confirmed: false,
                setted: false,
                listForAuditsTrail: new Map(),
                recipe:null,
                recipeSteps:null,
                recipeValues:[],
                editWorkorderValues: {},
            }
        },
        computed: {
            enableNextButton() {
                return this.edited && !this.confirmed
            },
            enableOkButton() {
                return this.confirmed
            },
            recipeVariables(){
                return this.recipeValues
            }
        },
        methods: {
            changeQuantityToProduce(item, event, fieldName) {
                let values = {"old": item.QuantityToProduce, "new": event};
                this.setListForAudits(fieldName, values);
                this.editWorkorderValues.QuantityToProduce = event;
            },
            setListForAudits(fieldName, values) {
                    this.listForAuditsTrail.set(fieldName, values);
            },
            activeNextButton() {
              this.edited = true;
            },
            activeOkButton() {
                this.edited = false;
                this.confirmed = true;
            },
            goBack(){
              this.confirmed=false;
              this.edited=true;
            },
            setCaller(caller) {
                this.callerInstance = caller;
            },
            callBack(result) {
                this.error ="";
                if (result) {
                    //Field used to saving parameters changes when  Its value is true. If false save a status change
                    this.workorder.UpdateOnly = true;
                    let self = this;

                    let physicalLineId = "";
                    if(this.$config.isL2())
                        physicalLineId = this.$settings.getLineSettings().physicalLineId;
                    if(this.$config.isL3())
                        physicalLineId = this.workorder.PhysicalLineId;
                    //set recipe to workorder
                    let recipe = recipes.wrapVariables(this.recipeValues.map(rv=>{
                        rv.children.map(rvv=>{
                            rvv.name=rvv.id
                            return rvv
                        })
                        return rv
                    }),this.recipeSteps);
                    if (Array.isUseful(recipe))
                        this.editWorkorderValues.recipe = recipe;
                    if(self.workorderCallBack)
                        self.workorderCallBack(this.workorder, physicalLineId,  this.listForAuditsTrail, this.editWorkorderValues);
                    self.showDialog = false;
                    if(self.callerInstance)
                        self.callerInstance.annotationDialogOpen = false;

                }
                else { // Close Dialog
                    this.showDialog = false;
                    if(this.callerInstance)
                        this.callerInstance.annotationDialogOpen = false;
                }
            },
            async Show(user, workorder, workorderCallBack, newStatuses) {
                this.showDialog = true;
                this.user = user;
                this.recipeSteps=[];
                this.recipeValues=[];
                this.recipeVariables=[];
                this.workorder = this.$utils.detach(workorder);
                this.workorderCallBack = workorderCallBack;
                this.setted = true;
                this.newStatuses=newStatuses;

            },
            getItemLabel() {
                return this.$gettext("Are you sure you want to save your changes to workorder {0}?").format(this.workorder.Id);
            },
            unwrapMachine(machine){
                let self=this;
                machine.configuration.forEach(configuration=>{
                    self.recipeSteps.push(...configuration.steps)
                    configuration.steps.forEach(step => {
                        let variable={
                            title: machine.id + " - " + step.name,
                            name:'',
                            description:step.description,
                            type: 'group',
                            inputMode:'Normal',
                            children:[]
                        }
                        let variables=[];
                        variables.push(...step.configuration.values.filter(value => {
                            return value.canChangeValueOnTransition
                        }));
                        variables.forEach(value=>{
                            let child=recipes.unwrapVariable(step,value);
                            child.label=child.title;
                            child.id=child.name
                            child.title=undefined;
                            child.name=undefined
                            if(Object.isUseful(self.workorder.recipe)){
                                let findValue= self.workorder.recipe.find(v=>{return v.id===value.id})
                                if(findValue)
                                    child.value=findValue.value;
                            }

                            variable.children.push(child);
                        })
                        self.recipeValues.push(variable)
                    })
                })

            }
        },
        watch:{
            setted:{
                async handler(){
                    if(this.workorder && this.setted) {
                        let self=this;
                        if(this.workorder.RecipeId){
                            this.recipe = await recipes.getById(this.workorder.RecipeId);
                            //if recipe is type line
                            if(this.recipe)
                                self.unwrapMachine(this.recipe)
                        }
                        if (this.workorder.MachineRecipes){
                            this.workorder.MachineRecipes.forEach(async(mr)=>{
                                let recipe=await  recipes.getById(mr.Recipe)
                                recipe.configuration.forEach(machine => {
                                    self.unwrapMachine(machine)
                                })
                            })
                        }


                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
