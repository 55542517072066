import Vue from 'vue'

//Register apis on system Vue instance
import defines from '@/api/defines';
Object.defineProperty(Vue.prototype, '$defines', { value: defines });
import data from '@/api/data';
Object.defineProperty(Vue.prototype, '$datalayer', { value: data });
import settings from '@/api/settings';
Object.defineProperty(Vue.prototype, '$settings', { value: settings });
import jsutils from '@/api/jsutils';
Object.defineProperty(Vue.prototype, '$utils', { value: jsutils });
import datetime from '@/api/datetimeutils.js';
Object.defineProperty(Vue.prototype, '$dateTime', { value: datetime });
import grants from '@/api/grants';
Object.defineProperty(Vue.prototype, '$grants', { value: grants });
import i18n from '@/api/i18n';
Object.defineProperty(Vue.prototype, '$i18n', { value: i18n });
import timetracking from '@/api/timetracking';
Object.defineProperty(Vue.prototype, '$timeTracking', { value: timetracking });
import workorders from '@/api/workorders';
Object.defineProperty(Vue.prototype, '$workorders', { value: workorders });
import workflows from '@/api/workflows';
Object.defineProperty(Vue.prototype, '$workflows', { value: workflows });
import devices from '@/api/devices';
Object.defineProperty(Vue.prototype, '$devices', { value: devices });
import recipes from '@/api/recipes';
Object.defineProperty(Vue.prototype, '$recipes' , {value: recipes});
import audits from '@/api/audits';
Object.defineProperty(Vue.prototype, '$audits' , {value: audits});
import config from '@/api/config'
Object.defineProperty(Vue.prototype, '$config' , {value: config});
import processesStatus from '@/api/processesStatus';
Object.defineProperty(Vue.prototype, '$processesStatus' , {value: processesStatus});
import dashboards from '@/api/dashboards';
Object.defineProperty(Vue.prototype, '$dashboards' , {value: dashboards});
import dynamicElements from '@/api/dynamicElements';
Object.defineProperty(Vue.prototype, '$dynamicElements' , {value: dynamicElements});
import palette from '@/api/avpalette';
Object.defineProperty(Vue.prototype, '$avStyle' , {value: palette});
import alarms from '@/api/alarmsApi'
Object.defineProperty(Vue.prototype, '$alarms', {value: alarms});
import licensing from '@/api/licensing';
Object.defineProperty(Vue.prototype, '$license', { value: licensing });
import dataEntry from '@/api/dataentry';
Object.defineProperty(Vue.prototype, '$dataEntry', { value: dataEntry });
import entities from '@/api/entities';
Object.defineProperty(Vue.prototype, '$entities', { value: entities });
import aliases from '@/api/aliases';
Object.defineProperty(Vue.prototype, '$aliases', { value: aliases });
import version from '@/api/version';
Object.defineProperty(Vue.prototype, '$version', { value: version });
import maintenance from '@/api/maintenance';
Object.defineProperty(Vue.prototype, '$maintenance', { value: maintenance });

import ReportingService from '@/api/reportingService'
Object.defineProperty(Vue.prototype, '$reportingService', { value: ReportingService });
import shifts from '@/api/shifts';
Object.defineProperty(Vue.prototype, '$shifts', { value: shifts });
import rules from '@/api/rules';
Object.defineProperty(Vue.prototype, '$rules', { value: rules });
