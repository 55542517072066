import Api from '@/api/api'
import OrchestratorAPI from '@/api/orchestrator'
import Microservices from '@/api/microservices'
import Vue from 'vue'
import workorderDialog from '@/components/dialogs/WorkorderScheduleDialog'
import VueInstance from './vueinstance'
import Defines from "./defines";
import DataApis from "./data";
import DateTime from "./datetimeutils"
import workorderFormSelectionDialog from '@/components/dialogs/WorkorderFormSelectionDialog'
import workorderManualLineSchedulingDialog from '@/components/dialogs/WorkorderManualLineSchedulingDialog'
import workorderFieldsEditingDialog from '@/components/dialogs/WorkorderFieldsEditingDialog'
import workorderOperationModeSelectionDialog from '@/components/dialogs/WorkorderOperationModeSelectionDialog'
import Config from '@/api/config'

let WorkorderStatus = Object.freeze(
    {
        "Aborted": "aborted",
        "Completed": "completed",
        "Planned": "planned",
        "Running": "running",
        "Suspended": "suspended",
        "WaitingUserStart": "waitinguserstart", //TODO Review Waiting user start in terms of actions and not state
        "Rejected": "rejected"
    });

let WorkorderFlowActions = Object.freeze(
    {
        "Start": "start",
        "Pause": "pause",
        "Stop": "stop"
    });

let WorkorderFlowIcons = Object.freeze(
    {
        "start": {color: 'blue', icon: 'fa-play'},
        "pause": {color: 'blue', icon: 'fa-pause'},
        "stop": {color: 'blue', icon: 'fa-stop'},
        "abort": {color: 'red', icon: 'fa-ban'}
    });

export default {
    WorkorderStatus: WorkorderStatus,
    WorkorderFlowActions: WorkorderFlowActions,
    WorkorderFlowIcons: WorkorderFlowIcons,

    //FN: manual is used for audits trail to specify manual selection of the wo or the selection by drop down .But then in the api is always set to true
    async scheduleWorkorder(woId) {

        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.workordersUrl.workOrders + '/user/override', { NewRunningWorkorderId: woId} )
                .then(t => {
                    resolve(true);
                })
                .catch(t => {
                    debugger;
                    console.error(t);
                    reject(VueInstance.get().$gettext("Error in scheduling workorder"));
                });
        });
    },

    async unScheduleWorkorder() {
        return this.scheduleWorkorder('')
    },

    openWorkOrderDialog(parent, callback, user) {
        //Create widget
        let componentClass = Vue.extend(workorderDialog);
        let woDialog = new componentClass();
        woDialog.setVueInstance(VueInstance.get());
        woDialog.$mount();
        woDialog.setCaller(parent);
        woDialog.Show(callback, user);
    },

    openWorkorderFieldsEditingDialog(parent, user, workorder, callBack = null) {
        //Create widget
        let componentClass = Vue.extend(workorderFieldsEditingDialog);
        let dialog = new componentClass();
        dialog.$mount();
        dialog.setCaller(parent);
        dialog.Show(user, workorder, callBack);
    },

    openWorkorderManualLineSchedulingDialog(parent, user, workorder, callBack = null) {
        //Create widget
        let componentClass = Vue.extend(workorderManualLineSchedulingDialog);
        let dialog = new componentClass();
        dialog.$mount();
        dialog.setCaller(parent);
        dialog.Show(user, workorder, callBack);
    },

    openWorkorderFormSelectionDialog(parent, user, workOrdersForms, callBack = null) {
        //Create widget
        let componentClass = Vue.extend(workorderFormSelectionDialog);
        let dialog = new componentClass();
        dialog.$mount();
        dialog.setCaller(parent);
        dialog.Show(user, workOrdersForms, callBack);
    },

    openWorkorderOperationModeSelectionDialog(parent, user, workOrdersOperationModes, callBack = null) {
        //Create widget
        let componentClass = Vue.extend(workorderOperationModeSelectionDialog);
        let dialog = new componentClass();
        dialog.$mount();
        dialog.setCaller(parent);
        dialog.Show(user, workOrdersOperationModes, callBack);
    },

    get(id) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.workordersUrl.workOrders + '/id/' + id)
                .then(t => {
                    if(!Array.isUseful(t))
                        resolve({Id: id, found: false});
                    else {
                        t[0].found = true;
                        resolve(t[0]);
                    }
                })
                .catch(t => {
                    console.log(t);
                    reject(VueInstance.get().$gettext("Unable to find requested workorder"));
                });
        });
    },

    getProductionCountersWorkordersFilter(setCurrent = false) {
        return {
            index: "production counters@5s",
            root: "Line",
            name: 'WorkorderID',
            type: 'keyword',
            selectedForFiltering: true,
            filters: [{
                conditions: [{operator: '=', value: setCurrent ? "@CurrentWorkOrder" : "~|~wo~|~"}],
                defaultName: setCurrent ? "Select current workorder" : "Select workorder",
                name: setCurrent ? "Select current workorder" : "Select workorder",
                enabled: true,
                filterId: "InternalSelectWorkorder" //Create a unique id that survives changes
            }]
        };
    },

    setProductionCountersWorkordersFilter(filter, value = null) {
        if(Array.isUseful(filter.filters) && filter.filters.length === 1 && filter.filters[0].filterId === "InternalSelectWorkorder" && Array.isUseful(filter.filters[0].conditions) && filter.filters[0].conditions.length === 1) {
            filter.filters[0].conditions[0].value = (value ? value : "@CurrentWorkOrder");
            return filter.filters[0].filterId;
        }
        return "";
    },

    getCurrentWorkorderQueryDescriptor(index) {
        return {
            index: index ? index : "production counters@5s",
            root: "Line",
            name: 'WorkorderID',
            type: 'keyword',
            representations: [
                {
                    name: "Workorder",
                    type: Defines.allAggregations.last.id,
                    id: Date.now(),
                    target: 0
                }]
        }
    },

     getCurrentWorkorderId() {

        let dataItems = [ this.getCurrentWorkorderQueryDescriptor() ];

        let queryDescriptor = DataApis.getDataQueryDescriptor(dataItems);

        //Execute query
        return new Promise((resolve, reject) => {
            if (queryDescriptor.agg.length > 0 || queryDescriptor.raw.length > 0 || queryDescriptor.comp.length > 0) {
                DataApis.getDataBlob(queryDescriptor, DateTime.getRfc3339TimeStamp(new Date(Date.now() - 60000)), DateTime.getRfc3339TimeStamp(new Date(Date.now())))
                    .then(result => {
                        let currentWorkorderName = "";
                        let data = DataApis.unwrapDataSets(dataItems, [], [], result);
                        if (Array.isUseful(data[0].data) && data[0].data[0].y) {
                            currentWorkorderName = data[0].data[0].y;
                        } else {
                            currentWorkorderName = "NO WORKORDER";
                        }
                        resolve(currentWorkorderName)
                    })
                    .catch(err => {
                        console.log(err);
                        //self.error = ;
                        reject(VueInstance.get().$gettext("Unable to retrieve current workorder information"));
                    });
            }
        });
    },

    loadWorkordersList(from, to) {

        return new Promise((resolve, reject) => {
            DataApis.getDistinctValues('production counters@5s', 'Line', 'WorkorderID', 'keyword', from, to)
                .then(workorders => {
                    resolve(workorders.filter(workorder => (workorder && workorder !== "UNKNOWN")));
                })
                .catch(err => {
                    console.log(err);
                    reject([]);
                });
        });
    },

    async getWorkordersByStatus(status) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.workordersUrl.workOrders + '/status/' + status)
                .then(response => {
                      if(!Array.isUseful(response))
                            response = [];
                      resolve(response);
                })
                .catch(err => {
                    if(Microservices.isIndexEmptyError(err))
                        resolve([]);
                    else reject(VueInstance.get().$gettext("Unable to load {0} workorders").format(status));
                })
        });
    },

    async getWorkorderLogs(woId) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.workordersUrl.workOrders + '/transitions/' + woId)
                .then(response => {
                    if(!Array.isUseful(response))
                        response = [];
                    resolve(response);
                })
                .catch(err => {
                    if(Microservices.isIndexEmptyError(err))
                        resolve([]);
                    else reject(VueInstance.get().$gettext("Unable to load workorder {0} log").format(woId));
                })
        });
    },

    async getWorkorderBom(woId) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.workordersUrl.workOrders + '/billofmaterials/' + woId)
                .then(response => {
                    if(Array.isUseful(response))
                        resolve(response[0])
                    else resolve(null);
                })
                .catch(err => {
                    if(Microservices.isIndexEmptyError(err))
                        resolve(null);
                    else reject(VueInstance.get().$gettext("Unable to load workorder {0} bom").format(woId));
                })
        });
    },

    getDataDescriptor() {

        let returning = { filters: [], data: [], aggregations: [] };

        returning.data.push({
            index: 'production counters@5s',
            root: 'Line',
            name: 'WorkorderID',
            type: 'keyword',
            selectedForVisualization: true,
            representations: [
                {
                    type: Defines.allAggregations.last.id,
                    filters: [  ],
                    target: 500,
                    defaultName: "Workorder",
                    name: "Workorder",
                    enabled: true,
                    timeless: true,
                },
            ],
        },{
            index: 'production counters@5s',
            root: 'Line',
            name: 'RecipeId',
            type: 'keyword',
            selectedForVisualization: true,
            representations: [
                {
                    type: Defines.allAggregations.last.id,
                    filters: [  ],
                    target: 501,
                    defaultName: "RecipeId",
                    name: "RecipeId",
                    enabled: true,
                    timeless: true,
                },
            ],
        }, {
            index: 'production counters@5s',
            root: 'Workorder',
            name: 'Material',
            type: 'keyword',
            selectedForVisualization: true,
            representations: [
                {
                    type: Defines.allAggregations.last.id,
                    filters: [  ],
                    target: 502,
                    defaultName: "Material",
                    name: "Material",
                    enabled: true,
                    timeless: true,
                },
            ],
        }, {
            index: 'production counters@5s',
            root: 'Workorder',
            name: 'ProductDescription',
            type: 'keyword',
            selectedForVisualization: true,
            representations: [
                {
                    type: Defines.allAggregations.last.id,
                    filters: [  ],
                    target: 503,
                    defaultName: "ProductDescription",
                    name: "ProductDescription",
                    enabled: true,
                    timeless: true,
                },
            ],
        });


        return returning;
    },

    getHistory(from, to) {
        let query = { filters: [], data: [], aggregations: []};

        query.data.push(
            {
                index: 'production counters@5s',
                root: 'Line',
                name: 'WorkorderID',
                type: 'keyword',
                selectedForVisualization: true,
                representations: [
                    {
                        type: Defines.allAggregations.raw.id,
                        filters: [],
                        target: 0,
                        aggregationWindow: 0,
                        defaultName: 'History',
                        name: 'History',
                        id: 'History',
                        enabled: true
                    },
                ],
            },
        );

        let queryDescriptor = DataApis.getDataQueryDescriptor(query.data, query.filters, query.aggregations);

        //Execute query
        return new Promise((resolve, reject) => {
            if (queryDescriptor.agg.length > 0 || queryDescriptor.raw.length > 0 || queryDescriptor.comp.length > 0) {
                DataApis.getDataBlob(queryDescriptor, from, to)
                    .then(result => {
                        let workorders = DataApis.unwrapDataSets(query.data, [], [], result).last().data;

                        if (!Array.isUseful(workorders)) {
                            resolve([]);
                            return;
                        }

                        let returning = [];
                        let wo = workorders[0].y;

                        let startTime = workorders[0].x;
                        let stopTime = startTime;

                        for (let i = 1 ; i < workorders.length ; i++) {
                            let tmpTime = workorders[i].x;
                            let tmpWo = workorders[i].y;

                            if (tmpTime > (stopTime + 15000) || tmpWo !== wo) {
                                returning.push({start: startTime, stop: stopTime, duration: (stopTime - startTime), workorder: wo});

                                startTime = tmpTime;
                            }

                            stopTime = tmpTime;
                            wo = tmpWo;
                        }

                        returning.push({start: startTime, stop: stopTime, duration: (stopTime - startTime), workorder: wo});

                        resolve(returning);
                    })
                    .catch(err => {
                        debugger
                        console.error(err);
                        //self.error = self.$gettext("Unable to retrieve current workorder information");
                        reject([]);
                    });
            }
        });
    },
    getBackAnnotationObject(workorder, start, stop, recipe, speed, operator, identifier) {
        if(!operator) {
            operator = "";
        }

        let returning = {
            Begin:     DateTime.getRfc3339TimeStamp(new Date(start)),
            End:       DateTime.getRfc3339TimeStamp(new Date(stop)),
            '@timestamp': DateTime.getRfc3339TimeStamp(new Date()),
            SubField: "Line",
            Status: "",
            Identifier: identifier,
            Annotation: {
                WorkorderID: workorder,
                RecipeId: recipe,
                NominalSpeed: speed,
                ShouldBeProducing: workorder !== "" ? 1 : 0,
            },
            tag: {
                Operator: operator,
            }
        };
        return returning
    },

    createWorkorder(workorder, physicalLineId = "") {
        let url = Microservices.workordersUrl.workOrders + "/";
        if(VueInstance.get().$license.hasManager())
            url += "scheduleworkorder"
        else url += (physicalLineId || "")
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', url , workorder)
                .then(t => {
                    resolve(true);
                })
                .catch(t => {
                    console.error(t);
                    reject(t);
                });
        })
    },

    requestWorkorderTransition(workorder, physicalLineId = ""){
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.workordersUrl.workOrders + "/transaction/" + (physicalLineId || ""), workorder)
                .then(t => {
                    resolve(true);
                })
                .catch(t => {
                    console.error(t);
                    reject(t);
                });
        })
    },

    getCurrentOperationMode() {
        return new Promise((resolve, reject) =>{
            OrchestratorAPI.proxyCall('get', Microservices.workordersUrl.workOrders + "/" + "operationmode")
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    debugger
                    //TODO CHECK
                    if(Microservices.isIndexEmptyError(error)) {
                        resolve({});
                    } else {
                        reject(error);
                    }
                });

        });
    },

    async saveOperationMode(name, descriptor) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.workordersUrl.workOrders + '/' + 'operationmode' + '/{0}'.format(name), descriptor)
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    debugger
                    reject(error);
                });
        });
    },

    async updateWorkorder(woId, payload) {

        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.workordersUrl.workOrders + '/update/' + woId, payload )
                .then(t => {
                    resolve(true);
                })
                .catch(t => {
                    console.error(t);
                });
        });
    },

    async getLineInfo(lineId, sublineId = "") {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('get', Microservices.workordersUrl.workOrders +
                '/line/info/{0}'.format(lineId) + (sublineId ? '/{0}'.format(sublineId) : '') )
                .then(t => {
                    resolve(t);
                })
                .catch(err => {
                    debugger
                    console.error(err);
                    reject()
                });
        });
    },

    async resetLine(lineId) {
        return new Promise((resolve, reject) => {
            OrchestratorAPI.proxyCall('post', Microservices.workordersUrl.workOrders + '/line/reset/{0}'.format(lineId) )
                .then(t => {
                    console.log(t)
                    resolve(true);
                })
                .catch(t => {
                    console.error(t);
                });
        });
    },

    async assignWorkorderToLine(line, woId, subline = "") {
        return new Promise((resolve, reject) => {
            let url = Microservices.workordersUrl.workOrders + '/line/assign/{0}'.format(line)
            if(subline)
                url += "/{0}/{1}".format(subline, woId);
            else url += "/{0}".format(woId);
            OrchestratorAPI.proxyCall('post', url)
                .then(t => {
                    resolve(t);
                })
                .catch(t => {
                    debugger;
                    console.error(t);
                    reject(VueInstance.get().$gettext("Error in assigning workorder to line"));
                });
        });
    },
    async rejectWorkorder(line, woId) {
        return new Promise((resolve, reject) => {
            let url = Microservices.workordersUrl.workOrders + '/reject/{0}'.format(line)
            url += "/{0}".format(woId);
            let message = VueInstance.get().$gettext("Workorder {0} rejected").format(woId)
            OrchestratorAPI.proxyCall('post', url)
                .then(t => {
                    resolve(t);
                    VueInstance.get().showNotification(message,true,false);
                })
                .catch(t => {
                    debugger;
                    console.error(t);
                    message = VueInstance.get().$gettext("Workorder {0} rejection fail").format(woId)
                    reject(VueInstance.get().$gettext("Error in rejecting workorder for line"));
                    VueInstance.get().showErrorNotification(message,true,false);
                });
        });
    },
    async recoverWorkorder(line, woId) {
        return new Promise((resolve, reject) => {
            debugger;
            let url = Microservices.workordersUrl.workOrders + '/restore/{0}'.format(line)
            url += "/{0}".format(woId);
            let message = VueInstance.get().$gettext("Workorder {0} restored").format(woId)
            OrchestratorAPI.proxyCall('post', url)
                .then(t => {
                    resolve(t);
                    VueInstance.get().showNotification(message,true,false);
                })
                .catch(t => {
                    debugger;
                    console.error(t);
                    message = VueInstance.get().$gettext("Workorder {0} restoring fail").format(woId)
                    reject(VueInstance.get().$gettext("Error in restoring workorder for line"));
                    VueInstance.get().showErrorNotification(message,true,false);
                });
        });
    },
}
