<template>
    <DynamicElementBase>
        <div v-if="!showAsMatrix" style="height: 100%; width:100%; overflow: auto;">
            <v-data-table :headers="headers" :items="dataCollection" hide-actions>
                <template v-slot:headers="props">
                    <tr>
                        <th style="padding-left: 10px; padding-right: 5px; text-align: left"
                            v-for="header in props.headers" :class="header.class">{{ header.text }}
                        </th>
                    </tr>
                </template>
                <template v-slot:items="props">
                    <tr>
                        <td class="subheading">
                            Line: {{ props.item.physicalLineId }}
                        </td>
                        <td>
                            <v-layout row>
                                <div style="display:inline-block; cursor: pointer"
                                     @click="openLineDetails(props.item.lineIndex);">
                                    <!--@click="/openLineDetails(props.item.physicalLineId);"-->
                                    <!--<a href="l1">-->
                                    <av-icon size="30">open_in_new</av-icon>
                                    <v-tooltip v-translate>Show Details</v-tooltip>
                                    <!--</a>-->
                                </div>
                                <!--<div style="display:inline-block; cursor: pointer" @click="openLinePerformance(props.item.physicalLineId)">-->
                                <!--<v-icon size="30">videocam</v-icon>-->
                                <!--<v-tooltip>Show Devices Performances</v-tooltip>-->
                                <!--</div>-->
                            </v-layout>
                        </td>
                        <!--<td>-->
                        <!--{{ props.item.logicalLineId }}-->
                        <!--</td>-->
                        <td>
                            <div :class="props.item.status"
                                 style="width:25px; height:25px; -webkit-border-radius:25px;">&nbsp;
                            </div>
                            <v-tooltip>{{ props.item.status }}</v-tooltip>
                        </td>
                        <!--                        <td>-->
                        <!--                            <div style="width:300px; display:inline-block;">-->
                        <!--                                <div style="width:200px; display:inline-block; vertical-align: text-bottom;">-->
                        <!--                                    <div-->
                        <!--                                        :style="'text-align: center; height: 20px; borderRadius:5px; border: 1px solid lightgray; backgroundImage:' + props.item.speedBackgroundImage">-->
                        <!--                                        {{ props.item.efficiency }} % of max speed-->
                        <!--                                    </div>-->
                        <!--                                </div>-->
                        <!--                                <v-tooltip v-if="props.item.speed >= 0 && props.item.maxSpeed >= 0">-->
                        <!--                                    {{ props.item.speed }} (actual) / {{ props.item.maxSpeed }} (max)-->
                        <!--                                </v-tooltip>-->
                        <!--                                <span-->
                        <!--                                    class="subheading">&nbsp;{{ props.item.speed >= 0 ? props.item.speed : '' }} ppm</span>-->
                        <!--                            </div>-->
                        <!--                        </td>-->
                        <td>
                            <div style="width:250px; display:inline-block;">
                                <div style="width:250px; display:inline-block; vertical-align: text-bottom;">
                                    <div
                                        :style="'text-align: center; height: 20px; borderRadius:5px; border: 1px solid lightgray; backgroundImage:' + props.item.oeeBackgroundImage">
                                        {{ props.item.oee >= 0 ? props.item.oee + '%' : '' }}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <template>
                                <span class="subheading">{{ props.item.quantityProduced }}</span>
                            </template>
                        </td>
<!--                        <td>-->
<!--                            <template v-if="props.item.rejects >= 0">-->
<!--                                <span class="subheading">{{ props.item.rejects }}</span>-->
<!--                            </template>-->
<!--                            <template v-else>-->
<!--                                &nbsp;-->
<!--                            </template>-->
<!--                        </td>-->
                        <td>
                            <template v-if="props.item.quality >= 0">
                                <span class="subheading">{{ 100 - props.item.quality >= 0 ? props.item.quality + '%' : "" }}</span>
                            </template>
                        </td>
                        <td>
                            <div class="align-center"
                                 style="min-width:100px;display:inline-block; white-space:nowrap;margin-left: 20px;">
                                <div style="width:250px;display:inline-block; vertical-align: text-bottom;">
                                    <div
                                        v-bind:style="'text-align: center; height: 20px; borderRadius: 5px; border: 1px solid lightgray; backgroundImage:' + props.item.progressBackgroundImage">
                                        {{
                                            props.item.itemsCountPercent >= 0 ? props.item.itemsCountPercent + '%' : ""
                                        }}
                                        of {{ props.item.quantityToProduce }} items
                                    </div>
                                </div>
                                <!--<v-tooltip v-if="props.item.itemsCount >= 0 && props.item.quantityToProduce >= 0">{{props.item.itemsCount}} (actual) / {{props.item.quantityToProduce}} (total)</v-tooltip>-->
                                &nbsp;
                            </div>

                        </td>
                        <!--                        <td>-->
                        <!--                            <template v-if="props.item.inTime">-->
                        <!--                                <div :class="props.item.inTime"-->
                        <!--                                     style="width:25px; height:25px; -webkit-border-radius:20px;">&nbsp;-->
                        <!--                                </div>-->
                        <!--                                <v-tooltip>{{ props.item.inTime }}</v-tooltip>-->
                        <!--                            </template>-->
                        <!--                            <template v-else>-->
                        <!--                                &nbsp;-->
                        <!--                            </template>-->
                        <!--                        </td>-->
                        <td>
                            <template class="subheading" v-if="props.item.lot">
                                {{ props.item.lot }}
                            </template>
                            <template v-else>
                                &nbsp;
                            </template>
                        </td>
                        <!--<td>-->
                        <!--<template v-if="props.item.nextLot">-->
                        <!--{{ props.item.nextLot }}-->
                        <!--</template>-->
                        <!--<template v-else>&nbsp;</template>-->
                        <!--</td>-->
                    </tr>
                </template>
            </v-data-table>
        </div>
        <div v-else style="height: 100%; width:100%; overflow: auto;">
            <v-layout id="layoutLines" row wrap py-2 justify-center style="height: 100%; width:100%; overflow: auto;">
                <div v-for="(item,indexCol) in dataCollection" :key="indexCol"
                     :style="'min-width:{0}px; min-height: 300px; max-width: 500px; height:-webkit-fill-available; padding: 8px;  overflow: auto;'.format(slotWidth-10)">
                    <v-card class="av-card-borderless" elevation="0" style="'height:-webkit-fill-available'">
                        <v-toolbar :class="item.status" style="height: 10%">
                            <v-toolbar-title style="height: 100%;">
                                {{ item.physicalLineId }}
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-title v-if="item.lot" style="height: 5%">
                            <span class="subheading mr-2">{{ item.lot }}</span>
                        </v-card-title>
                        <v-card-text style="height: 80%">
                            <!--                            <div style="width:250px;display:inline-block; vertical-align: text-bottom;">-->
                            <!--                                <div v-bind:style="'text-align: center; height: 20px; borderRadius: 5px; border: 1px solid lightgray; backgroundImage:' + item.progressBackgroundImage">{{item.itemsCountPercent >= 0 ? item.itemsCountPercent + '%' : ""}} of {{item.quantityToProduce}} items</div>-->
                            <!--                            </div>-->
                            <div style="height: 5%">
                                <div
                                    v-bind:style="'text-align: center; borderRadius: 5px; border: 1px solid lightgray; backgroundImage:' + item.progressBackgroundImage">
                                    {{ item.itemsCountPercent >= 0 ? item.itemsCountPercent + '%' : "" }} of
                                    {{ item.quantityToProduce }} items
                                </div>
                            </div>
                            <div style="height:75%;margin-top: 10px;" :ref="'lineElement-' + indexCol"
                                 :id="`cv${indexCol}`">
                            </div>
                            <div style="height: 5%">
                                <div style="text-align: center;" class="title font-weight-bold">
                                    {{ $gettext('Total stop time') }}: {{ item.totalStopTime }}
                                </div>
                            </div>
                            <div style="height: 5%;margin-top: 10px;">
                                <div style="text-align: center;"> {{ $gettext('Produced') }}:
                                    {{ item.quantityProduced }}
                                </div>
                            </div>
                            <div style="height: 5%;margin-top: 2px;">
                                <div style="text-align: center;"> {{ $gettext('Rejects') }}:
                                    {{ 100 - item.quality >= 0 ? item.quality + '%' : "" }}
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </v-layout>
        </div>
    </DynamicElementBase>
</template>

<script>

import DynamicElementBase from '@/components/dynamic-elements/DynamicElementBase.vue'
import Production from '@/api/production'
import Oee from '@/api/oee'
import AvToolbar from "../../av-components/av-toolbar";

export default {
    name: "WidgetPlant",
    extends: DynamicElementBase,
    components: {
        AvToolbar,
        DynamicElementBase
    },
    data() {
        return {
            headers: [{text: this.$gettext("Line"), class: "subheading"}, {
                text: this.$gettext("Line details"),
                class: "subheading"
            },/*{ text: "Logical line", class: "subheading" },*/{
                text: this.$gettext("Status"),
                class: "subheading"
            }, /*{text: this.$gettext("Speed (ppm)"), class: "subheading"},*/
                {text: "OEE", class: "subheading"},
                {text: this.$gettext("Produced"), class: "subheading"},
                {text: this.$gettext("Rejects"), class: "subheading"},
                {text: this.$gettext("Progress"), class: "subheading"},
                // {text: this.$gettext("In Time"), class: "subheading"},
                {
                    text: this.$gettext("Current Lot"),
                    class: "subheading"
                }/*, { text: "Next Lot", class: "subheading" }*/],
            lineBreaks: [],
            dataCollection: [],
            initializer: null,
            isAutoloading: false,
            minHeight: 400,
            minWidth: 516,
            slotHeight: 0, // TODO set 300?
            slotWidth: 0, // TODO set 250?
            numLinesToDisplay: 0,
            matrixInitialized: false,
            showAsMatrix: false,
            numStaticTweaks: 0,
            // currentElement: null,
            queriesCountForLine: 14,
            currentElements: []
        }
    },
    mounted() {
        //AutomaticRuntimeDatasource is setting a true for level 3
        this.visualizationTweaks = [
            {
                name: this.$gettext("Automatic runtime datasource"),
                id: "AutomaticRuntimeDatasource",
                type: "bool",
                default: function () {
                    return false;
                }
            },
            {
                name: this.$gettext("Show as matrix"),
                id: "ShowAsMatrix",
                type: "bool",
                default: function () {
                    return false;
                }
            }
        ];
        this.numStaticTweaks = this.visualizationTweaks.length;
        this.dataExplorationMode.compatibleDataPatterns.push(this.$defines.getDataPatternDescriptor(["production", "counters"], true));
        this.dataExplorationMode.autoDataSourcing = true;
        this.dataExplorationMode.deviceSelectMode = true;
        this.dataExplorationMode.enumerateLineInDevices = true;
        setTimeout(this.loadSavedTweaks, 1000);
    },
    computed: {
        devices() {
            return this.properties.selectedDevices;
        }
    },
    watch: {
        devices() {
            if (!this.loading && !this.getTweakValue("AutomaticRuntimeDatasource"))
                this.$root.showDialogBox(this.$gettext("Do you want to automatically create data sources for Plant View?"), null, this.$gettext("Yes"), this.createDataItems, this.$gettext("No"), null);
        },
        visualizationTweaks: {
            handler: function () {
                this.showAsMatrix = this.getTweakValue("ShowAsMatrix");
                if (!this.isAutoloading) {
                    //Update visualization tweaks values and save widget
                    this.saveTweaks();
                    if (this.getTweakValue("AutomaticRuntimeDatasource")) {
                        this.loadDataMappings();
                    }
                }
            },
            deep: true,
        },
        containerWidth() {
            if (this.showAsMatrix) {
                this.initMatrix();
            }
        },
        containerHeight() {
            if (this.showAsMatrix) {
                this.initMatrix();
            }
        },
        showAsMatrix() {
            if (this.showAsMatrix) {
                this.createElements();
            }
        }
    },
    methods: {

        initMatrix() {
            this.numLinesToDisplay = this.dataCollection.length;
            let numCardsInRow = this.numLinesToDisplay;
            while (true) {
                if (numCardsInRow < 1)
                    return
                let cellWidth = this.containerWidth / numCardsInRow;
                if (cellWidth < this.minWidth) {
                    numCardsInRow--
                } else {
                    this.slotWidth = cellWidth;
                    break;
                }
            }
        },

        updateElements() {
            for (let i = 0; i < this.dataCollection.length; i++) {
                this.currentElements[i].value = this.dataCollection[i].oee > 0 ? this.dataCollection[i].oee + '%' : this.dataCollection[i].oee;
            }
        },

        createElements() {
            for (let i = 0; i < this.dataCollection.length; i++) {
                let currentElementType = this.$dynamicElements.Types.Gauge;
                let self = this;
                this.$nextTick(() => {
                    // let descriptor={
                    //     aggregations: [],
                    //     customDescriptor: "",
                    //     data: [],
                    //     deploy: null,
                    //     exports: [],
                    //     filters: [],
                    //     formVariables: [],
                    //     functions: [],
                    //     outputs: [],
                    //     properties: {
                    //         alwaysHideManualRefresh: false,
                    //         cron: "0 */1 * * 0",
                    //         dashboardToRedirect: "",
                    //         dataPatterns: [],
                    //         description: "",
                    //         descriptorVersion: 1,
                    //         editableToLevels: [{
                    //             level: 0,
                    //             show: "viewer"
                    //         }, {
                    //             level: 1,
                    //             show: "operator"
                    //         }, {
                    //             level: 2,
                    //             show: "manager"
                    //         }, {
                    //             level: 9,
                    //             show: "admin"
                    //         }],
                    //         exposableToLevels: null,
                    //         forcedDataRefreshInterval: -1,
                    //         name: "",
                    //         reloadDynamicLinks: false,
                    //         selectedDevices: [],
                    //         showTimeWindow: true,
                    //         showTitleBar: true,
                    //         timeWindow: "",
                    //         title: "",
                    //         type: 15,
                    //         visibleToLevels: [{
                    //             level: 0,
                    //             show: "viewer"
                    //         }, {
                    //             level: 1,
                    //             show: "operator"
                    //         }, {
                    //             level: 2,
                    //             show: "manager"
                    //         }, {
                    //             level: 9,
                    //             show: "admin"
                    //         }],
                    //     },
                    //     queryDescriptor: {
                    //     },
                    //     reportTemplate: null,
                    //     reportTemplateName: null,
                    //     rules: [],
                    //     version: 9
                    // }


                    //Create element
                    let element = self.$dynamicElements.createNew(currentElementType, false);
                    // self.currentElements[i] = self.$dynamicElements.createNew(currentElementType, false);
                    self.currentElements.push(element);
                    if (!self.currentElements[i])
                        return; //License issue or huge error (unlikely)
                    self.currentElements[i].properties.type = currentElementType;
                    self.currentElements[i].properties.hideBorder = true;
                    self.currentElements[i].properties.showTitleBar = false;
                    self.currentElements[i].newElement();
                    self.$nextTick(() => {
                        self.$refs['lineElement-' + i][0].appendChild(self.currentElements[i].$el);
                        self.currentElements[i].setTweakValue("minValue", 0);
                        self.currentElements[i].setTweakValue("maxValue", 100);
                        setTimeout(() => {
                            self.currentElements[i].value = this.dataCollection[i].oee;
                        }, 5000);

                    });
                });
            }

        },

        loadDataMappings() {
            this.isAutoloading = true;
            let self = this;
            this.$devices.selectDeviceFromIndexPatterns(this.dataExplorationMode.compatibleDataPatterns, true)
                .then(devices => {
                    self.createDataItems(devices);
                })
                .finally(() => {
                    self.isAutoloading = false;
                });
        },
        loadSavedTweaks() {
            if (this.properties.visualizationTweaksValues) {
                // this.visualizationTweaks = [];
                this.clearTweaks();
                let unsortedTweaks = [];
                for (const tweak in this.properties.visualizationTweaksValues) {
                    if (tweak !== "AutomaticRuntimeDatasource" && tweak !== "ShowAsMatrix") {
                        unsortedTweaks.push({
                            name: tweak.split("_")[1],
                            id: tweak,
                            type: "string",
                            value: this.properties.visualizationTweaksValues[tweak]
                        });
                    }
                }
                this.visualizationTweaks.push(...unsortedTweaks.sort(function (a, b) {
                    try {
                        if (parseInt(a.id) > parseInt(b.id))
                            return 1;
                        else
                            return -1;
                    } catch (err) {
                        /*We have a broken widget, this shouldn't happen in production, yet during development unstable widgets could be produced*/
                        return 0;
                    }
                }));
            }
        },
        openLineDetails(lineIndex) {
            if (Object.isUseful(this.visualizationTweaks[lineIndex * 3 + 1].value) && this.visualizationTweaks[lineIndex * 3 + 1].value &&
                Object.isUseful(this.visualizationTweaks[lineIndex * 3 + 2].value) && this.visualizationTweaks[lineIndex * 3 + 2].value) {
                let dashName = this.visualizationTweaks[lineIndex * 3 + 1].value.replace(/\//g, "£").replace(/#/g, "@");
                let dashLink = this.visualizationTweaks[lineIndex * 3 + 2].value.replace(/\//g, "£").replace(/#/g, "@");
                this.$router.push({path: '/realtime/' + dashName + '/' + dashLink});
            }
        },
        // openLinePerformance(physicalLineId) {
        //     if(physicalLineId === 1)
        //         this.environment.router.push({path:'/dashboards/builtin-rejects-l1/show'});
        //     else if(physicalLineId === 2)
        //         this.environment.router.push({path:'/dashboards/builtin-rejects-l2/show'});
        //     else if(physicalLineId === 3)
        //         this.environment.router.push({path:'/dashboards/builtin-rejects-l3/show'});
        // },

        //Clear all tweaks loaded or created a runtime , but not the first static tweak.
        clearTweaks() {
            if (this.visualizationTweaks.length > this.numStaticTweaks) {
                this.visualizationTweaks.splice(1, this.visualizationTweaks.length - 1);
            }
        },
        createDataItems(devices) {
            //Create data items for each line, ensure that even in case user selects
            //more than one device per line, we consider only one per line.
            this.lineBreaks.push(0);
            let filters = [];
            let data = [];
            let representationsCount = 0;
            let selectedIndexes = [];
            // this.visualizationTweaks = [];
            this.clearTweaks();
            let lineIndex = 0;

            for (const device of (Array.isUseful(devices) ? devices : this.devices)) {
                if (!(selectedIndexes.includes(device.dataIndex))) {
                    let productionDescriptor = Production.getDataDescriptor(device.dataIndex, [Production.targets.progress, Production.targets.quantityProduced, Production.targets.quantityToProduce, Production.targets.status, Production.targets.workorder], true);
                    filters = filters.concat(productionDescriptor.filters);
                    data = data.concat(productionDescriptor.data);
                    for (let i = 0; i < productionDescriptor.data.length; i++)
                        representationsCount += productionDescriptor.data[i].representations.length;

                    let oeeDescriptor = Oee.getDataDescriptor([device], true);
                    filters = filters.concat(oeeDescriptor.filters);
                    data = data.concat(oeeDescriptor.data);
                    for (let i = 0; i < oeeDescriptor.data.length; i++)
                        representationsCount += oeeDescriptor.data[i].representations.length;
                    filters.push({
                        index: device.dataIndex,
                        root: "TimeTracking",
                        name: 'Activity',
                        type: 'integer',
                        selectedForFiltering: true,
                        filters: [{
                            conditions: [{operator: '>', value: 1}],
                            defaultName: "lineStoppedFilter",
                            name: "lineStoppedFilter",
                            enabled: true,
                            filterId: "lineStoppedFilter"
                        }],
                    },)
                    data.push({
                            index: device.dataIndex,
                            root: 'Line',
                            name: 'Status',
                            type: 'integer',
                            selectedForVisualization: true,
                            representations: [
                                {
                                    type: this.$defines.allAggregations.last.id,
                                    filters: [],
                                    target: 300,
                                    defaultName: 'Line Status',
                                    name: 'Line Status',
                                    enabled: true
                                }
                            ]
                        },
                        {
                            index: device.dataIndex,
                            root: 'Line',
                            name: 'PhysicalLineId',
                            type: 'keyword',
                            selectedForVisualization: true,
                            representations: [
                                {
                                    type: this.$defines.allAggregations.last.id,
                                    filters: [],
                                    target: 400,
                                    defaultName: 'LineId',
                                    name: 'LineId',
                                    enabled: true
                                }
                            ]
                        },
                        {
                            index: device.dataIndex,
                            root: 'TimeTracking',
                            name: 'Activity',
                            type: 'integer',
                            selectedForVisualization: true,
                            representations: [
                                {
                                    type: this.$defines.allAggregations.duration.id,
                                    filters: ["lineStoppedFilter"],
                                    target: 500,
                                    defaultName: 'TotalStopTime',
                                    name: 'TotalStopTime',
                                    enabled: true
                                }
                            ]
                        }
                    );
                    representationsCount += data.length;
                    this.lineBreaks.push(representationsCount);
                    if (!this.getTweakValue("AutomaticRuntimeDatasource")) {
                        this.visualizationTweaks.push({
                            name: "Row " + lineIndex + " details title",
                            id: (lineIndex * 3) + "_Row " + lineIndex + " details title",
                            type: "string",
                            value: ""
                        });

                        this.visualizationTweaks.push({
                            name: "Row " + lineIndex + " details link",
                            id: (lineIndex * 3 + 1) + "_Row " + lineIndex + " details link",
                            type: "string",
                            value: ""
                        });

                        this.visualizationTweaks.push({
                            name: "Row " + lineIndex + " line name",
                            id: (lineIndex * 3 + 2) + "_Row " + lineIndex + " override line name",
                            type: "string",
                            value: ""
                        });
                    }

                    lineIndex++;
                }
            }
            this.filterItems = filters;
            this.dataItems = data;

            this.$emit('dataItemsUpdated');
        },
        refreshData(dataValues) {

            if (!dataValues)
                return;

            if (dataValues.length % this.queriesCountForLine !== 0) {
                this.setError("Wrong data sources");
                return;
            }

            this.clearErrors();
            this.clearWarnings();

            this.dataCollection = [];

            let errors = [];
            let warnings = [];

            for (let lineIndex = 0; lineIndex < (dataValues.length / this.queriesCountForLine); lineIndex++) {
                let item = {};
                let lineDataValues = dataValues.slice(lineIndex * this.queriesCountForLine, (lineIndex + 1) * this.queriesCountForLine);
                for (const dataSet of lineDataValues) {
                    if (dataSet && Array.isUseful(dataSet.data)) {
                        //From OEE
                        let valid = Oee.isIndicatorValid(dataSet.data.last().error, dataSet.data.last().warning);
                        if (dataSet.target === Oee.targets.efficiency)
                            item.efficiency = valid ? this.$utils.roundToDigits(dataSet.data.last().y, 1) : "N/A";
                        else if (dataSet.target === Oee.targets.rejectedItems)
                            item.rejects = valid ? this.$utils.roundToDigits(dataSet.data.last().y, 1) : "N/A";
                        else if (dataSet.target === Oee.targets.total)
                            item.oee = valid ? this.$utils.roundToDigits(dataSet.data.last().y, 1) : "N/A";
                        else if (dataSet.target === Oee.targets.maxSpeed)
                            item.maxSpeed = valid ? dataSet.data.last().y : "N/A";
                        else if (dataSet.target === Oee.targets.averageSpeed)
                            item.speed = valid ? this.$utils.roundToDigits(dataSet.data.last().y, 1) : "N/A";
                        else if (dataSet.target === Oee.targets.quality)
                            item.quality = valid ? this.$utils.roundToDigits(dataSet.data.last().y, 1) : "N/A";
                        //From Batch
                        else if (dataSet.target === Production.targets.quantityToProduce)
                            item.quantityToProduce = dataSet.data.last().y;
                        else if (dataSet.target === Production.targets.quantityProduced)
                            item.quantityProduced = dataSet.data.last().y;
                        else if (dataSet.target === Production.targets.status)
                            item.inTime = dataSet.data.last().y === 1 ? "inTime" : "late";
                        else if (dataSet.target === Production.targets.progress) {
                            item.itemsCountPercent = this.$utils.roundToDigits(dataSet.data.last().y, 1);
                            item.progressBackgroundImage = '-webkit-linear-gradient(left, gray ' + item.itemsCountPercent + '%, white 1%)';
                        } else if (dataSet.target === Production.targets.workorder)
                            item.lot = dataSet.data.last().y;
                        //Line Status
                        else if (dataSet.target === 300) {
                            if (Array.isUseful(dataSet.data))
                                item.status = dataSet.data.last().y > 0 ? "running" : "stopped";
                            else
                                item.status = "Unknown";
                        } else if (dataSet.target === 400) {
                            if (Array.isUseful(dataSet.data))
                                item.physicalLineId = dataSet.data.last().y;
                        } else if (dataSet.target === 500) {
                            if (Array.isUseful(dataSet.data))
                                item.totalStopTime = dataSet.data.last().y;
                        }
                        if (Object.isUseful(dataSet.data.last().error) && dataSet.data.last().error && dataSet.data.last().error !== "NoError")
                            errors.push(Oee.translateError(dataSet.data.last().error));
                        if (Object.isUseful(dataSet.data.last().warning) && dataSet.data.last().warning && dataSet.data.last().warning !== "NoWarning")
                            warnings.push(Oee.translateError(dataSet.data.last().warning));
                    }

                    if (item.efficiency > 80)
                        item.speedBackgroundImage = '-webkit-linear-gradient(left, green ' + item.efficiency + '%, white 1%)';
                    else if (item.efficiency > 60)
                        item.speedBackgroundImage = '-webkit-linear-gradient(left, yellow ' + item.efficiency + '%, white 1%)';
                    else
                        item.speedBackgroundImage = '-webkit-linear-gradient(left, red ' + item.efficiency + '%, white 1%)';

                    if (item.oee > 80)
                        item.oeeBackgroundImage = '-webkit-linear-gradient(left, green ' + item.oee + '%, white 1%)';
                    else if (item.oee > 60)
                        item.oeeBackgroundImage = '-webkit-linear-gradient(left, yellow ' + item.oee + '%, white 1%)';
                    else
                        item.oeeBackgroundImage = '-webkit-linear-gradient(left, red ' + item.oee + '%, white 1%)';
                    if (this.getTweakValue((lineIndex * 3 + 2) + "_Row " + lineIndex + " override line name")) {
                        item.physicalLineId = this.getTweakValue((lineIndex * 3 + 2) + "_Row " + lineIndex + " override line name");
                    }
                    item.logicalLineId = "";
                    item.nextLot = "";
                    item.lineIndex = lineIndex;
                }

                this.dataCollection.push(item);

                for (let i = 0; i < errors.length; i++)
                    if (errors[i])
                        this.setError("Line {0}: {1}".format(item.physicalLineId, errors[i]));
                for (let i = 0; i < warnings.length; i++)
                    if (warnings[i])
                        this.setWarning("Line {0}: {1}".format(item.physicalLineId, warnings[i]));
                errors.clear();
                warnings.clear();

            }
            if (this.showAsMatrix) {
                if (!this.matrixInitialized) {
                    this.initMatrix();
                    this.matrixInitialized = true;
                    this.createElements();
                } else {
                    this.updateElements();
                }
            }
        },
    }
}

</script>

<style scoped>

table.v-table tbody td, table.v-table tbody th {
    height: 35px;
    margin: 0;
    padding: 0px;
}

table.v-table tbody tr:not(:last-child) {
    border-bottom: none;
    margin: 0;
    padding: 0px;
}

.running {
    background-color: var(--av-green);
}

.stopped {
    background-color: var(--av-red);
}

.inTime {
    background-color: var(--av-green);
}

.late {
    background-color: var(--av-red);
}

.canvas-gauges {
    width: 100% !important;
    height: 100% !important;
}

</style>
