<template>
    <v-layout column>
        <!--TODO check form config mode -->
        <label v-if="type.toLowerCase() !== 'boolean' && type.toLowerCase() !== 'files' && title" class="title control text-xs-left">{{title}}
            <av-icon style="font-size: .8em; margin-bottom: 3px" v-if="isJoinedField">far fa-clone</av-icon>
        </label>
        <template v-if="type.toLowerCase() === 'files'">
            <v-layout row>
                <label class="title control">{{ title }}</label>
                <UploadButton no-title-update color="info" style="margin-left: 32px; margin-top: -16px" title="Load file"
                              @file-update="uploadImageCallback">
                    <template slot="icon-left">
                        <av-icon left light>fas fa-upload</av-icon>
                    </template>
                </UploadButton>
            </v-layout>
            <v-layout row class="my-2">
                <av-icon color="orange darken-3" size="15" class="mb-1 mr-2">fas fa-exclamation-triangle</av-icon>
                <label class="subheading" style="color: #EF6C00">{{$gettext('Please note that {appName} can only open pdf and image files. All other file types can be stored and downloaded but require an external application').format({appName: $config.appName})}}</label>
            </v-layout>
        </template>
        <av-select
                v-on:input="updateValue"
                :label="label"
                v-if="isJoinedField && !isTransactionAndPrimaryField"
                :value="value"
                :items="joinItem"
                class="control"
                :dynamicID="dynamicID"
                :disabled="disabled || variable.readonly"
        ></av-select>
        <av-select
                v-on:input="updateValue"
                :label="label"
                v-if="isTransactionAndPrimaryField"
                :value="value"
                :items="keyValues"
                class="control"
                :dynamicID="dynamicID"
                :disabled="disabled || variable.readonly"
        ></av-select>
        <av-text-field
                v-on:input="updateValue"
                :label="label"
                v-if="type.toLowerCase() === 'text' && !isJoinedField && !isTransactionAndPrimaryField"
                :value="value"
                class="control"
                :dynamicID="dynamicID"
                :disabled="disabled || restrictedMode || variable.readonly"
        ></av-text-field>
        <av-text-field v-if="type.toLowerCase() === 'text-number' && !isJoinedField && !isTransactionAndPrimaryField" :label="label"
                       v-on:input="updateValue" type="number" :value="value" class="control" :dynamicID="dynamicID" :disabled="disabled || variable.readonly"></av-text-field>

        <av-text-field v-if="type.toLowerCase() === 'number' && !isJoinedField && !isTransactionAndPrimaryField"
                       v-on:input="updateValue" type="number"
                       :label="label"
                       append-outer-icon="add"
                       @click:append-outer="addStep"
                       prepend-icon="remove"
                       @click:prepend="delStep"
                       :value="value"
                       class="control"
                       :dynamicID="dynamicID"
                       :disabled="disabled || variable.readonly"></av-text-field>
        <av-select
                v-if="(type.toLowerCase()==='single option' || type.toLowerCase()==='multi options') && variable.inputMode.toLowerCase() !== 'big buttons' && !variable.enableManualEntryForSingleOption"
                v-on:input="updateValue"
                :value="value"
                :label="label"
                :items="options"
                :chips="type.toLowerCase()==='multi options'"
                :deletable-chips="type.toLowerCase()==='multi options'"
                :hide-no-data="type.toLowerCase()==='multi options'"
                :hide-selected="type.toLowerCase()==='multi options'"
                :multiple="type.toLowerCase()==='multi options'"
                class="control"
                :dynamicID="dynamicID"
                :disabled="disabled || variable.readonly"></av-select>
        <v-combobox
            v-if="type.toLowerCase()==='single option' && variable.inputMode.toLowerCase() !== 'big buttons' && variable.enableManualEntryForSingleOption"
            v-on:input="updateValue"
            :value="value"
            :label="label"
            :items="options"
            :chips="type.toLowerCase()==='multi options'"
            :deletable-chips="type.toLowerCase()==='multi options'"
            :hide-no-data="type.toLowerCase()==='multi options'"
            :hide-selected="type.toLowerCase()==='multi options'"
            :multiple="type.toLowerCase()==='multi options'"
            class="control"
            :dynamicID="dynamicID"
            :disabled="disabled || variable.readonly">
        </v-combobox>
        <v-datetime-picker class="control" v-if="type.toLowerCase()==='datetime'"
                :datetime="value" @input="updateValue">
        </v-datetime-picker>
        <v-layout v-if="type.toLowerCase()==='boolean'" row class="control">
            <v-checkbox v-model="value" v-on:change="updateValue($event === true)" :label="(title || label) + (preview ? ':' + value : '')" :disabled="disabled || variable.readonly " />
            <av-icon :color="value ? 'green' : 'red'" class="mb-2" style="width: 70px">{{ value ? "fa-check" : "fa-times" }}</av-icon>
            <label class="font-weight-bold">{{ value? $gettext('true') : $gettext('false') }}</label>
        </v-layout>

        <div class="body-2 control text-xs-left" style="color: var(--av-red); margin-top: -8px!important" v-if="validationError">{{ validationError }}</div>
        <div class="body-1 control text-xs-left" style="color: var(--av-blue); margin-top: -8px!important"
             v-if="type.toLowerCase() !== 'files' && !validationError && variableHint">{{ variableHint }}</div>
        <!--TODO put description right or bottom based on isMobile -->
        <div class="body-1 control text-xs-left ma-2" v-if="showDescription">{{ variable.description }}</div>

        <v-layout v-if="(type.toLowerCase()==='single option' || type.toLowerCase()==='multi options') && variable.inputMode.toLowerCase() === 'big buttons'" row wrap justify-center>
            <v-hover v-for="(option, index) in variable.options">
                <v-card slot-scope="{ hover }" :class="`elevation-${(hover || isBigButtonChecked(option)) ? 20 : 5}`"
                        @click="bigButtonClick(option)" :id="computedVcardId + 'BigButton'"
                        :style="'overflow: hidden; width: 200px; height: 100px; text-align: center; margin: 10px; font-weight:400; background-color: {0}; {1}'.format(buttonsColor[index], ($avStyle.whiteTextNeeded(buttonsColor[index]) ? 'color: white' : ''))">
                    <av-icon v-if="isBigButtonChecked(option)" color="white" style="position: absolute; top: 70px; left: 10px;">
                        fas fa-check fa-3x
                    </av-icon>
                    <v-card-text style="word-wrap: break-word; font-weight: 400; padding: 10px" class="title">{{ option }}
                    </v-card-text>
                </v-card>
            </v-hover>
        </v-layout>
        <v-container v-if="type.toLowerCase() === 'files'" class="pa-0">
            <v-layout row align-left class="pa-0">
                <template v-for="file in value">
                    <av-file-viewer :file-name="file.FileName" :type="file.Type" :data="file.Value" :mediaId="file.MediaId" :medium="true" :key="file.MediaId" :vertical="true" class="pa-0">
                        <template slot="appendButton">
                            <v-btn flat icon small @click="deleteFile(file)" >
                                <av-icon color="error" small>fa-trash</av-icon>
                            </v-btn>
                        </template>
                    </av-file-viewer>
                </template>
            </v-layout>
        </v-container>
    </v-layout>
</template>

<script>

    import ColorSequence from '@/api/colorsequences'
    import UploadButton from 'vuetify-upload-button';
    import Entity from "@/api/entities";
    import VueInstance from "@/api/vueinstance";
    import AvFileViewer from "@/components/av-components/av-file-viewer";

    export default {
        name: "Field",
        components: { UploadButton, AvFileViewer },
        props: {
            variable: {
                default() {
                    return {}
                }
            },
            value: {
                default() {
                    return null
                }
            },
            onlyShowVarName: {
                default() {
                    return false
                }
            },
            label: {
                default() {
                    return undefined
                }
            },
            preview: {
                default() {
                    return false
                }
            },
            showDescription: {
                default() {
                    return true
                }
            },
            configurator: {
                default() {
                    return false
                }
            },
            restrictedMode: {
                default() {
                    return false
                }
            },            
            dynamicID: {
                default() {
                    return ""
                }
            },
            disabled: {
                default() {
                    return false
                }
            },
            fullFormVariables: {
                default() {
                    return []
                }
            },
            valuesChangedEvent: {
                default() {
                    return 0;
                }
            }
        },
        data: function () {
            return {
                joinItem: [],
                keyValues: []
            }
        },
        asyncComputed: {
            async options() {
                if (this.variable.hasOwnProperty('linkedVariables') && Array.isUseful(this.variable.linkedVariables)) {
                    debugger
                    let options = [];
                    for (const variable of this.variable.linkedVariables) {
                        await this.$datalayer.getDistinctValues(variable.index, variable.root, variable.name, variable.type)
                            .then(result => {
                                options.push.apply(options, result);
                            })
                            .catch(err => {
                                console.log(err);
                            })
                    }
                    if (Array.isUseful(this.variable.options)) {
                        options.push.apply(options, this.variable.options);
                    }
                    return options;
                }
                if (!Array.isUseful(this.variable.options)) {
                    if (this.variable.hasOwnProperty('linkedToParent') && this.variable.linkedToParent && this.valuesChangedEvent) {
                        let linkedLine = this.fullFormVariables.findItemByKeyRecursive('name', this.variable.linkedToParent);
                        let linkedLineChosen = linkedLine.items[linkedLine.itemIndex];
                        if (linkedLineChosen.type === 'Multi options' && Array.isUseful(linkedLineChosen.value)) {
                            let options = [];
                            linkedLineChosen.value.forEach(line => {
                                options.push.apply(options, this.variable.options[line]);
                            });
                            if (Array.isUseful(this.variable.value)) {
                                this.variable.value.forEach(val => {
                                    if (!options.includes(val))
                                        this.variable.value = this.variable.value.filter(v => v !== val);
                                });
                            }
                            return options;
                        } else if (linkedLineChosen.type === 'Single option' && linkedLineChosen.value) {
                            return this.variable.options[linkedLineChosen.value];
                        }
                    }
                    return [];
                }
                if(this.configurator) {
                    let options = this.variable.options.slice();
                    options.unshift("");
                    return options;
                }
                return this.variable.options;
            },
        },
        computed: {
            title() {
                if(this.configurator)
                    return "";
                return (this.onlyShowVarName || !this.variable.title) ? this.variable.name : this.variable.title;
            },
            type() {
                if(this.configurator && this.variable.type.toLowerCase() === 'number')
                    return 'text-number';
                return this.variable.type;
            },
            computedVcardId() {
                return this.toCamelCase(this.$router.history.current.name + 'VCard')
            },
            validationError() {
                if(this.configurator)
                    return "";
                return this.$dataEntry.validateVariable(this.variable, true)
            },
            variableHint() {
                if (this.variable.hideHints || this.configurator)
                    return "";
                return this.$dataEntry.variableHints(this.variable);
            },
            buttonsColor() {
                return ColorSequence.getColors(this.variable.options.length)
            },
            joinKeys() {
                return this.getAllEntityPrototypes()
            },
            isJoinedField() {
                return (this.variable.join && this.variable.join.entity && this.variable.join.key);
            },
            isTransactionAndPrimaryField() {
                return (this.variable.isTransaction && this.variable.primaryCharacteristic);
            }
        },
        mounted() {
            //!!!!!Never forget alerts in code
            // this.$root.$on("formName", (value) => {
            //     alert(value)
            // });
            //TODO verify this code
            if (this.$config.options.forms.entities || this.$config.options.forms.transactions) {
                this.getAllEntityPrototypes(this.variable.join.entity);
                if (this.isTransactionAndPrimaryField){
                    this.getKeyValueForKeys({key: this.variable.name, entityPrototype: this.variable.entityPrototype})
                }
            }
        },
        methods: {
            getKeyValueForKeys(keyName) {
                Entity.getKeyValueForKeys(null, keyName).then(list => {
                    list.map(item => this.keyValues.push(item))
                })
            },
            getAllEntityPrototypes(entity) {
                Entity.getAllEntityByDbKey(entity).then(list => {
                    list.map(item => item.IsEntityActive && this.joinItem.push(item[item.NameSpace][this.variable.join.key]))
                })
            },
            addStep() {
                let step = this.variable.step > 0 ? this.variable.step : 1;
                let value = Number(this.value === "" ? 0 : this.value);
                let max = (this.variable.max === "" ? 0 : this.variable.max) > 0 && this.variable.mustMatch ? (this.variable.max === "" ? 0 : this.variable.max) : value + step;
                this.value = Number(parseFloat((value + step <= max ? value + step : value)).toFixed(10));
                this.updateValue(this.value)
            },
            delStep() {
                let step = this.variable.step > 0 ? this.variable.step : 1;
                let value = Number(this.value === "" ? 0 : this.value);
                let min = this.variable.mustMatch ? (this.variable.min === "" ? 0 : this.variable.min) : value - step;
                this.value = Number(parseFloat((value - step >= min ? value - step : value)).toFixed(10));
                this.updateValue(this.value)
                },
            updateValue(event) {
                if (this.variable.maxChoices && event.length > this.variable.maxChoices) {
                    VueInstance.get().showErrorNotification(this.$gettext("Maximum Choices for this input is {0}").format(this.variable.maxChoices), true);
                } else if (event.length < this.variable.minChoices) {
                    VueInstance.get().showErrorNotification(this.$gettext("Minimum Choices for this input is {0}").format(this.variable.minChoices), true);
                }
                // let checkIfObjHasChildren = function(obj) {
                //   if (!obj || !obj.isTransaction){
                //     return null
                //   }
                //   obj.mandatory = true
                //   obj.value = null
                //
                //   for(var i in obj) {
                //     if(obj.children[0].children[0]){
                //       var foundValue = checkIfObjHasChildren(obj.children[0]);
                //       if(!foundValue) {
                //         return foundValue; }
                //     }
                //   }
                //   return null;
                // };
                // if(Array.isUseful(this.variable.children))
                //     checkIfObjHasChildren(this.variable.children[0])
                this.value = event;
                this.variable.value = this.value;
                this.$emit('input', this.$dataEntry.convertValue(this.type, event));
                //TODO sane this by changing field to name instead of title or, better push all variable
                this.$emit('onVariableChange',{title: this.variable.name, description: this.variable.description, value: this.value})
            },
            isBigButtonChecked(item) {
                if (this.type.toLowerCase() === 'single option')
                    return (this.value === item);
                else
                    return (Array.isUseful(this.value) && this.value.includes(item));
            },
            bigButtonClick(item) {
                if (this.type.toLowerCase() === 'single option') {
                    if (this.value === item)
                        this.value = null;
                    else this.value = item;
                } else {
                    if (!Object.isUseful(this.value) || !Array.isArray(this.value))
                        this.value = [];
                    if (this.value.includes(item))
                        this.value.removeItem(item);
                    else this.value.push(item);
                }
                this.updateValue(this.value);
            },
            uploadImageCallback(file) {
                if (file) {
                    const reader = new FileReader();
                    let self = this;
                    reader.onload = () => {
                        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                        if ((encoded.length % 4) > 0) {
                            encoded += '='.repeat(4 - (encoded.length % 4));
                        }
                        if (!self.value)
                            self.value = [];
                        self.value.push({
                            FileName:file.name,
                            Type:file.type,
                            Value: encoded
                        });
                        self.updateValue(self.value)
                        // let img = new Image();
                        // img.onload = () => {
                        //     if (!self.value)
                        //         self.value = [];
                        //     self.value.push({
                        //         name:file.name,
                        //         type:file.type,
                        //         data: encoded
                        //     });
                        //     self.updateValue();
                        // };
                        // img.src = "data:image/png;base64," + encoded;
                    };
                    reader.readAsDataURL(file);
                }
            },
            deleteFile(file){
                this.value.removeItemRecursive(file);
            }
        },
        watch: {
            type(newV, oldV) {
                if (this.configurator)
                    return;
                if (newV !== oldV) {
                    if (newV.toLowerCase() === "boolean")
                        this.$emit('input', false);
                    else
                        this.$emit('input', undefined);
                }
            }
        }
    }
</script>

<style scoped>
    .control {
        max-width: 500px;
    }
</style>
