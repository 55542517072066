<template>
    <v-card v-if="properties" class="full-size av-card" style="promin-height: calc(100% - 50px); max-height: calc(100%)" elevation="0" data-qa-type="card" data-qa-name="properties">
        <v-card-title class="sectionTitle" v-translate>Item settings</v-card-title>
        <v-divider/>
        <v-layout column style="height: calc(100% - 64px); overflow: auto" pa-3>
            <v-layout row pa-0 ma-0 pt-3>
                <v-flex :xs6="hasVisibleTweaks && !singleColumn" :xs12="!hasVisibleTweaks|| singleColumn">
                    <v-layout v-if="$dynamicElements.isDefaultItemName(elementName)" row id="itemDefaultNameWarning" class="mb-4">
                        <av-icon color="error">fas fa-exclamation-triangle</av-icon>
                        <label class="subheading ml-2" style="color: var(--av-red);" v-translate>Please choose a valid identification name</label>
                    </v-layout>
                    <av-text-field ref="input" :disabled="!renamable" :label="visualizationName" v-model="elementName" @input="nameChanging()" @change="NameChanged()" class="osk" clearable
                                   :placeholder="$i18n.CommonAttributes()['type here']" id="InputUniqueItemName" data-qa-type="input-text" data-qa-name="item-name"></av-text-field>
                    <av-text-field v-if="type !== 'rules' && type !== 'queries' && type !== 'recipes'" ref="field" class="mt-4 osk" clearable :placeholder="$i18n.CommonAttributes()['type here']"
                                   :label="$gettext('Title')" :value="properties.title"  @click:clear="properties.title = ''" @change="properties.title = $event" data-qa-type="input-text" data-qa-name="title"></av-text-field>

                    <av-textarea class="mt-4" :label="$gettext('Description')" :placeholder="$i18n.CommonAttributes()['type here']"
                                 :value="properties.description" @change="properties.description = $event" rows="3" data-qa-type="input-textarea" data-qa-name="item-description"/>
                    <v-combobox multiple class="mt-4" :placeholder="$gettext('Add tags to categorize this item')" :items="tags" :label="$gettext('Item tags')" v-model="properties.tags" data-qa-type="input-select" data-qa-name="item-tags"/>
                    <av-switch v-if="type === 'widgets'" class="mt-4" :label="$gettext('Show time window')" v-model="properties.showTimeWindow" data-qa-type="input-switch" data-qa-name="show-time-window"/>
                    <av-switch v-if="type === 'widgets' || type === 'forms'" class="mt-3" :label="$gettext('Show title bar')" v-model="properties.showTitleBar" data-qa-type="input-switch" data-qa-name="show-title-bar"/>
                    <av-switch v-if="type === 'widgets'" class="mt-3" :label="$gettext('Hide warnings')" v-model="properties.hideWarnings" data-qa-type="input-switch" data-qa-name="hide-warnings"/>
                    <v-switch v-if="type === 'widgets'" class="mt-3" :label="autoRefreshInterval" :input-value="properties.forcedDataRefreshInterval === 0" @change="setAutoRefresh($event)" data-qa-type="input-switch" data-qa-name="automatic-refresh-interval"/>
                    <v-layout row v-if="type === 'widgets' && properties.forcedDataRefreshInterval > 0" class="ml-5">
                        <v-btn icon small data-qa-type="button" data-qa-name="data-refresh-interval-minus"><av-icon color="info" @click="properties.forcedDataRefreshInterval > 5 && (properties.forcedDataRefreshInterval -= 5)">fas fa-minus</av-icon></v-btn>
                        <label class="subheading mx-2 mt-2">{{$dateTime.formatTime(properties.forcedDataRefreshInterval, false, false)}}</label>
                        <v-btn icon small data-qa-type="button" data-qa-name="data-refresh-interval-plus"><av-icon color="info" @click="properties.forcedDataRefreshInterval += 5">fas fa-plus</av-icon></v-btn>
                    </v-layout>
                    <icon-selector class="mt-2" v-if="type === 'widgets' || type === 'forms' " v-model="properties.icon"/>

                    <av-multi-select class="mt-5" v-if="(type === 'reports' || type === 'forms' || this.type === 'widgets' || this.type === 'queries' || (this.type === 'rules' && this.properties.isActivity))" :label="visibleToLevels + ':'" item-text="show"
                                :placeholder="$i18n.CommonAttributes()['select']"
                               :items="getlevelsUsers('visible')" v-model="properties.visibleToLevels" :manage-everyone="true" :return-object="false" :keep-selected="['av.administrator']"
                                data-qa-type="input-multiselect" data-qa-name="visible-to-levels">
                    </av-multi-select>
                    <av-multi-select class="mt-3" :label="editableToLevels + ':'" item-text="show"
                               :placeholder="$i18n.CommonAttributes()['select']" :items="getlevelsUsers('editable')"
                               v-model="properties.editableToLevels" :manage-everyone="true" :return-object="false" :keep-selected="['av.administrator']"
                                     data-qa-type="input-multiselect" data-qa-name="editable-to-levels">
                    </av-multi-select>
                    <av-multi-select class="mt-5" v-if="type === 'reports' || type === 'forms'" :label="exposableToLevels + ':'"
                                     item-text="show" :placeholder="$i18n.CommonAttributes()['select']"
                                     :items="getlevelsUsers('exposable')"
                                     v-model="properties.exposableToLevels" :manage-everyone="true" :return-object="false"
                                     data-qa-type="input-multiselect" data-qa-name="exposable-to-levels">
                    </av-multi-select>
                    <template v-if="singleColumn && Array.isUseful(visualizationTweaks)">
                        <div style="height: 60px"></div>
                        <SimpleConfigurationsEditor :descriptor="visualizationTweaks"/>
                    </template>
                </v-flex>
                <template v-if="!singleColumn && hasVisibleTweaks">
                    <div style="width: 60px"></div>
                <v-flex xs6>
                        <SimpleConfigurationsEditor :descriptor="visualizationTweaks"/>
                    </v-flex>
                </template>
            </v-layout>
        </v-layout>
    </v-card>
</template>

<script>

import SimpleConfigurationsEditor from '@/components/utilities/SimpleConfigurationsEditor'
import IconSelector from '@/components/utilities/IconSelector';
import RulesEngine from '@/api/rules.js';
import Entity from "@/api/entities";

export default {
        name: "PropertiesEditor",
        components: {
            SimpleConfigurationsEditor, IconSelector
        },
        data() {
            return {
                initialName: "",
                elementName: "",
                tags: [],
            }
        },
        props: {
            properties: {
                type: Object,
                default: null
            },
            visualizationTweaks: {
                type: Array,
                default: null
            },
            ruleRunning: {
                type: Boolean,
                default: false
            },
            renamable: {
                type: Boolean,
                default: true
            },
            singleColumn: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            value(value) {
                this.$emit('onchange', value);
                // or generate/simulate a native events (not sure how, but its outside Vue's realm I think
            },
            'properties.exposableToLevels'(newValue, oldValue) {
                if (!Array.isUseful(oldValue))
                    oldValue = [];
                if (!Array.isUseful(newValue))
                    newValue = [];
                if ((!oldValue.some(user => user.key === this.$grants.getLevel().key) && newValue.some(user => user.key === this.$grants.getLevel().key)) || (oldValue.some(user => user.key === this.$grants.getLevel().key) && !newValue.some(user => user.key === this.$grants.getLevel().key))) {
                    this.$root.reloadDynamicLinks = true;
                }
            },
            'properties.tags'(after, before) {
                if (!(after instanceof Array) || !(before instanceof Array)) {
                    return;
                }
                if (after.length > before.length)
                    this.saveTagsSettings(after.last());
            }
        },
        computed: {
            hasVisibleTweaks() {
                if(!Array.isUseful(this.visualizationTweaks))
                    return false;
                return Array.isUseful(this.visualizationTweaks.filter((item) => !item.hidden))
            },
            selectAllLevelsForExposable() {
                return this.properties.exposableToLevels.length === this.$grants.getLevels().length;
            },
            selectAllLevelsForVisible() {
                return this.properties.visibleToLevels.length === this.$grants.getLevels().length;
            },
            selectAllLevelsForEditable() {
                return this.properties.editableToLevels.length === this.$grants.getLevels().length;
            },
            icon() {
                if (this.selectAllLevelsForExposable) return 'mdi-close-box';
                return 'mdi-checkbox-blank-outline';
            },
            type() {
                if ((this.properties.type >= this.$dynamicElements.ReportsTypeRangeMin && this.properties.type <= this.$dynamicElements.ReportsTypeRangeMax)
                    || this.properties.type === this.$dynamicElements.Types.LegacyReport)
                    return "reports";
                else if (this.properties.type >= this.$dynamicElements.RulesTypeRangeMin && this.properties.type <= this.$dynamicElements.RulesTypeRangeMax)
                    return "rules";
                else if (this.properties.type === this.$dynamicElements.Types.ReusableQuery)
                    return "queries";
                else if (this.properties.type >= this.$dynamicElements.FormTypeRangeMin && this.properties.type <= this.$dynamicElements.FormTypeRangeMax)
                    return "forms";
                else if (this.properties.type >= this.$dynamicElements.RecipesTypeRangeMin && this.properties.type <= this.$dynamicElements.RecipesTypeRangeMax)
                    return "recipes";
                else if (this.properties.type >= this.$dynamicElements.WizardsTypeRangeMin && this.properties.type <= this.$dynamicElements.WizardsTypeRangeMax)
                    return "wizards";
                else return "widgets";
            },
            visualizationName() {
                return this.$gettext("Item name (must be unique)")
            },
            exposableToLevels() {
                return this.$gettext("These users can see menu link to this item");
            },
            visibleToLevels() {
                return this.$gettext("These users can see this item");
            },
            editableToLevels() {
                return this.$gettext("These users can edit this item");
            },
            autoRefreshInterval() {
                return this.$gettext("Automatic refresh rate");
            },
            },
        mounted: function () {
            //Store the initial name to provide feedback of names collisions
            this.initialName = this.properties.name;
            //Detach element name from properties to avoid saving while typing
            this.elementName = this.properties.name;
            //Initialize by default the showTitleBar option that was added in 1.2
            if (!Object.isUseful(this.properties.showTitleBar))
                this.$set(this.properties, "showTitleBar", true);
            if (!Array.isUseful(this.properties.editableToLevels))
                this.properties.editableToLevels = ["all"];
            if ((this.type === 'widgets' || (this.type === 'rules' && this.properties.isActivity))  && !Array.isUseful(this.properties.visibleToLevels))
                this.properties.visibleToLevels = ["all"];
            if (this.type !== 'widgets' && !Array.isUseful(this.properties.exposableToLevels))
                this.properties.exposableToLevels = ["all"];

            this.$root.$emit('sendPrototypeTitle', this.elementName);
            this.getTags();
        },
        methods: {
            getlevelsUsers(item) {
                let users = [];
                switch (item) {
                    case 'exposable':
                    case 'visible':
                        users = this.$grants.getLevels();
                        break;
                    case 'editable':
                        //admin must have total control over editing everything
                        users = this.$grants.getLevels();
                        break;
                }
                return users;
            },

            setAutoRefresh(value) {
                if(value)
                    this.properties.forcedDataRefreshInterval = 0;
                else this.properties.forcedDataRefreshInterval = 10;
            },

            async nameChanging() {
                let error1 = this.$gettext("Element name already used in DB, please choose a different one");
                let error2 = this.$gettext("Following characters are not allowed: \\ / : * ? \" < > | .");
                let error3 = this.$gettext("Rules cannot be renamed while running. Please stop rule before renaming.");
                let error4 = this.$gettext("Rule name can only contain letters, numbers, spaces and minus sign (-)");
                if (this.elementName !== this.properties.name) {
                    if (this.type === "rules" && await RulesEngine.isRuleRunning(this.properties.name)) {
                        this.$root.showErrorNotification(error3, false, true);
                    }  //Rule doesn't start whith character "_" inside visualization name
                    else if (this.type === "rules" && !RulesEngine.validateRuleName(this.elementName)) {
                        this.$root.showErrorNotification(error4, false, true);
                        this.elementName = this.properties.name; //Can't rename a running rule, restore previous one
                    }
                    else if (!this.$dynamicElements.validateName(this.elementName, this.type).status) {
                        error1 = this.$dynamicElements.validateName(this.elementName, this.type).message;
                        this.$root.showErrorNotification(error1, false, true);
                    }
                    else if (!this.$utils.validateFileName(this.elementName)) {
                        this.$root.showErrorNotification(error2, false, true);
                    }
                    else this.$root.clearNotifications([error1, error2, error3, error4]);
                } else this.$root.clearNotifications([error1, error2, error3, error4]);
            },
            async NameChanged() {
                let error1 = this.$gettext("Element name already used in DB, please choose a different one");
                let error2 = this.$gettext("Following characters are not allowed: \\ / : * ? \" < > | .");
                let error3 = this.$gettext("Rules cannot be renamed while running. Please stop rule before renaming.");
                let error4 = this.$gettext("Rule name can only contain letters, numbers, spaces and minus sign (-)");
                this.elementName = this.elementName.trim(); //Untrimmed ids will give problems to API calls since names appears on the urls
                if (this.elementName !== this.properties.name) {
                    if (this.type === "rules" && await RulesEngine.isRuleRunning(this.properties.name)) {
                        this.$root.showErrorNotification(error3, false, true);
                        this.elementName = this.properties.name; //Can't rename a running rule, restore previous one
                    }
                    else if (this.type === "rules" && !RulesEngine.validateRuleName(this.elementName)) {
                        this.$root.showErrorNotification(error4, false, true);
                        this.elementName = this.properties.name; //Can't rename a running rule, restore previous one
                    }
                    else if (!this.$dynamicElements.validateName(this.elementName, this.type).status) {
                        error1 = this.$dynamicElements.validateName(this.elementName, this.type).message;
                        this.$root.showErrorNotification(error1, false, true);
                        this.elementName = this.properties.name; //Can't assign this name, restore previous one
                    }
                    else if (!this.$utils.validateFileName(this.elementName)) {
                        this.$root.showErrorNotification(error2, false, true);
                        this.elementName = this.properties.name; //Can't assign this name, restore previous one
                    } else {
                        this.$root.clearNotifications([error1, error2, error3, error4, true]);
                        this.properties.name = this.elementName; //Trigger properties change and element save
                        if (this.type !== 'widgets')
                            this.$root.reloadDynamicLinks = true;
                    }
                } else this.$root.clearNotifications([error1, error2, error3, error4]);
                this.$root.$emit('sendPrototypeTitle', this.elementName)
            },
            saveTagsSettings(elementAdded) {
                let tags = this.$settings.getTagsSettings();
                if (!Array.isUseful(tags[this.type]) || tags[this.type].filter(at => at.toLowerCase().includes(elementAdded.toLowerCase())).length === 0) {
                    tags[this.type].push(elementAdded);
                    this.$settings.saveTagsSettings(tags)
                        .catch(err => {
                            console.log(err);
                        })
                }
            },
            getTags() {
                this.tags = this.$settings.getTagsSettings()[this.type];
            }
        }
}
</script>

<style>

    /*Needed to avoid line break in text fields prefix when text contains a space (vuetify bug?)*/
    .v-text-field__prefix {
        white-space: pre;
    }

    .v-text-field {
        padding: 0px;
    }

    .v-input--selection-controls {
        margin: 0px;
        padding: 0px;
    }

    .v-input__slot {
        /*margin: 0px;*/
        margin-bottom: 0px !important;
    }

    .templateButton {
        min-width: 350px !important;
        max-width: 350px !important;
    }

</style>
