<template>
    <v-card-text  class="av-card-content" style="height: calc(100vh - 80px)">
        <v-flex style="max-width:640px">
            <table id="entity" class="table dt-body-nowrap" >
                <tr v-for="field in standardFields">
                    <template>
                        <td class="text-xs-left" style="min-width: 170px">{{ field.text }}</td>
                        <td class="text-xs-left"><pre>{{ details[field.value] }}</pre></td>
                    </template>
                </tr>
                <!--Not standard fields-->
                <tr v-for="(item, key, index) in details" :key="key">
                    <template v-if="!isStandardField(key) && !isNoShowField(key)">
                        <td class="text-xs-left" style="min-width: 170px">{{ key }}</td>
                        <td class="text-xs-left"><pre>{{ parseField(item) }}</pre></td>
                    </template>
                </tr>
            </table>
        </v-flex>
        <v-spacer></v-spacer>
    </v-card-text>
</template>

<script>
    export default {
        name: "workorderDetail",
        props:{
            details:{
                required:true,
                default:()=>{return []}
            }
        },
        data:()=>{
            return {
                customFields: [],
                noShowFields: [ "type", "tag", "BillOfMaterials", "Transitions", "Transform", "flows", "actionsGroup", "scheduleInfo"],
                standardFields: [
                    {
                        text: "Id",
                        class: "subheading",
                        sortable: false
                    },
                    {
                        text: "PhysicalLineId",
                        class: "subheading",
                        sortable: false
                    },
                    {
                        text: "RecipeId",
                        class: "subheading",
                        sortable: false
                    },
                    {
                        text: "Quantity To Produce",
                        class: "subheading",
                        sortable: false,
                        value: 'QuantityToProduce'
                    },
                    {
                        text: "Material",
                        class: "subheading",
                        sortable: false,
                        value: 'Material'
                    },
                    {
                        text: "Status",
                        class: "subheading",
                        sortable: false,
                        value: 'StatusString',
                    },
                ],
            }
        },
        created(){
            this.parseStandardFields();
        },
        methods:{
            parseStandardFields(){
                for(const element of this.standardFields) {
                    if(!element.hasOwnProperty('value')) {
                        element.value = element.text;
                    }
                }
            },
            isStandardField(property) {
                return this.standardFields.some(obj => obj.value === property)
            },
            isNoShowField(property) {
                return this.noShowFields.some(obj => obj === property)
            },
            parseField(value) {
                try {
                    if(this.$utils.isObject(value))
                        return JSON.stringify(value, null, 4);
                } catch(err) {}
                return value
            },
        }
    }
</script>

<style scoped>

    .table {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
        font-size: 18px;
        margin-top: auto;
        margin-bottom: auto;
    }

    .table td {
        border: 1px solid #ddd;
        padding: 8px;
    }

    .table tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    .table tr:hover {
        background-color: #ddd;
    }

    .dt-body-nowrap {
        white-space: nowrap;
    }
</style>
