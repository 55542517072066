<template>
    <v-container pa-0 ma-0 style="max-width: 100%; height: 100%;">
        <av-page v-if="this.$root.startedUp" toolbar-activation-on="true">
            <template slot="toolbarFixedItems">
                    <av-button @click="showFiltersPicker = true" v-show="showFiltersPickerButton"
                               color="info" buttonIcon="fas fa-filter" iconColor="light" :text="$gettext('Filters')" class="ml-4" />
                    <span class="subheading mt-4 ml-2">{{ scope().filterMessage }}</span>
                    <time-picker style="max-width: 250px;" :call-back="loadDataForTimeWindow" :time-window="scope().timeWindow" :showRefreshBtn="true" v-if="scope().showTimeFilter"/>
            </template>
            <template slot="toolbarDynamicItems">
                <v-spacer></v-spacer>
                    <v-select v-if="visibleProfiles.length > 1" :items="visibleProfiles" :item-text="'Name'" :prefix="$gettext('Profile:')"
                               style="max-width: 205px" class="mr-3 mt-1" @change="getUserProfile($event)" v-model="scope().profileName" return-object />
                    <av-button v-if="$grants.get().dataExploration.CanEditTablesVisualizationProfiles" v-show="scope().showCustomizeView" @click="customizeViewClick" :text="$gettext('Customize view')"
                               color="info" buttonIcon="fas fa-cog" iconColor="light" id="btn-configuration-view" />
                    <v-menu offset-y left>
                        <av-button slot="activator" id="btn-export-menu" :text="$gettext('Export')" buttonIcon="fa-arrow-down" color="info" iconColor="light"/>
                        <v-list>
                            <v-list-tile @click="exportReport('xlsx')" id="export-menu-xlsx">
                                <v-list-tile-action>
                                    <av-icon>fas fa-file-pdf</av-icon>
                                </v-list-tile-action>
                                <v-list-tile-title class="subheading" v-translate>Export excel</v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile @click="exportReport('pdf')" id="export-menu-pdf">
                                <v-list-tile-action>
                                    <av-icon>fas fa-file-pdf</av-icon>
                                </v-list-tile-action>
                                <v-list-tile-title class="subheading" v-translate>Export pdf</v-list-tile-title>
                            </v-list-tile>
                        </v-list>
                    </v-menu>
                    <av-button v-if="showButtonNewItem && $grants.get().dataExploration.forms" :text="newItemButtonC.newItemButtonName" color="info"
                               buttonIcon="fas fa-plus" iconColor="light" @click="newItem" />
                    <slot name="backButton"></slot>
            </template>
            <template slot="pageContent">
                    <v-data-table
                        :headers="scope().userProfile.selectedDataItems"
                        :items="visibleData"
                        class="elevation-0 subheading fixed-header v-table__overflow"
                        :pagination.sync="pagination"
                        hide-actions
                        :expand="expand"
                        item-key="itemKey" style="height: calc(100% - 50px)"
                    >
                        <template v-slot:headers="props">
                            <tr>
                                <th
                                    v-for="(header, index) in props.headers"
                                    :key=index
                                    :class="['column sortable text-xs-left subheading', pagination.descending ? 'desc' : 'asc', header.name === pagination.sortBy ? 'active' : '']"
                                    @click="changeSort(header.name)"
                                >
                                    {{ header.text }}
                                    <v-icon small>arrow_upward</v-icon>
                                </th>
                            </tr>
                        </template>
                        <template v-slot:items="props">
                            <td v-for="(value, id) in props.item" :key="id" v-if="id !== 'itemKey'"  class="text-xs-left subheading" >
                                <av-button :text="''" flat v-ripple="false" size="35px" :title="detailsGroup.text" icon v-if="id === 'details'"
                                           :buttonIcon="detailsGroup.icon" :iconColor="detailsGroup.color"   @click="props.expanded = !props.expanded;
                                               detailsExpanded[props.index] = props.expanded; actionsExpanded[props.index] = false"/>
                                <av-button :text="''" flat v-ripple="false" size="35px" :title="actions.text" icon v-else-if="id === 'actions'"
                                           :buttonIcon="actions.icon" :iconColor="actions.color" @click="props.expanded = !props.expanded;
                                               actionsExpanded[props.index] = props.expanded; detailsExpanded[props.index] = false"/>
                                <label v-else>{{ $dataEntry.convertToReadableValue(value) }}</label>
                            </td>
                        </template>
                        <template v-slot:expand="props">
                            <v-card>
                                <v-card-text v-if="actionsExpanded[props.index]">
                                    <v-layout row wrap justify-center>
                                        <template v-for="action in actions.children" v-if="action.getGrant(props.item.itemKey)">
                                            <v-btn flat v-ripple="false" @click="handleFunctionCall(action.local, action.handler, props.item.itemKey); props.expanded = false"
                                                   style="width: 200px;" :title="action.description" class="my-5">
                                                <v-layout column>
                                                    <av-icon :color="action.color" size="50">{{ action.icon }}</av-icon>
                                                    <label class="subheading font-weight-bold mt-2">{{ action.text }}</label>
                                                </v-layout>
                                            </v-btn>
                                        </template>
                                    </v-layout>
                                </v-card-text>
                                <v-card-text v-if="detailsExpanded[props.index]">
                                    <v-layout row wrap justify-center>
                                        <template v-for="detail in scope().detailsGroup.children" v-if="detail.getGrant(props.item.itemKey)">
                                            <v-btn flat v-ripple="false" @click="handleFunctionCall(detail.local, detail.handler, props.item.itemKey); props.expanded = false;
                                                        detailsExpanded = {}" style="width: 200px;" :title="detail.description" class="my-5">
                                                <v-layout column>
                                                    <av-icon :color="detail.color" size="50">{{ detail.icon }}</av-icon>
                                                    <label class="subheading font-weight-bold mt-2">{{ detail.text }}</label>
                                                </v-layout>
                                            </v-btn>
                                        </template>
                                    </v-layout>
                                </v-card-text>
                            </v-card>
                        </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <v-layout row class="align-center">
                        <v-spacer></v-spacer>
                        <label style="font-size: 0.8em; opacity: 0.7" class="mr-3">{{$gettext("Rows per page:")}}</label>
                        <v-select hide-deatils :dense="true" v-model="pagination.rowsPerPage" style="max-width:50px; font-size:0.9em" :items="pagination.items"
                                   item-key="value" item-text="text"></v-select>

                        <label style="font-size: 0.8em;  opacity: 0.7" class="ml-3 mr-3">
                            {{rowFrom}} - {{rowTo}} / {{ visibleData.length}}
                        </label>
                        <v-btn flat icon :disabled="pagination.rowsPerPage===-1 || pagination.page===1" @click="pagination.page=1">
                            <av-icon>fas fa-chevron-double-left</av-icon>
                        </v-btn>
                        <v-btn flat icon :disabled="pagination.rowsPerPage===-1 || pagination.page===1" @click="pagination.page-=1">
                            <av-icon>fas fa-chevron-left</av-icon>
                        </v-btn>
                        <v-btn flat icon :disabled="pagination.rowsPerPage===-1 || pagination.page===pages" @click="pagination.page+=1">
                            <av-icon>fas fa-chevron-right</av-icon>
                        </v-btn>
                        <v-btn flat icon :disabled="pagination.rowsPerPage===-1 || pagination.page===pages" @click="pagination.page=pages">
                            <av-icon >fas fa-chevron-double-right</av-icon>
                        </v-btn>
                    </v-layout>
            </template>
        </av-page>
        <v-dialog v-model="scope().showVariablePicker" width="620px"
                  :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="true" scrollable>
            <v-card :key="scope().newProfile">
                <v-toolbar-title class="headline text-xs-center pa-3" style="height: 100px">
                    <v-layout align-center row px-0 py-1 wrap>
                        <av-text-field v-if="scope().newProfile" :label="$gettext('New profile')"
                                       v-model="scope().profileName"
                                       style="max-width: 200px" @input="nameChanging()"/>
                        <av-select v-else :items="editableProfiles" :item-text="'Name'" :label="$gettext('Profiles')"
                                   style="max-width: 200px" @change="getUserProfile($event)"
                                   v-model="scope().profileName" return-object>
                        </av-select>
                        <av-switch class="ma-4" :label="$gettext('Set to default')"
                                   v-model="scope().userProfile.checkAsDefault"/>
                        <v-btn @click="changeProfile" style="width: 160px" v-if="scope().profilesFull.length > 0">
                            <av-icon small left>fa fa-user</av-icon>
                            {{ scope().newProfile ? $gettext('Choose profile') : $gettext('New profile') }}
                        </v-btn>
                    </v-layout>
                </v-toolbar-title>
                <v-card-text>
                    <v-layout column style="height: 800px;">
                        <v-expansion-panel class="mb-2" expand>
                            <v-expansion-panel-content class="px-2">
                                <template v-slot:header>
                                    <div class="subheading pa-0 ma-0" style="width: 90%;">
                                        <translate>Select fields</translate>
                                    </div>
                                </template>
                                <v-layout row justify-start fill-height>
                                    <v-layout column>
                                        <data-mapping-tree-view :mapping=scope().mapping
                                                                :data-items=scope().userProfile.selectedDataItems
                                                                :filterItems=scope().userProfile.selectedFilterItems />
                                    </v-layout>
                                    <v-layout column align-end>
                                        <v-tooltip>
                                            <v-btn fab small slot="activator" @click="getDataDefinitions(true)">
                                                <av-icon>fas fa-sync-alt</av-icon>
                                            </v-btn>
                                            <span v-translate>Refresh data definitions</span>
                                        </v-tooltip>
                                    </v-layout>
                                </v-layout>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel class="mb-2" expand
                                           v-show="scope().userProfile.selectedDataItems.length > 0">
                            <v-expansion-panel-content class="px-2">
                                <template v-slot:header>
                                    <div class="subheading pa-0 ma-0" style="width: 90%;">
                                        <translate>Header Names</translate>
                                    </div>
                                </template>
                                <v-layout row justify-space-between style="max-width: 95%" pa-0
                                          v-for="item in scope().userProfile.selectedDataItems">
                                    <label style="margin-top: 10px; margin-left: 15px;" class="subheading">Show
                                        <span class="font-weight-bold"> {{ item.name }}</span></label>
                                    <av-text-field :placeholder="$gettext('type here')"
                                                   :prefix="$gettext('As: ')"
                                                   v-model=item.text
                                                   clearable single-line class="osk ml-2"
                                                   style="max-width: 200px; margin-top: 6px"
                                    />
                                </v-layout>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel class="mb-2" expand>
                            <v-expansion-panel-content class="px-2">
                                <template v-slot:header>
                                    <div class="subheading pa-0 ma-0" style="width: 90%;">
                                        <translate>Grants</translate>
                                    </div>
                                </template>
                                <av-multi-select :placeholder="$i18n.CommonAttributes()['select']"
                                                 item-text="show" :label="lbMinView + ':'"
                                                 :items="$grants.getLevels()" v-model="scope().userProfile.showLevel"
                                                 style="margin-bottom: 20px" :manage-everyone="true" :return-object="false"></av-multi-select>

                                <av-multi-select :placeholder="$i18n.CommonAttributes()['select']"
                                                 item-text="show" :label="lbMinEdit + ':'"
                                                 :items="$grants.getLevels()"
                                                 v-model="scope().userProfile.editLevel" :manage-everyone="true" :return-object="false"></av-multi-select>
                                <v-spacer></v-spacer>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel class="mb-2" expand>
                            <v-expansion-panel-content class="px-2">
                                <template v-slot:header>
                                    <div class="subheading pa-0 ma-0" style="width: 90%;">
                                        <translate>Export</translate>
                                    </div>
                                </template>
                                    <v-layout justify-center>
                                        <SimpleConfigurationsEditor :descriptor="scope().visualizationTweaks"/>
                                    </v-layout>
                                <v-spacer></v-spacer>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-layout>
                </v-card-text>
                <v-card-actions style="height: 100px">
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" flat="flat"
                           @click="saveProfile"
                           :disabled="disableSaveBtn || scope().disableSave">
                           {{ scope().newProfile ? $gettext("Save profile") : $gettext('Update profile') }}
                    </v-btn>
                    <v-btn color="red darken-1" flat="flat"
                           @click="deleteProfile"
                           :key="newProfile" v-if="!scope().newProfile" :disabled="disableSaveBtn">
                        <translate>Delete profile</translate>
                    </v-btn>
                    <v-btn color="red darken-1" flat="flat"
                           @click="closeDialog" v-translate>
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showFiltersPicker" width="480px" height="640px" min-height="640px"
                  :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="true">
            <v-card :key="scope().profileName">
                <v-container fill-height pa-3>
                    <v-layout column>
                        <v-toolbar-title class="headline text-xs-center">
                            <v-layout fill-height align-center row px-0 py-1 wrap>
                                <label>
                                    <template v-if="scope().profileName"><translate>Filters for profile: </translate>{{ scope().profileName }}</template>
                                    <translate v-else>Profile name is required</translate>
                                </label>
                            </v-layout>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-layout row wrap>
                            <v-flex v-for="(filter, key) in scope().userProfile.selectedFilterItems" :key="key" xs12 class="ma-4">
                                <av-text-field v-if="filter.type === 'keyword'|| filter.type === 'long'"
                                               :label="$gettext('Filter by ') + filter.name"
                                               :type="(filter.type === 'long')? 'number' : ''" v-model="filter.value"/>
                                <v-flex v-if="filter.type === 'boolean'">
                                    <label>{{ filter.name }}</label>
                                    <v-radio-group row  class="mt-2" v-model="filter.value">
                                        <v-radio :label="$gettext('Exclude')" value="exclude"></v-radio>
                                        <v-radio :label="$gettext('True')" :value=true></v-radio>
                                        <v-radio :label="$gettext('False')" :value=false></v-radio>
                                    </v-radio-group>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                        <v-spacer></v-spacer>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1"
                                   flat="flat" @click="closeFilterDialog" :disabled="scope().profileName === ''">
                                <translate>Ok</translate>
                            </v-btn>
                            <v-btn color="red darken-1" flat="flat"
                                   @click="resetFilters">
                                <translate>Cancel</translate>
                            </v-btn>
                        </v-card-actions>
                    </v-layout>
                </v-container>
            </v-card>
        </v-dialog>
        <v-dialog v-model="scope().showNewItemDialog" width="720px" height="350px" :persistent="true">
            <v-card>
                <v-toolbar card dense>
                    <v-toolbar-title class="headline text-xs-center">
                        {{ scope().auditType + " " + $gettext('Form Selection')}}
                    </v-toolbar-title>
                </v-toolbar>
                <v-layout column pa-3>
                    <v-spacer />
                    <v-spacer />
                    <v-select :items="newItemButtonC.itemForms" v-model="newItemButtonC.selectedForm" :label="$gettext('Select a {formType} form').format({formType: scope().auditType})"/>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" flat="flat" v-translate @click="openSelectedForm" :disabled="newItemButtonC.selectedForm === ''">
                            OK
                        </v-btn>
                        <v-btn color="red darken-1" flat="flat" v-translate @click="scope().showNewItemDialog = false">
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-layout>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>

import DataMappingTreeView from '@/components/utilities/DataMappingTreeView'
import Vue from "vue";
import Grants from "@/api/grants";
import ImportExportExcel from "@/components/utilities/ImportExportExcel";
import SimpleConfigurationsEditor from '@/components/utilities/SimpleConfigurationsEditor';
import TimePicker from '@/components/utilities/TimePicker';
import AvPage from "@/components/av-components/av-page";
import {TimeSpan} from '@/api/datetimeutils';

export default {
    name: "GenericTable",
    components: {
        SimpleConfigurationsEditor,
        DataMappingTreeView,
        ImportExportExcel,
        TimePicker,
        AvPage
    },
    data: () => ({
        showVariablePicker: false,
        mapping: [],
        GENERIC_TABLE: true,
        showFiltersPicker: false,
        listOfItems: [],
        getListOfItems: null,
        scopeIndex: "",
        scopeType: "",
        formsMetadata: null,
        userProfile: {
            selectedDataItems: [],
            selectedFilterItems: [],
            checkAsDefault: false,
            showLevel: [],
            editLevel: [],
            reportTemplate: null,
            reportTemplateName: '',
            importedReportTemplate: false
        },
        newProfile: false,
        profileName: "",
        pagination: {
            sortBy: '',
            rowsPerPage: -1,
            items:[
                {text:"5",value:5},
                {text:"10",value:10},
                {text:"25",value:25},
                {text:"All",value:-1},
            ]
        },
        unsavedChanges: false,
        profileChanged: false,
        defaultVisualizationProfile: [],
        auditType: "",
        profilesFull: [],
        waitItems: null,
        disableSave: false,
        listOfItemsFlatten: [],
        newItemButton: null,
            // type: -1,
            // backPath: "",
            // itemForms: [],
            // newItemButtonName: "",
            // selectedForm: ""

        showNewItemDialog: false,
        filterMessage: "",
        visualizationTweaks: [],
        showTimeFilter: false,
        timeWindow: new TimeSpan(),
        alwaysTimeFilter: false,
        loadAll: false,
        expand: true,
        timeRefreshTimeout: null,
        actionsExpanded: {},
        detailsExpanded: {},
        detailsGroup: {
            icon: 'fa-list',
            text: '',
            color: 'blue',
            children: [
                {
                    icon: 'fa-list',
                    text: 'View details',
                    color: 'blue',
                    handler: 'itemDetails',
                    description: 'View item details',
                    local: true,
                    getGrant: () => { return true; }
                }
            ]
        },
        customActions: null,
        actionsGroup: {
            icon: 'fas fa-pen',
            text: '',
            color: 'blue',
            children: [
                {
                    icon: 'fa-pen',
                    text: 'Edit item',
                    color: 'blue',
                    handler: 'editItem',
                    local: true,
                    getGrant: () => { return true; }
                },
                {
                    icon: 'fa-trash',
                    text: 'Delete item',
                    color: 'red',
                    handler: 'deleteItem',
                    local: true,
                    getGrant: () => { return true; }
                }
            ]
        },
        needActionAndDetails: false,
    }),
    mounted() {
        this.scope().visualizationTweaks = [
            {
                name: this.$gettext("Import excel template"),
                id: "ImportTemplate",
                type: "file",
                importCallback: this.importExcelCallback,
                deleteCallback: this.deleteTemplateCallback,
                value: "No template loaded",
                default: function() {
                    return "No template loaded";
                }
            },
        ]
    },
    computed: {
        actions() {
            if(this.scope().customActions)
                return this.scope().customActions;
            else return this.scope().actionsGroup
        },
        visibleProfiles() {
            let visibleProfiles = [];
            if(this.scope().profilesFull.length > 0) {
                for (let profile of this.scope().profilesFull) {
                    let showLevel=profile.profileData.showLevel
                    if (profile.profileData.showLevel.length>0 && typeof profile.profileData.showLevel[0] === 'object' ){
                        showLevel=profile.profileData.showLevel.map(l=>{return l.key})
                    }
                    if (Grants.enabledForVisible(showLevel))
                        visibleProfiles.push(profile);
                }

            }
            return visibleProfiles;
        },
        editableProfiles() {
            let editableProfiles = []
            if(this.scope().profilesFull.length > 0){
                for (let profile of this.scope().profilesFull) {
                    let editLevel=profile.profileData.editLevel
                    if (profile.profileData.editLevel.length>0 && typeof profile.profileData.editLevel[0] === 'object' ){
                        editLevel=profile.profileData.editLevel.map(l=>{return l.key})
                    }
                    if (Grants.enabledForEditable(editLevel))
                        editableProfiles.push(profile)
                }
            }
            return editableProfiles
        },
        showCustomizeView() {
            return Array.isUseful(this.scope().listOfItemsFlatten);
        },
        showFiltersPickerButton() {
            return Array.isUseful(this.scope().userProfile.selectedFilterItems.filter(item => item.type !== 'date'));
        },
        disableSaveBtn() {
            return !this.scope().profileName;
        },
        filterData() {
            let self = this;
            let filtered = self.scope().listOfItemsFlatten;
            self.scope().userProfile.selectedFilterItems.forEach(filter => {
                if (filter.value || filter.value === false) {
                    switch (filter.type) {
                        case 'keyword':
                            filtered = filtered.filter((visualization) => {
                                if (visualization[filter.name]) {
                                    return filter.value.toLowerCase().split(' ').every(v => visualization[filter.name].toLowerCase().includes(v))
                                }
                            });
                            break;
                        case 'long':
                            filtered = filtered.filter(visualization => visualization[filter.name]? visualization[filter.name].toString().includes(filter.value) : "");
                            break;
                        case 'boolean':
                            if (filter.value === true || filter.value === false)
                                filtered = filtered.filter(visualization => visualization[filter.name] === filter.value);
                            break;
                    }
                }
            });
            if (!self.scope().alwaysTimeFilter) {
                if (self.scope().userProfile.selectedFilterItems.some(item => { return item.type === 'date' })) {
                    self.scope().showTimeFilter = true;
                    self.scope().loadAll = true;
                }
                else {
                    //Load all records only once when timestamp filter is removed
                    if (self.scope().loadAll) {
                        self.scope().timeWindow.setPredefined(2628000);
                        self.scope().loadDataForTimeWindow();
                    }
                    self.scope().showTimeFilter = false;
                }

            }
            return filtered;
        },
        visibleData() {
            let self = this;
            //Remove duplicates (like assetId)
            self.scope().userProfile.selectedDataItems = self.scope().userProfile.selectedDataItems.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.name === value.name
                    ))
            );
            self.scope().userProfile.selectedDataItems.forEach(item => {
                if (!item.text)
                    item.text = item.displayName || item.name;
                if (!item.order)
                    item.order = 1;
                item.text = self.$gettext(item.text);
            });

            //Add actions and details button
            if (self.scope().needActionAndDetails) {
                if (self.scope().actions.children.some(item => { return item.getGrant() }) && !self.scope().userProfile.selectedDataItems.some(item => { return item.name === 'actions'}))
                    self.scope().userProfile.selectedDataItems.push({name: 'actions', text: this.$gettext('Actions'), order: 2});
                if (self.scope().detailsGroup.children.some(item => { return item.getGrant() }) && !self.scope().userProfile.selectedDataItems.some(item => { return item.name === 'details' }))
                    self.scope().userProfile.selectedDataItems.push({name: 'details', text: this.$gettext('Details'), order: 3});
            }
            if (!self.scope().needActionAndDetails && (self.scope().userProfile.selectedDataItems.some(item => { return item.name === 'actions' }) || self.scope().userProfile.selectedDataItems.some(item => { return item.name === 'details' }))) {
                self.scope().userProfile.selectedDataItems = self.scope().userProfile.selectedDataItems.filter(item => { return item.name !== 'actions' && item.name !== 'details' })
            }
            self.scope().userProfile.selectedDataItems.sort((a, b) => { return a.order - b.order });
            //If actions and details are only two items, remove it
            if (self.scope().needActionAndDetails) {
                if (self.scope().userProfile.selectedDataItems.length === 2 && self.scope().userProfile.selectedDataItems.some(item => { return item.name === 'actions'}) && self.scope().userProfile.selectedDataItems.some(item => { return item.name === 'details' })) {
                    self.scope().userProfile.selectedDataItems.splice(0, 2);
                    return []
                }
                if (self.scope().userProfile.selectedDataItems.length === 1 && self.scope().userProfile.selectedDataItems.some(item => { return item.name === 'actions' || item.name === 'details' })) {
                    self.scope().userProfile.selectedDataItems.splice(0, 1);
                    return [];
                }
            }

            let selectedDataNames = self.scope().userProfile.selectedDataItems.map(selectedData => {
                let name = selectedData.name;
                // if(selectedData.root)
                //     name = selectedData.root + "." +  name;
                return {
                    name: selectedData.name,
                    fullName: selectedData.root + "." +  selectedData.name
                };
            });
            const newDataTableArray = [];
            self.filterData.forEach((dataTableItem, index) => {
                let newObj = {};
                selectedDataNames.forEach(item => {
                    if (dataTableItem.hasOwnProperty(item.fullName)) {
                        newObj[item.fullName] = dataTableItem[item.fullName];
                    } else if (dataTableItem.hasOwnProperty(item.name)) {
                        newObj[item.name] = dataTableItem[item.name];
                    } else {
                        newObj[item.name] = null;
                    }
                });
                newObj.itemKey = dataTableItem.id;
                if (self.scope().needActionAndDetails) {
                    if (self.scope().userProfile.selectedDataItems.some(item => { return  item.name === 'actions' }))
                        newObj.actions = self.actions;
                    if (self.scope().userProfile.selectedDataItems.some(item => { return item.name === 'details' }))
                        newObj.details = self.scope().detailsGroup;
                }
                newDataTableArray.push(newObj);
            });
            //newDataTableArray.sortOnProperty(self.pagination.sortBy,self.pagination.descending);
            return newDataTableArray;
        },
        lbMinView() {
            return this.$gettext("User profiles that can see this profile")
        },
        lbMinEdit() {
            return this.$gettext("User profiles that can edit this profile")
        },
        exportHeaders() {
            let self = this;
            return self.scope().userProfile.selectedDataItems.map(selectedData => { return {
                label: selectedData.text,
                field: selectedData.name
            }});
        },
        showButtonNewItem() {
            return Array.isUseful(this.newItemButtonC.itemForms);
        },
        pages () {
            if (this.pagination.rowsPerPage == null)
                return 0
            return Math.ceil(this.visibleData.length / this.pagination.rowsPerPage)
        },
        rowFrom(){
            return (this.pagination.page-1) * this.pagination.rowsPerPage +1
        },
        rowTo() {
            let rowTo = ((this.pagination.page - 1) * this.pagination.rowsPerPage) + this.pagination.rowsPerPage
            if (this.pagination.rowsPerPage === -1 || rowTo > this.visibleData.length)
                rowTo = this.visibleData.length
            return rowTo
        },
        newItemButtonC() {
            if(!this.scope().newItemButton) {
                return {
                    type: -1,
                    backPath: "",
                    itemForms: [],
                    newItemButtonName: "",
                    selectedForm: ""
                }
            } else return this.scope().newItemButton;
        }
    },
    watch: {
        scopeIndex() {
            this.getDataDefinitions(false);
        },
        userProfile: {
            handler: function (value) {
                if (this.scope().profileChanged) {
                    this.scope().unsavedChanges = false
                    this.profileChanged = false
                    return
                }
                if (this.scope().showVariablePicker && this.scope().profileChanged && Array.isUseful(value.selectedFilterItems) || Array.isUseful(value.selectedDataItems)) {
                    this.scope().unsavedChanges = true
                }
            },
            deep: true,
        },
        alwaysTimeFilter: {
            handler: function () {
                if (this.scope().alwaysTimeFilter)
                    this.scope().showTimeFilter = true;
            },
        }
    },
    methods: {
        scope() {
            let returning = this;
            for(;;) {
                if (!returning.$parent)
                    return returning;
                else {
                    if(!Object.isDefined(returning.$parent.GENERIC_TABLE))
                        return returning;
                    else
                        returning = returning.$parent;
                }
            }
        },
        handleFunctionCall(...args) {
            let local = [...args][0];
            let function_name = [...args][1];
            args.shift();
            args.shift();
            if (local)
                this[function_name](...args);
            else
                this.scope()[function_name](...args);
        },
        itemDetails(itemId) {
            const item = this.scope().listOfItems.find(item => item.id === itemId);
            this.scope().itemDetails(item);
        },
        editItem(itemId) {
            const item = this.scope().listOfItems.find(item => item.id === itemId);
            this.scope().editItem(item)
        },
        async deleteItem(itemId) {
            const item = this.scope().listOfItems.find(item => item.id === itemId);
            let self = this;
            self.$root.showDialogBox(this.$gettext("Are you sure you want to delete item: {0}?").format(item.id), null, "Yes", function () {
                self.scope().deleteItem(item)
                    .then((result) => {
                        if (result) {
                            self.$root.showInfoNotification(self.$gettext("Successfully deleted item: {0}").format(item.id), true);
                            self.scope().listOfItemsFlatten = self.scope().listOfItemsFlatten.filter(item => item.id !== itemId);
                        }
                    })
                    .catch(err => {
                        self.$root.showErrorNotification(err, true, false);
                    })
            }, "No", null);
        },
        closeDialog() {
            if (this.scope().unsavedChanges)
                this.$root.showDialogBox(this.$gettext("You have some unsaved changes. Are you sure you want to leave the page?"), null,
                    "Yes", () => {
                        this.scope().showVariablePicker = false;
                        if (!this.scope().profileName) {
                            this.scope().userProfile.selectedDataItems = this.scope().defaultVisualizationProfile;
                            this.newProfile = true;
                        }},
                    "CANCEL", () => { this.scope().showVariablePicker = true;});
            else {
                this.scope().showVariablePicker = false
                if (!Array.isUseful(this.scope().userProfile.selectedDataItems)){
                    this.scope().userProfile.selectedDataItems = this.scope().defaultVisualizationProfile;
                }
            }

        },
        closeFilterDialog() {
            this.showFiltersPicker = false;
            this.scope().unsavedChanges = false
            // this.profileChanged = true
        },
        resetFilters() {
            let self = this;
            self.scope().userProfile.selectedFilterItems.forEach(filter => {
                if (filter.value)
                    filter.value = "";
            })
            self.showFiltersPicker = false;
        },
        getDataDefinitions(forceReload = false) {
            let self = this;
            self.$datalayer.dataDefinitionsForIndex(self.scope().scopeIndex, forceReload, true)
                .then(mapping => {
                    this.$utils.forEachItemInObject(mapping, function (item) {
                        if (item.name && typeof item.name === 'string' && item.name.endsWith(".keyword")) {
                            item.name = item.name.replace(".keyword", "");
                        }
                    });
                    self.scope().mapping = mapping;
                    if (forceReload)
                        self.$root.showInfoNotification(self.$gettext("Data definitions refreshed"), true);
                })
                .catch(t => {
                    debugger
                    console.error(t);
                    self.$root.showErrorNotification(self.$gettext("Error in retrieving data definitions from DB."), true);
                });
        },
        loadDataForTimeWindow(force) {
            let delay = 2000;
            if(force)
                delay = 50;

            let self = this;
            if(this.timeRefreshTimeout) {
                clearTimeout(this.timeRefreshTimeout);
                this.timeRefreshTimeout = null;
            }
            this.timeRefreshTimeout = setTimeout(() => {
                self.loadData(false, false);
                self.loadAll = false;
                }, delay);
        },
        async loadData(backGround = false, profilesLoad = true) {
            let self = this;
            if (!backGround)
                this.$root.setLoading(true, this.$gettext("Loading records"));
            setTimeout(async () => {
                try {
                    self.scope().listOfItems = [];
                    self.scope().listOfItemsFlatten = [];
                    self.formsMetadata = await self.$dynamicElements.getMetadataList("forms", true, false, false, false, true);
                    self.scope().getListOfItems();
                    await self.scope().waitItems;
                    if(self.scope().listOfItems)
                        self.scope().listOfItems.forEach(item => {
                            let flattenObj = self.convertToFlat(item);
                            self.scope().listOfItemsFlatten.push({...flattenObj});
                        });
                    //Prevent loading profiles again when time window filter changes
                    if (profilesLoad)
                        self.loadProfiles();

                    //Used from report ms to export data, timeout used to await profilesLoading filtered
                    if (this.$route.params.requiredExport) {
                        setTimeout(() => {
                            this.exportReport(this.$route.params.format, true);
                        }, 2000)
                    }
                } finally {
                    self.$root.setLoading(false);
                }
            }, 2000);
        },
        saveProfile() {
            let self = this;
            self.$settings.save(self.scope().userProfile, self.scope().scopeType, self.scope().profileName)
                .then((result) => {

                    if (result === 'created' || result === 'updated') {
                        self.$root.showInfoNotification(this.$gettext("Successfully {createdOrUpdated} profile").format({createdOrUpdated: result}), true);
                        self.scope().showVariablePicker = false;
                        if (result === 'created') {
                            let profileData = Object.assign({}, self.scope().userProfile);
                            let profileFull = {Name: self.scope().profileName, Type: self.scope().scopeType, profileData: profileData};
                            self.scope().profilesFull.push(profileFull);
                            self.$audits.save(self.$root.userName, self.$audits.items().tableVisualizationProfileCreated, "", "", self.scope().auditType + " /" + self.scope().profileName)
                                .catch(err => {
                                    debugger
                                    self.root.showErrorNotification(this.$gettext("An error occurred while saving audits to DB"), true);
                                });
                        } else {
                            //TODO clarify this line, it seems useless
                            let profileData = Object.assign({}, self.scope().userProfile);
                            self.scope().profilesFull.find(profile => profile.Name === self.scope().profileName).profileData = profileData;
                            self.$audits.save(self.$root.userName, self.$audits.items().tableVisualizationProfileUpdated, "", "", self.scope().auditType + " /" + self.scope().profileName)
                                .catch(err => {
                                    debugger
                                    self.$root.showErrorNotification(this.$gettext("An error occurred while saving audits to DB"), true);
                                });
                        }
                    }
                })
                .catch(err => {
                    self.$root.showErrorNotification("An error occurred while saving settings to DB", true);
                    self.showVariablePicker = false;
                });
        },
        deleteProfile() {
            let self = this;
            self.$root.showDialogBox(this.$gettext("Are you sure you want to delete profile {0}?").format(self.profileName), "", "Yes",
                () => {
                    self.$settings.delete(self.scope().scopeType, self.scope().profileName)
                        .then((result) => {
                            if (result === 'deleted') {
                                self.$root.showInfoNotification("Successfully deleted profile", true);
                                self.scope().profilesFull = self.scope().profilesFull.filter(item => item.Name !== self.scope().profileName);
                                let deleteProfileName = self.scope().profileName;
                                self.scope().profileName = "";
                                self.scope().userProfile.selectedDataItems = [];
                                self.scope().userProfile.selectedFilterItems = [];
                                self.scope().userProfile.reportTemplate = null,
                                self.scope().userProfile.reportTemplateName = '',
                                self.scope().userProfile.importedReportTemplate = false,
                                self.scope().userProfile.checkAsDefault = false;
                                self.matchDataItemsWithDefinitions(true);
                                if (self.scope().profilesFull.length === 0) {
                                    self.scope().userProfile.selectedDataItems = self.scope().defaultVisualizationProfile;
                                    self.scope().newProfile = true;
                                }
                                self.$audits.save(self.$root.userName, self.$audits.items().tableVisualizationProfileDeleted, "", "", self.scope().auditType + " /" + deleteProfileName)
                                    .catch(err => {
                                        debugger
                                        self.root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                                    });
                            }
                        })
                        .catch(err => {
                            self.$root.showErrorNotification("An error occurred while deleting settings from DB", true);
                        });
                }, "No", null);
        },
        loadProfiles() {
            let self = this;
            self.$settings.list(self.scope().scopeType)
                .then(async result => {
                    if (Array.isUseful(result)) {
                        let promise = new Promise((resolve, reject) => {
                            result.forEach(profile => {
                                self.$settings.load(profile.Type, profile.Name)
                                    .then(resultDetails => {
                                        let profileFull = {Name: profile.Name, Type: profile.Type, profileData: resultDetails};
                                        self.scope().profilesFull.push(profileFull);
                                        if (self.scope().profilesFull.length === result.length) {
                                            resolve(self.scope().profilesFull)
                                        }
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        self.$root.showErrorNotification("An error occurred while loading settings from DB", true);
                                        reject([]);
                                    })
                            });
                        });
                        await promise;

                        //Used from report ms to export data,
                        if (this.$route.params.requiredExport) {
                            if (this.$route.params.itemName === 'Default view') {
                                self.userProfile.selectedDataItems = self.scope().defaultVisualizationProfile;

                            } else {
                                let profile = self.visibleProfiles.find(profile => profile.Name === this.$route.params.itemName);
                                if (profile) {
                                    self.userProfile.selectedDataItems = profile.profileData.selectedDataItems;
                                    self.userProfile.selectedFilterItems = profile.profileData.selectedFilterItems;
                                    self.profileName = profile.Name;
                                }
                            }
                            return
                        }
                        let defaultFound = false;
                        for (let profile of self.visibleProfiles) {
                            if (profile.profileData.checkAsDefault) {
                                self.userProfile.selectedDataItems = profile.profileData.selectedDataItems;
                                self.userProfile.selectedFilterItems = profile.profileData.selectedFilterItems;
                                self.userProfile.checkAsDefault = profile.profileData.checkAsDefault;
                                self.userProfile.showLevel = profile.profileData.showLevel;
                                self.userProfile.editLevel = profile.profileData.editLevel;
                                self.userProfile.reportTemplate = profile.profileData.reportTemplate;
                                self.userProfile.reportTemplateName = profile.profileData.reportTemplateName;
                                self.userProfile.importedReportTemplate = profile.profileData.importedReportTemplate;
                                self.scope().visualizationTweaks[0].value = profile.profileData.importedReportTemplate ? profile.profileData.reportTemplateName : self.scope().visualizationTweaks[0].default()
                                self.profileName = profile.Name;
                                self.matchDataItemsWithDefinitions(false);
                                defaultFound = true;
                                break;
                            }
                        }
                        if (!defaultFound) {
                            self.scope().userProfile.selectedDataItems = self.scope().defaultVisualizationProfile;
                            self.scope().newProfile = true;
                        }
                    } else {
                        self.scope().userProfile.selectedDataItems = self.scope().defaultVisualizationProfile;
                        self.scope().newProfile = true;
                    }

                })
                .catch(err => {
                    console.log(err);
                    self.$root.showErrorNotification("An error occurred while loading settings from DB", true);
                });
        },
        getUserProfile(event) {
            let self = this;
            self.scope().profileName = event.Name;

            let selectedProfile = self.scope().profilesFull.find(profile => profile.Name === event.Name);
            self.scope().userProfile.selectedDataItems = selectedProfile.profileData.selectedDataItems;
            self.scope().userProfile.selectedFilterItems = selectedProfile.profileData.selectedFilterItems;
            self.scope().userProfile.checkAsDefault = selectedProfile.profileData.checkAsDefault;
            self.scope().userProfile.showLevel = selectedProfile.profileData.showLevel;
            self.scope().userProfile.editLevel = selectedProfile.profileData.editLevel;
            self.scope().userProfile.reportTemplate = selectedProfile.profileData.reportTemplate;
            self.scope().userProfile.reportTemplateName = selectedProfile.profileData.reportTemplateName;
            self.scope().userProfile.importedReportTemplate = selectedProfile.profileData.importedReportTemplate;
            self.scope().visualizationTweaks[0].value = self.scope().userProfile.importedReportTemplate ? self.scope().userProfile.reportTemplateName : self.scope().visualizationTweaks[0].default()
            self.matchDataItemsWithDefinitions(false);
            this.scope().unsavedChanges = false
            this.scope().profileChanged = true
        },
        matchDataItemsWithDefinitions(reset) {
            let self = this;
            self.scope().userProfile.selectedDataItems.forEach(function (item) { Vue.set(item, "matched", false) });
            self.scope().userProfile.selectedFilterItems.forEach(function (item) { Vue.set(item, "matched", false) });
            //Try to merge selected items with the full mapping list populating the tree view
            let matchesCount = 0;   //Used to short out matching loop when all selectedItems were matched
            self.$utils.forEachItemInObject(self.scope().mapping, function (item) {
                //we have a data item
                try {
                    if (item && (Object.areDefined(item.index, item.root, item.name) || (Object.areDefined(item.index, item.name) && item.isRoot))) {
                        Vue.set(item, "selectedForVisualization", false);
                        Vue.set(item, "selectedForFiltering", false);

                        //Clear all items checked state unless they are listed in selected items
                        //Process items until we matched selected items count then just keep setting all other to unchecked to save time
                        if (!reset) {
                            if (matchesCount < (self.scope().userProfile.selectedDataItems.length + self.scope().userProfile.selectedFilterItems.length)) {
                                if (self.scope().userProfile.selectedDataItems) {
                                    let itemFound = self.scope().userProfile.selectedDataItems.find(function (element) {
                                        return ( (element.index === item.index || element.index + "*" === item.index || element.index === item.index + "*") &&
                                            ((element.root === item.root && element.name === item.name) ||
                                                (element.index === item.index && element.name === item.name && element.isRoot && item.isRoot)));
                                    });
                                    if(itemFound) {
                                        Vue.set(item, "selectedForVisualization", true);
                                        Vue.set(itemFound, "matched", true);
                                    }
                                }
                                if (self.scope().userProfile.selectedFilterItems) {
                                    let itemFound = self.scope().userProfile.selectedFilterItems.find(function (element) {
                                        return ((element.index === item.index || element.index + "*" === item.index || element.index === item.index + "*") &&
                                            element.root === item.root && element.name === item.name);
                                    });
                                    if(itemFound) {
                                        Vue.set(item, "selectedForFiltering", true);
                                        Vue.set(itemFound, "matched", true);
                                    }
                                }

                                matchesCount += item.selectedForFiltering ? 1 : 0;
                                matchesCount += item.selectedForVisualization ? 1 : 0;

                            }
                        }
                    }
                } catch (ex) {
                    debugger
                }
            });
        },
        newProfileBtn() {
            let self = this;
            self.scope().newProfile = !self.scope().newProfile;
            self.scope().profileName = "";
            self.scope().userProfile.selectedDataItems = [];
            self.scope().userProfile.selectedFilterItems = [];
            self.scope().userProfile.showLevel = [];
            self.scope().userProfile.editLevel = [];
            self.scope().userProfile.reportTemplate = null,
            self.scope().userProfile.reportTemplateName = '',
            self.scope().userProfile.importedReportTemplate = false
            self.scope().visualizationTweaks[0].value = self.scope().visualizationTweaks[0].default()
            self.scope().userProfile.checkAsDefault = false;
            self.matchDataItemsWithDefinitions(true);
            this.scope().unsavedChanges = false

        },
        changeSort (column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending
            } else {
                this.pagination.sortBy = column
                this.pagination.descending = false
            }
        },
        changeProfile() {
            if (this.scope().unsavedChanges)
                this.$root.showDialogBox(this.$gettext("You have some unsaved changes. Are you sure you want to leave the page?"), null, "Yes", this.newProfileBtn, "CANCEL", null);
            else {
                this.newProfileBtn()
            }
        },
        customizeViewClick() {
            if (!Array.isUseful(this.scope().mapping))
                this.getDataDefinitions(true);

            this.scope().showVariablePicker = true;
            this.scope().unsavedChanges = false;
            if (!this.scope().profileName) {
                this.scope().userProfile.selectedDataItems = [];
                this.scope().userProfile.selectedFilterItems = [];
                this.matchDataItemsWithDefinitions(true);
            }
        },
        nameChanging() {
            let self = this;
            if (self.scope().profilesFull.some(profile => { return  profile.Name.toLowerCase() === self.scope().profileName.toLowerCase() })) {
                self.$root.showErrorNotification(self.$gettext("Visualization profile with name {0} already exists").format(self.scope().profileName), true);
                self.scope().disableSave = true;
            } else if (self.scope().profileName.toLowerCase() === 'default view') {
                self.$root.showErrorNotification(self.$gettext("Forbidden profile name: Default view"), true);
                self.scope().disableSave = true;
            } else if (self.scope().disableSave === true) {
                self.scope().disableSave = false;
            }
        },
        convertToFlat(obj, root = "") {
            const flattened = {};
            Object.keys(obj).forEach((key) => {
                const value = obj[key];
                if (typeof value === 'object' && value !== null && !Array.isArray(value))
                    Object.assign(flattened, this.convertToFlat(value/*, (root ? root + "." + key : key)*/));
                else
                    flattened[/*(root ? root + "." + key : key)*/key] = value;
            });
            return flattened;
        },
        async loadForms() {
            let self = this;
            if(!this.scope().newItemButton) {
                this.scope().newItemButton = {
                    type: -1,
                    backPath: "",
                    itemForms: [],
                    newItemButtonName: "",
                    selectedForm: ""
                }
                //TODO move this stuff to child pages
                switch (self.scope().auditType) {
                    case "Asset register":
                        self.newItemButton.backPath = "assetsRegister";
                        self.newItemButton.type = self.$dynamicElements.Types.FormAsset;
                        self.newItemButton.newItemButtonName = this.$gettext("New Asset");
                        break;
                    case "Maintenance intervention":
                        self.newItemButton.backPath = "maintenanceRegister";
                        self.newItemButton.type = self.$dynamicElements.Types.FormMaintenance;
                        self.newItemButton.newItemButtonName = this.$gettext("New Intervention");
                        break;
                    default:
                        return;
                }
            }
            self.scope().newItemButton.itemForms.clear();

            if (self.scope().newItemButton.type !== -1) {
                this.$dynamicElements.LoadItems("forms")
                    .then(result => {
                        if(Array.isUseful(result)) {
                            for (let form of result) {
                                if (form.properties.type === self.scope().newItemButton.type && form.properties.deployStatus > 0)
                                    self.scope().newItemButton.itemForms.push(form.properties.name);
                            }
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        self.$root.showErrorNotification(this.$gettext("Error in retrieving saved items from DB."), true);
                    })
            }
        },
        async newItem() {
            let self = this;
            if(self.scope().newItemButton.itemForms.length > 1) {
                let self = this;
                self.scope().showNewItemDialog = true;
            } else {
                self.$router.push({ name: 'forms', params: { routeId: self.scope().newItemButton.itemForms[0], backPath: { name: self.scope().newItemButton.backPath }}});
            }
        },
        openSelectedForm() {
            let self = this;
            self.$router.push({ name: 'forms', params: { routeId: self.scope().newItemButton.selectedForm, backPath: { name: self.scope().newItemButton.backPath }}});
            self.scope().showNewItemDialog = false;
        },
        importExcelCallback(file) {
            this.scope().visualizationTweaks[0].value = file.name;
            const reader = new FileReader();

            let self = this;
            reader.onload = () => {
                let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                if ((encoded.length % 4) > 0) {
                    encoded += '='.repeat(4 - (encoded.length % 4));
                }
                self.scope().userProfile.reportTemplate = encoded;
                self.scope().userProfile.reportTemplateName = file.name;
                self.scope().userProfile.importedReportTemplate = true;
                self.scope().newProfile = true;
            };
            reader.readAsDataURL(file);
        },
        deleteTemplateCallback() {
            this.scope().userProfile.reportTemplateName = null;
            this.scope().userProfile.reportTemplate = null;
            this.scope().userProfile.importedReportTemplate = false;
            this.scope().visualizationTweaks[0].value = this.scope().visualizationTweaks[0].default();
        },
        exportReport(format, requiredExport = false) {
            let visibleData = [...this.visibleData];
            visibleData.forEach(item => {
                delete item.itemKey;
                //Removed actions and details from exporting data
                delete item.actions;
                delete item.details;
            });
            let headers = [...this.scope().userProfile.selectedDataItems];
            //Removed actions and details from exporting headers
            headers = headers.filter(header => header.name !== 'actions' && header.name !== 'details');
            let data = {
                data: visibleData,
                dataFormat: 'table',
                headers: headers.map(item => {
                    return {
                        order: item.order,
                        text: item.text,
                        value: item.root? item.root + '.' + item.name: item.name
                    }
                })
            };

            if (!Array.isUseful(data.data) || !Array.isUseful(data.headers))
                return

            let self = this;
            let title = self.scope().profileName ? self.scope().scopeIndex + ' - ' + self.scope().profileName : self.scope().scopeIndex + 'Report';
            self.scope().exporting = true;
            let request = {
                name: '',
                title: title,
                type: format,
                data: [data],
                from: null,
                to: null
            };
            if (requiredExport && this.$route.params.exportDataHandler) {
                let response = {
                    data: {
                        name: '',
                        title: title,
                        type: format,
                        data: [data],
                        from: null,
                        to: null
                    },
                    template: self.scope().userProfile.reportTemplate,
                };
                //Return requested export data to report ms
                this.$route.params.exportDataHandler(response, this.$route.params.messageId);
            } else {
                this.$dynamicElements.ExportVisualization(format, request, self.scope().userProfile.reportTemplate)
                    .then(r => {
                        const link = document.createElement('a');
                        link.href = r;
                        link.setAttribute('download', title + "." + format);
                        document.body.appendChild(link);
                        link.click();
                    })
            }
        },
        detailsButtonClick(props) {
            if (this.scope().detailsGroup.children.length === 1) {
                let detailsButton = this.scope().detailsGroup.children[0];
                this.handleFunctionCall(detailsButton.local, detailsButton.handler, props.item.itemKey);
                return
            }
            props.expanded = !props.expanded;
            this.detailsExpanded[props.index] = props.expanded;
            this.actionsExpanded[props.index] = false
        },
        actionsButtonClick(props) {
            if (this.scope().actions.children.length === 1) {
                let actionButton = this.scope().actions.children[0];
                this.handleFunctionCall(actionButton.local, actionButton.handler, props.item.itemKey);
                return
            }
            props.expanded = !props.expanded;
            this.actionsExpanded[props.index] = props.expanded;
            this.detailsExpanded[props.index] = false
        }

    },
}
</script>

<style scoped>
    .v-btn:before {
        opacity: 0 !important;
    }

    .v-ripple__container {
        opacity: 0 !important;
    }
</style>
