<template>
    <v-treeview :items="filteredMapping" item-text="name" item-key="key" ><!--:search="search" :filter="filter">-->
        <template v-slot:prepend="{ item }">
            <template v-if="rootSelect || fieldSelect">
                <av-button v-if="!multiple && (fieldSelect && !item.isRoot || rootSelect && item.isRoot)"
                           :toolTip="$gettext('Select')" @click="addRemoveItem(item, 'select')"
                           buttonIcon="fa-check" iconColor="info" size="medium" fab small/>
                <av-icon v-if="multiple && (fieldSelect && !item.isRoot || rootSelect && item.isRoot)"
                         @click.native="addRemoveItem(item, 'checkbox')" style="margin-left: 2px">
                    {{ isSelected(item) ? 'far fa-check-square' : 'far fa-square' }}
                </av-icon>
                <!--          <v-checkbox v-if="multiple && (fieldSelect && !item.isRoot || rootSelect && item.isRoot)" v-model="multipleSelection" label="" :value="item.key" class="check" @click.native="addRemoveItem(item, 'checkbox')" ></v-checkbox>-->
            </template>
            <template v-else>
                <template v-if="item.isRoot && !multiple">
                    <av-icon @click="addRemoveAllChildren(item)" class="ml-3">
                        {{ isSelectedRoot(item) ? 'far fa-check-square' : 'far fa-square' }}</av-icon>
                </template>

                <template v-if="isFilterable(item)">
                    <av-icon size="18px">fas fa-filter</av-icon>
                    <av-icon @click="addRemoveItem(item, 'filter')" style="margin-left: 2px">
                        {{ item.selectedForFiltering ? 'far fa-check-square' : 'far fa-square' }}
                    </av-icon>
                </template>
                <div v-else-if="isSelectable(item) && !documentSelect" style="width: 42px"></div>
                <template v-if="isSelectable(item)">
                    <av-icon v-if="!documentSelect" size="20px" style="margin-left: 5px">fas fa-search</av-icon>
                    <av-icon @click="addRemoveItem(item, 'show')" style="margin-left: 2px">
                        {{ item.selectedForVisualization ? 'far fa-check-square' : 'far fa-square' }}
                    </av-icon>
                </template>
            </template>
        </template>
        <template v-slot:label="{ item }">
            <label class="v-treeview-node__label" v-if="item.displayName">
                {{ (item.displayName) }}
            </label>
            <label class="v-treeview-node__label" v-else>
                {{ (item.name) }}
            </label>
        </template>
        <template v-slot:append="{ item }">
            <label class="v-treeview-node__label" v-if="item.type">
                {{ printItemType(item) }}
            </label>
            <label class="v-treeview-node__label" v-if="item.additionalInfo">
                {{"({0})".format(item.additionalInfo)}}
            </label>
        </template>
    </v-treeview>
</template>

<script>

    import Vue from 'vue'

    export default {
        name: "DataMappingTreeView",
        props: {
            mapping: {
                type: Array,
                default: () => []
            },
            dataItems: {
                type: Array,
                default: () => []
            },
            filterItems: {
                type: Array,
                default: () => []
            },
            documentSelect: {
                type: Boolean,
                default: false
            },
            fieldSelect: {
                type: Boolean,
                default: false
            },
            rootSelect: {
                type: Boolean,
                default: false
            },
            search: {
                type: String,
                default: ""
            },
            disableSearch: {
                type: Boolean,
                default: () => {
                    return false;
                }
            },
            multiple: {
                type: Boolean,
                default: () => {
                    return false
                }
            },
            excludeItem: {
                type: Object,
                required: false,
                default: () => {
                    return undefined
                }
            },
            variables: {
                required: false,
                default: () => {
                    return undefined
                }
            }
        },
        data: () => {
            return {
                multipleSelection: [],
                multiSelectionItems: []
            }
        },
        created() {
            if (this.excludeItem) {
                this.mapping.removeItemRecursive(this.excludeItem, "children")
            }

            function getParent(array, key, parent) {
                return array.some(o =>
                    o.key === key || o.children
                    && (parent = getParent(o.children, key, o)) !== null) ? parent : null;
            }

            if (this.variables && this.multiple) {
                let self = this
                this.variables.forEach(ms => {
                    if (self.fieldSelect) {
                        this.addRemoveItem(ms, "checkbox")
                    }
                    if (self.rootSelect) {
                        let parent = getParent(self.mapping, ms.key)
                        if (parent) {
                            self.addRemoveItem(parent, "checkbox")
                        }
                    }
                })
            }
        },
        computed: {
            isSelected() {
                return item => {
                    return this.multipleSelection.includes(item.key)
                }
            },
            filter() {
                return this.disableSearch
                    ? (item, search, textKey) => item.label === search || item.name === search
                    : undefined
            },
            filteredMapping() {
                return this.$datalayer.searchDataItems(this.mapping, this.search, this.disableSearch);
            },
            isSelectedRoot(){
                let self=this;
                return item =>{
                    return self.isSelectedRootItem(item)
                }
            }
        },
        methods: {
            isSelectable(item) {
                return (!item.isRoot && this.$defines.isVisualizable(item)) || this.isRootSelectable(item);
            },
            isFilterable(item) {
                return (!item.isRoot && this.$defines.isFilterable(item) && !this.documentSelect);
            },
            isRootSelectable(item) {
                if (item.isRoot) {
                    let wellKnownRoot = this.$defines.getWellKnownItem(item);
                    if (Object.isUseful(wellKnownRoot)) {
                        if ((this.documentSelect && wellKnownRoot.fullNodeComparable) || wellKnownRoot.fullNodeQuerable) {
                            if (Array.isUseful(item.children))
                                item.children = [];
                            return true;
                        }
                    }
                }
                return false;
            },
            recursiveCheckboxSelection(items, deselect) {
                let self = this;
                items.forEach(i => {
                    if ((self.rootSelect && i.isRoot) || self.fieldSelect && !i.isRoot)
                        if (deselect) {
                            self.multipleSelection.removeItem(i.key)
                        } else {
                            if (!self.multipleSelection.includes(i.key)) {
                                self.multipleSelection.push(i.key)
                            }
                        }
                    if (Array.isUseful(i.children)) {
                        self.recursiveCheckboxSelection(i.children, deselect)
                    }
                })
            },
            //When a data item tree node is checked or unchecked (either for visualization or filtering) we get here
            addRemoveItem(item, type) {
                if (type === "select") {
                    this.$emit("selectedItem", item);
                    return
                }

                if (type === 'checkbox') {
                    if (this.rootSelect) {
                        let deselect = this.multipleSelection.includes(item.key);
                        if (deselect) {
                            this.multipleSelection.removeItem(item.key)
                        } else {
                            this.multipleSelection.push(item.key)
                        }
                        if (Array.isUseful(item.children)) {
                            this.recursiveCheckboxSelection(item.children, deselect)
                        }
                        let ms = this.calcMultiSelection();
                        this.$emit("selectedItems", ms);
                        return
                    } else {
                        let deselect = this.multipleSelection.includes(item.key);
                        if (deselect) {
                            this.multipleSelection.removeItem(item.key);
                        } else {
                            this.multipleSelection.push(item.key);
                        }
                        let ms = this.calcMultiSelection();
                        this.$emit("selectedItems", ms);
                        return
                    }
                }

                //Add to data and filter items all the stuff needed to work reactivelly
                if (type === "show" && !Object.isDefined(item.selectedForVisualization)) {
                    Vue.set(item, "selectedForVisualization", false);   //Controls the checked state of the select for visualization checkBox
                }

                if (type === "filter" && !Object.isDefined(item.selectedForFiltering)) {
                    Vue.set(item, "selectedForFiltering", false);   //Controls the checked state of the select for filtering checkBox
                }

                if (!Object.isDefined(item.matched)) {
                    Vue.set(item, "matched", true);   //Controls the valid state of selected var
                }

                if (type === "show" && !Object.isDefined(item.representations)) {
                    Vue.set(item, "representations", []);   //Will hold the visualization parameters for this item
                }

                if (type === "filter" && !Object.isDefined(item.filters)) {
                    Vue.set(item, "filters", []);   //Will hold the filtering parameters for this item
                }

                if (type === "show")
                    item.selectedForVisualization = !item.selectedForVisualization; //Check if unchecked and vice versa

                if (type === "filter")
                    item.selectedForFiltering = !item.selectedForFiltering; //Check if unchecked and vice versa

                //Add or remove item to selected group (visualizable or filterable), this will show item in
                //data representation configurator and start data flow in data grid.
                //By pushing items to groups they will also reach current element (if any one loaded) that will save them to DB within its descriptor.
                if (item.selectedForFiltering && type === "filter")
                    this.filterItems.push(item);
                else if (item.selectedForVisualization && type === "show")
                    this.dataItems.push(item);
                else {  //This else clause removes items from groups (and current element) when they are unchecked in tree view
                    if (type === "show") {
                        for (let dataItemIndex = 0; dataItemIndex < this.dataItems.length; dataItemIndex++) {
                            if ((this.dataItems[dataItemIndex].index === item.index && this.dataItems[dataItemIndex].root === item.root && this.dataItems[dataItemIndex].name === item.name)) {
                                this.dataItems.splice(dataItemIndex, 1);
                                break;
                            }
                        }
                    }
                    if (type === "filter") {
                        for (let filterItemIndex = 0; filterItemIndex < this.filterItems.length; filterItemIndex++) {
                            if ((this.filterItems[filterItemIndex].index === item.index && this.filterItems[filterItemIndex].root === item.root && this.filterItems[filterItemIndex].name === item.name)) {
                                this.filterItems.splice(filterItemIndex, 1);
                                break;
                            }
                        }
                    }
                }
            },
            addRemoveAllChildren(item){
                let self=this
                let selectedRoot=self.isSelectedRootItem(item)
                item.children.forEach(c=>{
                    if (Array.isUseful(c.children)){
                        if(!self.isSelectedRootItem(c)){
                            self.addRemoveAllChildren(c)
                        }
                    }else{
                        if((!selectedRoot && !c.selectedForVisualization) ||
                            (selectedRoot && c.selectedForVisualization) ){
                            self.addRemoveItem(c,"show")
                        }
                    }
                })
            },
            isSelectedRootItem(item){
                let self=this;
                let map = item.children.map(c=>{
                    return c.isRoot? self.isSelectedRoot(c) : c.selectedForVisualization
                })
                return map.filter(m=>{return !m}).length === 0
            },
            calcMultiSelection() {
                let multiSelectionItems = [];
                let self=this
                let findSelection = (item) => {
                    for (let i = 0; i < item.children.length; i++) {
                        let it = item.children[i];
                        let comparableItem = it;
                        if(this.rootSelect)
                            comparableItem = item;
                        if (!it.isRoot && ((it.name === this.search && this.disableSearch) || (it.name.startsWith(this.search) && !this.disableSearch)) &&
                            self.multipleSelection.includes(comparableItem.key)) {
                            if (!it.label || it.label===it.name)
                                it.label = it.name;
                            multiSelectionItems.push(it)
                        } else if (it.isRoot && Array.isUseful(it.children)) {
                            findSelection(it)
                        }
                    }
                }
                this.mapping.forEach(m => {
                    findSelection(m)
                })
                return multiSelectionItems;
            },
            //Formats the data type string near data tree view entries
            printItemType(item) {
                return " : " + this.$defines.getAvionicsDataType(item.type).show;
            },
        },

    }
</script>

<style scoped>
    .check {
        max-width: 20px;
        position: relative;
        top: 5px;
    }
</style>