<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer"  class="page-container" grid-list-md  text-xs-center pa-3 ma-0 style="max-width: 100%;">
        <v-card  class="ma-0 av-card" elevation="0">
            <v-card-text class="av-card-content">
                <ConfigurationsEditor :descriptor="lineSettings"  @value-Changed="valueChanged" :filters="$license.modulesList()"></ConfigurationsEditor>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import ConfigurationsEditor from "@/components/utilities/ConfigurationsEditor";
import DateTime from "@/api/datetimeutils"

export default {
    name: "PlantSettings",
    components: {
        ConfigurationsEditor
    },
    data() {
        return {
            compatibilityMode: false,
            devices:[],
            recipes : [],
            loading: false,
            lineSettings: {
                lineIdentification: {
                    visible: this.$grants.get().settings.lineIdentification,
                    groupName: this.$gettext("Plant identifications"),
                    settings:
                        {
                            customerId: {
                                name: this.$gettext("Customer domain identificator"),
                                value: "",
                                description: "",
                                type: "string",
                                readOnly: true
                            },
                            factoryId: {
                                name: this.$gettext("Factory identificator"),
                                value: "",
                                description: "",
                                type: "string",
                                readOnly: true
                            },
                        }
                },
                openingTimeSettings: {
                    visible: this.$grants.get().settings.standardOpeningTime,
                    groupName: this.$gettext("Standard opening time"),
                    settings: {
                        workDays: {
                            name: this.$gettext("Weekly work days"),
                            options: DateTime.daysOfWeek(),
                            value: [],
                            description: "",
                            type: "multiChecks",
                            readOnly: false,
                        },
                        workHours: {
                            name: this.$gettext("Daily work schedule"),
                            description: "",
                            type: "subgroup",
                            groupParentName: "openingTimeSettings",
                            settings: {
                                mondayWorkHours: {
                                    name: this.$gettext("Monday work schedule"),
                                    fromLabel: this.$gettext("From"),
                                    toLabel: this.$gettext("to"),
                                    value: [],
                                    type: "timeSpans",
                                    copyPaste: true,
                                    multi: true,
                                    index: 0,
                                },
                                tuesdayWorkHours: {
                                    name: this.$gettext("Tuesday work schedule"),
                                    fromLabel: this.$gettext("From"),
                                    toLabel: this.$gettext("to"),
                                    value: [],
                                    type: "timeSpans",
                                    copyPaste: true,
                                    multi: true,
                                    index: 1,
                                },
                                wednesdayWorkHours: {
                                    name: this.$gettext("Wednesday work schedule"),
                                    fromLabel: this.$gettext("From"),
                                    toLabel: this.$gettext("to"),
                                    value: [],
                                    type: "timeSpans",
                                    copyPaste: true,
                                    multi: true,
                                    index: 2,
                                },
                                thursdayWorkHours: {
                                    name: this.$gettext("Thursday work schedule"),
                                    fromLabel: this.$gettext("From"),
                                    toLabel: this.$gettext("to"),
                                    value: [],
                                    type: "timeSpans",
                                    copyPaste: true,
                                    multi: true,
                                    index: 3,
                                },
                                fridayWorkHours: {
                                    name: this.$gettext("Friday work schedule"),
                                    fromLabel: this.$gettext("From"),
                                    toLabel: this.$gettext("to"),
                                    value: [],
                                    type: "timeSpans",
                                    copyPaste: true,
                                    multi: true,
                                    index: 4,
                                },
                                saturdayWorkHours: {
                                    name: this.$gettext("Saturday work schedule"),
                                    fromLabel: this.$gettext("From"),
                                    toLabel: this.$gettext("to"),
                                    value: [],
                                    type: "timeSpans",
                                    copyPaste: true,
                                    multi: true,
                                    index: 5,
                                },
                                sundayWorkHours: {
                                    name: this.$gettext("Sunday work schedule"),
                                    fromLabel: this.$gettext("From"),
                                    toLabel: this.$gettext("to"),
                                    value: [],
                                    type: "timeSpans",
                                    copyPaste: true,
                                    multi: true,
                                    index: 6,
                                },
                            }
                        },
                        notWorkingDaysOfYear: {
                            name: this.$gettext("Non working periods of years"),
                            fromLabel: this.$gettext("From"),
                            toLabel: this.$gettext("to"),
                            value: [],
                            description: "",
                            type: "dateSpans",
                            multi: true,
                            readOnly: false,
                        },
                    },
                },
            },
        }
    },
    mounted: async function () {
        if (this.$root.startedUp) {
            this.loading = true;
            this.$root.setLoading(this.loading, "");

            this.loadSettings();
            this.loading = false;
            this.$root.setLoading(this.loading, "");

        }
    },

    computed: {
        workDays() {
            return this.lineSettings.openingTimeSettings.settings.workDays.value;
        }
    },

    watch: {
        workDays() {
            this.lineSettings.openingTimeSettings.settings.workHours.settings.mondayWorkHours.visible = this.workDays.includes(0);
            this.lineSettings.openingTimeSettings.settings.workHours.settings.tuesdayWorkHours.visible = this.workDays.includes(1);
            this.lineSettings.openingTimeSettings.settings.workHours.settings.wednesdayWorkHours.visible = this.workDays.includes(2);
            this.lineSettings.openingTimeSettings.settings.workHours.settings.thursdayWorkHours.visible = this.workDays.includes(3);
            this.lineSettings.openingTimeSettings.settings.workHours.settings.fridayWorkHours.visible = this.workDays.includes(4);
            this.lineSettings.openingTimeSettings.settings.workHours.settings.saturdayWorkHours.visible = this.workDays.includes(5);
            this.lineSettings.openingTimeSettings.settings.workHours.settings.sundayWorkHours.visible = this.workDays.includes(6);
        }
    },

    methods: {
        async loadConfiguredDevicesList() {
            //Get the list of devices that has a descriptions file saved in DB
            let self = this;
            this.devices.clear();
            await this.$settings.list(this.$settings.OpcDescriptions, this.$settings.InstanceScope)
                .then(devices => {
                    for (let i = 0; i < devices.length; i++) {
                        if (devices[i].Name)
                            self.devices.push(devices[i].Name);
                        // self.devices.push({value: devices[i].Name, readOnly: true});
                    }
                });
        },
        loadSettings() {
            //Get line settings and merge values on descriptor
            try {
                let self = this;
                this.settingsValues = this.$settings.getPlantSettings();
                this.identificationSettings = this.$settings.getIdentificationSettings();

                //FN The identifications Settigns must be fetched regardless of the other line values.
                if(Object.isUseful(this.identificationSettings)) {
                    let identificationSettingsValues = this.$utils.detach(this.identificationSettings);
                    this.mergeSetting(this.lineSettings.lineIdentification.settings.customerId, identificationSettingsValues.customerId);
                    this.mergeSetting(this.lineSettings.lineIdentification.settings.factoryId, identificationSettingsValues.factoryId);
                }

                if (Object.isUseful(this.settingsValues)) {
                    //This variable is used to create a copy of settings to keep his old values for auditing purposes
                    let values = this.$utils.detach(this.settingsValues);
                    let i = 0;
                    //Merge settings values on descriptor
                    if (this.settingsValues) {
                        this.compatibilityMode = (Array.isUseful(values.workHours) && !Array.isArray(values.workHours[0]));
                        if(this.compatibilityMode) {
                            values.workHours = [];
                            if(!Object.isUseful(values.workDays)) {
                                for (i=0 ; i<=6 ; i++) {
                                    values.workHours.push([]);
                                }
                            } else
                                for (i=0;i<=6;i++) {
                                    if(Object.isUseful(values.workDays[i])) {
                                        values.workHours.push(this.$utils.detach(this.settingsValues.workHours));
                                    } else
                                        values.workHours.push([]);
                                }
                        }

                        for (let j=0; j<=values.workHours.length-1;j++){
                            let dayWeek;
                            Object.fromEntries(
                                Object.entries(self.lineSettings.openingTimeSettings.settings.workHours.settings).filter(function(wh) {
                                    if( wh[1].index === j)
                                        dayWeek = wh[0];
                                    return wh[1].index === j;
                                }));
                            self.mergeSetting(self.lineSettings.openingTimeSettings.settings.workHours.settings[dayWeek], values.workHours[j]);
                        }
                        this.mergeSetting(this.lineSettings.openingTimeSettings.settings.workDays, (Object.isUseful(values.workDays) ? values.workDays.sort() : []));
                        this.mergeSetting(this.lineSettings.openingTimeSettings.settings.notWorkingDaysOfYear, (Object.isUseful(values.notWorkingDaysOfYear) ? values.notWorkingDaysOfYear.sort() : []));
                    }
                }
            }
            finally {
                if(this.compatibilityMode)
                    this.valueChanged();
            }
        },
        async valueChanged(groupName, settingName, actionObj, subGroup) {
            let trail;
            if (this.settingsValues === null)
                this.settingsValues = {};
            //If compatibility mode , it doesn't save the audits because is an autosave ,one time only, from the old type of workhours to the new type of [[timeSpan]
            if(!this.compatibilityMode)
                trail = this.$audits.getSettingChangedTrailObject(groupName, settingName, actionObj, this.settingsValues, this.lineSettings, ["PlantSettings"], subGroup);



            //This variable is used to create a copy of settings to keep his old values , new values and use them to save the audits
            let values = this.$utils.detach(this.lineSettings);
            let self = this;
            let workHoursValue = [];
            for (const key in values.openingTimeSettings.settings.workHours.settings) {
                let whs = values.openingTimeSettings.settings.workHours.settings[key].value;
                workHoursValue.push(whs);
            }

            this.settingsValues.customerId = values.lineIdentification.settings.customerId.value;
            this.settingsValues.factoryId = values.lineIdentification.settings.factoryId.value;
            //When imported from excel, numerical recipes are imported as numbers, we need to ensure string conversion
            for(let item of this.settingsValues.lineSpeeds)
                item.recipe = item.recipe.toString();

            this.settingsValues.workHours = workHoursValue;
            let workdays = values.openingTimeSettings.settings.workDays.value;
            this.settingsValues.workDays = (Object.isUseful(workdays) ? workdays.sort() : []);
            this.settingsValues.notWorkingDaysOfYear = values.openingTimeSettings.settings.notWorkingDaysOfYear.value;
            this.$settings.savePlantSettings(this.settingsValues)
                .then(() => {
                    self.$root.checkIdentificationSettings(self.settingsValues);
                    // //check for avoiding empty "deleted" audits
                    if (!this.compatibilityMode && !(actionObj.action==='deleted' &&  typeof trail.previousVal==='undefined')) {
                        self.$audits.save(trail.operator , trail.action, trail.previousVal , trail.nextVal, trail.actionId)
                            .catch(() => {
                                debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            })
                    }
                    if(this.compatibilityMode)
                        this.compatibilityMode = false;
                })
                .catch(err => {
                    debugger
                    self.$root.showErrorNotification(self.$gettext("An error occurred while saving settings to DB"), true);
                    self.loadSettings();
                })
        },
        mergeSetting(target, source) {
            if (source){
                target.value = source;
            }
        },

        //Check the matching between the recipes of lineSetting and that in the DB and advise about the missing recipes
        checkRecipes(){

            let missingRecipes = '';
            if(!Array.isUseful(this.recipes))
                return;
            let lineSettingsRecipes = [];
            if(this.lineSettings.productionSettings.settings.lineSpeeds.value[0].key)
                lineSettingsRecipes = this.lineSettings.productionSettings.settings.lineSpeeds.value.map(({key})=> key);
            else{
                lineSettingsRecipes = this.lineSettings.productionSettings.settings.lineSpeeds.value.map(record=>{
                    return record.recipe
                });
            }

            for(const inputRecipe of lineSettingsRecipes) {
                let found = false;
                for(const availableRecipe of this.recipes) {
                    if (this.$utils.matchWildcardString(availableRecipe, inputRecipe))
                        found = true;
                }
                if(!found)
                    missingRecipes += inputRecipe + ', '
            }

            if (missingRecipes)
                this.$root.showErrorNotification(this.$gettext("These recipes are missing from DB: ") + missingRecipes.slice(0,-2), true, true);

        },

        lineSpeedImportValidator(data) {
            let shouldSkip = false;
            let response = true;

            let numbersOfHeaders = data.headers.length;
            for(let item of data.records)  {
                if (shouldSkip) {
                    return;
                }
                //TODO : this control should be into ImportExportExcel ?? this last component doesn't import the header of an empty column
                if(Object.keys(item).length !== numbersOfHeaders) {
                    let missingColumn = data.headers.filter(x => !Object.keys(item).includes(x));
                    this.$root.showErrorNotification(this.$gettext("Unable to import excel. The column {0} must be filled and contain only numbers").format(missingColumn), true);
                    shouldSkip = true;
                    response = false;
                } else {
                    Object.keys(item).forEach(key => {
                        if (shouldSkip) {
                            return;
                        }
                        if (key !== 'recipe') {
                            if (isNaN(item[key])) {
                                this.$root.showErrorNotification(this.$gettext("Unable to import excel. The column {0} must be filled and contain only numbers").format(key), true);
                                shouldSkip = true;
                                response = false;
                            }
                        }
                    });
                }
            }
            return response;
        },

        parseLoadedLineMachines(arr) {
            let parsedArray = [];

            if(Array.isUseful(arr)) {
                let self = this;
                arr.forEach(device => {
                    let obj = {"value": device, "readOnly": false} ;
                    if(self.devices.includes(device)) {
                        obj.readOnly = true;
                    }
                    parsedArray.push(obj);
                });
            } else {
                this.devices.forEach(device => {
                    parsedArray.push({"value": device, "readOnly": true}
                    )});
            }

            return parsedArray
        },

        parseLineMachinesToSave(arr) {
            return arr.map(a => a.value);
        },
    }
}
</script>

<style scoped>

</style>
