<template>
    <v-layout column fill-height>
        <v-card class="ma-3 av-card" height="60px" elevation="0" v-if="enabled || expandedElement"
                id="dataExplorerHeader" data-qa-type="data-explorer" data-qa-name="header">
            <v-layout row fill-height align-center>
                <v-menu offset-y transition="slide-y-transition" bottom v-if="dropDownItems.find(el => el === true)">
                    <v-btn slot="activator" flat icon>
                        <av-icon id="toolbar-dropdown" small>fa-bars</av-icon>
                    </v-btn>
                    <v-list id="toolbar-dropdown-menu">
                        <v-list-tile v-if="dropDownItems[0]">
                            <v-list-tile-content>
                                <v-switch v-model="userShowDataExplorer" label="Show data explorer" class="ml-3"
                                          style="margin-top: 11px"></v-switch>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile v-if="dropDownItems[1]">
                            <v-list-tile-content>
                                <v-switch v-model="allowItemsReordering" label="Allow items reordering" class="ml-3"
                                          style="margin-top: 11px"></v-switch>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile v-if="dropDownItems[2]">
                            <v-list-tile-content>
                                <v-switch v-model="dataExplorationMode.simplifiedView" label="Simplified view"
                                          class="ml-3" style="margin-top: 11px"></v-switch>
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-menu>

                <v-layout column align-center justify-center style="max-width: 100px">
                    <template v-if="saving">
                        <img v-show="saving" :src="loaderGif" style="width:50px; height:50px;"/>
                        <label style="margin-top: -20px">SAVING</label>
                    </template>
                    <template v-else>
                        <av-icon color="info">fas fa-save</av-icon>
                        <label>SAVED</label>
                    </template>
                </v-layout>

                <v-spacer/>

                <template>
                    <span class="title">{{ getItemStatus.message }}</span>
                    <av-button :text="$gettext('REQUEST ACTIVATION')" @click="requestActivation"
                               v-if="requestActivationGrant"
                               buttonIcon="fa-play" color="info" iconColor="light" :disabled="saving"/>
                    <av-button :text="$gettext('ACTIVATE')" @click="deploy" v-if="deployGrant"
                               :disabled="disableStart || saving"
                               buttonIcon="fa-play" color="info" iconColor="light"/>
                    <av-button :text="$gettext('DEACTIVATE')" @click="undeploy" v-if="getItemStatus.status > 0"
                               :disabled="disableStop || saving"
                               buttonIcon="fa-stop" color="error" iconColor="light"/>
                    <av-button v-if="needsDeleteButton" :text="$gettext('DELETE')" :disabled="!canBeDeleted"
                               iconColor="light" color="error"
                               @click="deleteElement" id="delete" buttonIcon="fas fa-trash"
                               :disabledTooltip="$gettext('Entity has dependant instances and cannot be deleted')"/>
                </template>
                <template v-if="!currentElement.dataExplorationMode.noPreview">
                    <av-button :text="$gettext('EXPAND')" v-if="!expandedElement" color="info"
                               @click="expandedElement = !expandedElement" iconColor="light" id="expand"
                               buttonIcon="fas fa-expand"/>
                    <av-button :text="$gettext('COLLAPSE')" v-else color="info"
                               @click="expandedElement = !expandedElement" iconColor="light" id="collapse"
                               buttonIcon="fas fa-compress"/>
                </template>

                <template>
                    <av-button @click="currentElement.getHistory()" @click.stop="drawer = !drawer"
                               :text="$gettext('VERSIONS')" color="info"/>
                    <v-navigation-drawer enable-resize-watcher right fixed app v-model="drawer">
                        <v-list three-line>
                            <v-layout row align-center>
                                <v-btn icon @click.stop="drawer = !drawer">
                                    <v-icon>fas fa-arrow-left</v-icon>
                                </v-btn>
                                <label class="sectionTitle">
                                    <translate>Versions</translate>
                                </label>
                            </v-layout>
                            <av-switch class="mt-4" :label="$gettext('Show revision details')"
                                       v-model="showRevisionDetails" style="margin-bottom: 20px; margin-left: 15px"/>
                            <template v-if="!showRevisionDetails" v-for="(historyItem, index) in activeVersions">
                                <v-list-tile
                                    :style="(currentElement.selectedHistoryVersion === historyItem.name) ? 'background-color: var(--av-lightblue); color: white;': ''"
                                    :key="index"
                                    @click="currentElement.getDescriptorForVersion(historyItem.internal_name, historyItem.version)"
                                    :disabled="saving"
                                    class="mt-3"
                                >
                                    <v-list-tile-content>
                                        <v-layout align-center row>
                                            <v-flex align-center style="padding-right: 5px">
                                                <av-icon
                                                    :color="(currentElement.deployedVersion === historyItem.version) ? 'green' : 'grey lighten-1'"
                                                    class="mr-2">fas fa-play
                                                </av-icon>
                                            </v-flex>
                                            <v-flex align-center>
                                                <v-list-tile-title>
                                                    <translate>Version</translate>
                                                    : {{ historyItem.activeVersion }}
                                                </v-list-tile-title>
                                                <v-list-tile-sub-title
                                                    :style="(currentElement.selectedHistoryVersion === historyItem.name) ? 'color: white;': ''">
                                                    <translate>Modified By</translate>
                                                    : {{ historyItem.modifiedBy }}
                                                </v-list-tile-sub-title>
                                                <v-list-tile-sub-title
                                                    :style="(currentElement.selectedHistoryVersion === historyItem.name) ? 'color: white;': ''">
                                                    <translate>Timestamp</translate>
                                                    : {{ new Date(historyItem.deployTime).format() }}
                                                </v-list-tile-sub-title>
                                                <v-list-tile-sub-title
                                                    :style="(currentElement.selectedHistoryVersion === historyItem.name) ? 'color: white;': ''">
                                                    <translate>Revision</translate>
                                                    : {{ historyItem.version }}
                                                </v-list-tile-sub-title>
                                            </v-flex>
                                        </v-layout>
                                    </v-list-tile-content>
                                </v-list-tile>
                            </template>
                            <v-expansion-panel v-show="showRevisionDetails" class="pa-0">
                                <v-expansion-panel-content v-for="(historyItem,i) in historyItemsFormatted"
                                                           style="height: 100%; padding: 35px 0">
                                    <template v-slot:header>
                                        <v-layout align-center row>
                                            <v-flex align-center>
                                                <av-icon
                                                    v-if="historyItem.hasActiveVersion || historyItem.versions.length > 0"
                                                    :color="historyItem.hasActiveVersion ? 'green' : 'grey lighten-1'"
                                                    class="mr-2">fas fa-play
                                                </av-icon>
                                                <av-icon v-else>fa-fw</av-icon>
                                            </v-flex>
                                            <v-layout style="width: 100%;" column>
                                                <div class="subheading">
                                                    <translate>From</translate>
                                                    : {{ historyItem.timestampStart }}
                                                </div>
                                                <div class="subheading">
                                                    <translate>To</translate>
                                                    : {{ historyItem.timestampEnd }}
                                                </div>
                                                <div class="subheading">
                                                    <translate>User</translate>
                                                    : {{ historyItem.modifiedBy }}
                                                </div>
                                                <div v-if="historyItem.versions.length === 1" class="subheading">
                                                    <translate>Version</translate>
                                                    : {{ historyItem.versions }}
                                                </div>
                                                <div v-else-if="historyItem.versions.length > 1" class="subheading">
                                                    <translate>Versions</translate>
                                                    : {{ historyItem.versions }}
                                                </div>
                                                <div class="subheading">
                                                    <translate>Revisions</translate>
                                                    : {{ historyItem.revisions }}
                                                </div>
                                            </v-layout>
                                        </v-layout>
                                    </template>
                                    <v-list three-line style="margin-top: 40px;">
                                        <template v-for="(version, index) in historyItem.children">
                                            <v-list-tile
                                                :style="(currentElement.selectedHistoryVersion === version.name) ? 'background-color: var(--av-lightblue); color: white;': ''"
                                                :key="index"
                                                @click="currentElement.getDescriptorForVersion(version.internal_name, version.version)"
                                                :disabled="saving"
                                            >
                                                <v-list-tile-content>
                                                    <v-layout align-center row style="margin-left: 20px">
                                                        <v-flex align-center>
                                                            <av-icon
                                                                v-if="version.activeVersion > 0"
                                                                :color="(currentElement.deployedVersion === version.version) ? 'green' : 'grey lighten-1'"
                                                                class="mr-2">fas fa-play
                                                            </av-icon>
                                                            <av-icon v-else>fa-fw</av-icon>
                                                        </v-flex>
                                                        <v-flex align-center
                                                                :style="(version.activeVersion === 0) ? 'margin-left: 0px': ''">
                                                            <v-list-tile-title>
                                                                <translate>Revision</translate>
                                                                : {{ version.version }}
                                                            </v-list-tile-title>
                                                            <v-list-tile-sub-title
                                                                v-if="version.activeVersion"
                                                                :style="(currentElement.selectedHistoryVersion === version.name) ? 'color: white;': ''">
                                                                Version: {{ version.activeVersion }}
                                                            </v-list-tile-sub-title>
                                                            <v-list-tile-sub-title
                                                                :style="(currentElement.selectedHistoryVersion === version.name) ? 'color: white;': ''">
                                                                <translate>Modified By</translate>
                                                                : {{ version.modifiedBy }}
                                                            </v-list-tile-sub-title>
                                                            <v-list-tile-sub-title
                                                                :style="(currentElement.selectedHistoryVersion === version.name) ? 'color: white;': ''">
                                                                <translate>Timestamp</translate>
                                                                :
                                                                {{
                                                                    new Date(version['@timestamp']).format() || new Date(version.deployTime).format()
                                                                }}
                                                            </v-list-tile-sub-title>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-list-tile-content>
                                            </v-list-tile>
                                        </template>
                                    </v-list>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-list>
                    </v-navigation-drawer>
                </template>
                <av-button :text="$gettext('BACK')" color="error" :disabled="saving" @click="goToMainPage" id="BACK"
                           iconColor="light" buttonIcon="fas fa-arrow-left fa-5x"/>
            </v-layout>
        </v-card>
        <av-split-pane :min-percent='showDataExplorer ? 10 : 0'
                       :default-percent='(showDataExplorer ? (!isForm ? defaultPercentDataExplorerPanel : 50) : 0)'
                       :fixed="!showDataExplorer || isForm"
                       split="vertical" :style="panelStyle" data-qa-type="panel">
            <template slot="paneL" v-if="showDataExplorer">
                <v-card v-if="enabled && !expandedElement" class="ml-3 mr-1 px-2" elevation="0"
                        style="border-radius: 5px; border: 1px solid lightgrey;height: calc(100% - 8px)">
                    <v-layout row>
                        <v-card-title class="sectionTitle" v-translate>Available data</v-card-title>
                        <img v-show="refreshingMapping" :src="loaderGif" style="width:50px; height:50px;"/>
                        <v-tooltip v-show="!refreshingMapping" left bottom>
                            <v-btn fab small slot="activator" @click="loadDataMappings(true)">
                                <av-icon>fas fa-sync-alt</av-icon>
                            </v-btn>
                            <span v-translate>Refresh data definitions</span>
                        </v-tooltip>
                    </v-layout>
                    <v-divider/>
                    <template v-if="autoDataSourcing">
                        <label class="subheading pa-3" v-translate>Selected widget can automatically source needed data. You can just select the full data source nodes</label>
                        <div>
                            <v-radio-group v-model="selectedSingleIndexPattern">
                                <v-radio
                                    v-for="(pattern, index) in indexPatterns.singleSelect"
                                    :key="index"
                                    :label="pattern.name"
                                    :value="index"
                                    :id="computedRadioId + 'SelectedPatern'"
                                    @change="selectedPatternChanged()"
                                ></v-radio>
                            </v-radio-group>
                            <v-checkbox
                                v-for="(pattern, index) in indexPatterns.multiSelect"
                                v-model="selectedMultiIndexPatterns"
                                :key="index"
                                :label="pattern.name"
                                :value="index"
                                @change="selectedPatternChanged()"
                            ></v-checkbox>
                        </div>
                    </template>
                    <v-text-field v-model="search" label="Search variable" clearable class="mx-2 mb-2 mt-3"
                                  :disabled="dataExplorationMode.filterVariables !== ''" data-qa-type="input-text" data-qa-name="search-variable"/>
                    <v-layout column align-start justify-start fill-height
                              :style="'height: calc(100% - {0}px); overflow:auto;'.format(autoDataSourcing ? 255 : 135)">
                        <av-expansion-panel v-if="Array.isUseful(groups) && !documentSelectMode" expand class="pa-0" data-qa-type="expansion-panel-group">
                            <v-expansion-panel-content v-for="group in groups" class="pa-0 expansionPanelHeader" data-qa-type="expansion-panel" :data-qa-name="group.toLowerCase().replaceAll(' ','-')">
                                <template v-slot:header>
                                    <label class="ml-0 pl-0 sectionTitle"
                                           style=" transform: translateX(-18px) scale(.9);">{{ group }}</label>
                                </template>
                                <data-mapping-tree-view :mapping="mapping.filter( ( el ) =>  el.group === group )"
                                                        :dataItems="dataItems" :filterItems="filterItems"
                                                        :search="search" />
                            </v-expansion-panel-content>
                        </av-expansion-panel>
                        <data-mapping-tree-view v-else :documentSelect="documentSelectMode" :mapping="filteredMapping"
                                                :dataItems="dataItems" :filterItems="filterItems" :search="search"/>
                    </v-layout>
                </v-card>
            </template>
            <template slot="paneR">
                <av-split-pane split="vertical"
                               :min-percent='(enabled && !dataExplorationMode.noDataExploration) ? minPercentLeftPanel : 0'
                               :default-percent='defaultPercentLeftPanel'
                               :fixed="!enabled || dataExplorationMode.noDataExploration || expandedElement"
                               class="full-size" data-qa-type="panel">
                    <template slot="paneL" v-if="!dataExplorationMode.noDataExploration">
                        <v-card v-if="enabled && !expandedElement && !isRecipe && !isWizard" class="ml-1 mr-1 px-1" elevation="0"
                                style="border-radius: 5px; border: 1px solid lightgrey;height: calc(100% - 8px)">
                            <DataRepresentationConfigurator v-if="!isForm && !isRecipe"
                                                            :representationMode="dataExplorationMode"
                                                            :enabled="enabled"
                                                            :dataItems="dataItems" :filterItems="filterItems"
                                                            :aggregationItems="aggregationItems"
                                                            :functionItems="functionItems"
                                                            :rules="rules" :outputs="outputs"
                                                            :availableDevices="availableDevices"
                                                            :selectedDevices="selectedDevices"
                                                            :visualizationTargets="visualizationTargets"
                                                            :visualizationOptions="visualizationOptions"
                                                            :preferredAggregations="preferredAggregations"
                                                            :properties="currentElement.properties"
                                                            :allowReordering="allowItemsReordering"
                                                            v-on:ItemsChanged="matchDataItemsWithDefinitions"
                                                            v-on:selectedDevicesChanged="selectedDevicesChanged"
                                                            v-on:itemsOrderingChanged="onItemOrderingChanged" />
                            <FormConfigurator v-if="enabled && isForm" :formVariables="formVariables"
                                              :form="currentElement"
                                              :representationMode="formConfigurationMode"
                                              ref="childComponent"></FormConfigurator>
                        </v-card>
                        <recipe-configurator v-if="enabled && isRecipe" :recipe="recipe"
                                             :current-recipe="currentElement"
                                             :mapping="mapping"></recipe-configurator>
                        <wizard-configurator v-if="enabled && isWizard" :wizard="wizard"></wizard-configurator>
                    </template>
                    <template slot="paneR">
                        <ElementsComposer style="min-width: 100%; height: 100%;" id="composer" ref="composer" :type="type"
                                        v-if="!pendingError"
                                        :expand-element="expandedElement" v-on:NoLoadedElement="newElement"
                                        v-on:NewElementCreated="newElement" v-on:SavedElementLoaded="loadElement"
                                        :renamable="canBeDeleted"/>
                        <div v-else class="headline text-xs-center" style="color: var(--av-red); margin-top: 20px"
                             v-translate>An error occurred during app operation, try to reload page with F5 or contact service desk if problem keeps appearing
                        </div>
                    </template>
                </av-split-pane>
            </template>
        </av-split-pane>
    </v-layout>
</template>

<script>

import ElementsComposer from './ElementsComposer.vue'
import DataRepresentationConfigurator from './DataRepresentationConfigurator.vue'
import FormConfigurator from '@/components/dynamic-elements/forms/FormConfigurator.vue'
import FormWorkorder from '@/components/dynamic-elements/forms/FormWorkorder.vue'
import DataMappingTreeView from '@/components/utilities/DataMappingTreeView'
import Vue from 'vue'
import RecipeConfigurator from "@/components/dynamic-elements/recipes/RecipeConfigurator";
import WizardConfigurator from "../../components/dynamic-elements/wizards/WizardConfigurator";

export default {
    name: "DataExplorer",
    components: {
        RecipeConfigurator,
        FormWorkorder,
        ElementsComposer,
        DataRepresentationConfigurator,
        FormConfigurator,
        DataMappingTreeView,
        WizardConfigurator
    },
    data() {
        return {
            drawer: false,
            dataItems: [], //Data items that have been selected (by user or loaded element) for visualization
            filterItems: [],    //Data items that have been selected (by user or loaded element) for filtering
            aggregationItems: [], //Data items that have been selected (in data representation configurator or loaded element) to be cross aggregated
            functionItems: [], //Not data binded visualization elements
            rules: [], //Conditional algorithms for rule engine
            outputs: [], //Rule engine outputs
            formVariables: [],  //Configurable forms variables descriptors
            recipe: [],
            wizard: {},
            // data: [],   //Holds data that are pushed by currently loaded element when it queries DB, could be used to show live flowing data in the bottom pane
            currentElement: null, //Holds a reference to currently visualized element, needed to know when and what visualization capabilities are available
            mapping: [],  //Holds the whole available data mappings loaded from backend
            groups: [],     //Maps the groups of indexes returned by mapping
            indexPatterns: {singleSelect: [], multiSelect: []},  //Holds all the data items that match the compatible index patterns specified by current element (if element supports only specific data indexes)
                                                                 //they are split in single or multi select based on the possibility to select a single index within group or many of them
            selectedSingleIndexPattern: -1,   //Holds the data index selected by user among those available in singleSelect indexPatterns
            selectedMultiIndexPatterns: [0],   //Holds the data indexes selected by user among those available in multiSelect indexPatterns
            pendingError: false,    //In case of problems in loading elements list from DB this flag excludes some features (such as new elements creation) that may cause trouble if not all info are available
            expandedElement: false,  //Element is full screen
            userShowDataExplorer: false,  //User enabled the showDataExplorer switch, thus overriding element built-in preferences
            search: "",
            simplifiedView: true,
            allowItemsReordering: true,  //Shows items reordering controls on representation configurator, it is typically disabled for reports
            refreshingMapping: false,
            redirection: false,
            showRevisionDetails: false,
            filterItemRemoved: null
        }
    },
    // created() {
    //     window.onbeforeunload = this.confirmExit
    // },
    mounted: function () {
        if (this.$store.state.isEmbedded) {
            this.asyncMount()
        } else {
            this.mount()
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.checkExitConditions(next))
            next()
    },
    beforeRouteUpdate(to, from, next) {
        if (this.checkExitConditions(next))
            next()
    },
    methods: {
        async asyncMount() {
            await this.waitForPageLoading();
            this.mount();
        },
        mount() {
            this.init();
            this.$root.$on('deleteSelectedVersionFromCombobox', () => {
                this.currentElement.selectedHistoryVersion = ""
                this.currentElement.version = 0
            });
        },
        //TODO experiment of handling on browser or tab close events. Unused at moment since user logic is too complex
        //  confirmExit() {
        //         return "Some task is in progress. Are you sure, you want to close?";
        // },
        checkExitConditions(next) {
            if (this.currentElement) {
                if (this.$dynamicElements.isDefaultItemName(this.currentElement.properties.name))
                    this.redirectionDialog(this.$gettext("This item does not have a proper name. Do you want to automatically delete it?"), next, true, true);
                else if (this.getItemStatus.status === 0)
                    this.redirectionDialog(this.$gettext("Item is not activated. Are you sure you want to exit without activation?"), next, false, false);
                else return true
            } else return true;
            return false;
        },
        redirectionDialog(message, next, withDeletion, leaveIfNo) {
            let name = this.currentElement.properties.name;
            let type = this.currentElement.type;

            this.$root.showDialogBox(message, "",
                this.$gettext("Yes"), () => {
                    this.leaveItem(next, name, type, withDeletion)
                },
                this.$gettext("No"), () => {
                    leaveIfNo ? this.leaveItem(next, name, type, false) : null
                });
        },
        leaveItem(next, name, type, deleteItem) {
            if (next)
                next();
            else this.$refs.composer.setOpenState();

            if (deleteItem)
                setTimeout(() => {
                    this.$dynamicElements.delete(name, type)
                }, 100)
        },
        // iterateObject(nestedProp, compareKey, compareId, arr) {
        //     return arr.filter(o => {
        //         console.log(o[compareKey])
        //         const keep = o[compareKey].includes(compareId);
        //         if (!keep && o[nestedProp]) {
        //             o[nestedProp] = this.iterateObject(nestedProp, compareKey, compareId, o[nestedProp]);
        //         }
        //         console.log(keep)
        //         return keep;
        //     });
        // },

        async deploy() {
            await this.currentElement.deployElement();
        },
        async undeploy() {
            await this.currentElement.undeployElement();
        },
        async requestActivation() {
            if (await this.currentElement.requestActivation()) {

            }
        },

        /**** LOAD/CREATE ELEMENT ****/
         newElement(element) {
            this.bindElement(element);
            //When a new element is created, bind local data descriptors to element's descriptors and make them reactive
            if (element) {
                this.$set(this.currentElement, "dataItems", this.dataItems);
                this.$set(this.currentElement, "filterItems", this.filterItems);
                this.$set(this.currentElement, "aggregationItems", this.aggregationItems);
                this.$set(this.currentElement, "functionItems", this.functionItems);
                this.$set(this.currentElement, "rules", this.rules);
                if (Array.isUseful(this.currentElement.outputs))
                    this.outputs = this.currentElement.outputs;
                else this.$set(this.currentElement, "outputs", this.outputs);
                //If a NEW element has some variables it means they are declared in template.
                //in this case synch dataexplorer with them
                if (this.$route.query.formVariables) {
                    this.currentElement.formVariables = this.$route.query.formVariables;
                    this.$route.query.formVariables = []
                }

                if (Array.isUseful(this.currentElement.formVariables))
                    this.formVariables = this.currentElement.formVariables;
                else this.$set(this.currentElement, "formVariables", this.formVariables);

                if (Array.isUseful(this.currentElement.recipe))
                    this.recipe = this.currentElement.recipe;
                else this.$set(this.currentElement, "recipe", this.recipe);
                if (Array.isUseful(this.currentElement.wizard))
                    this.wizard = this.currentElement.wizard;
                else this.$set(this.currentElement, "wizard", this.wizard);
            }
        },
        loadElement(element) {
            this.bindElement(element); //Set reference to current shown element
            //Bind data descriptors saved in element to local descriptors
            this.dataItems = this.currentElement.dataItems;
            this.filterItems = this.currentElement.filterItems;
            this.aggregationItems = this.currentElement.aggregationItems;
            this.functionItems = this.currentElement.functionItems;
            this.rules = this.currentElement.rules;
            this.formVariables = this.currentElement.formVariables;
            this.outputs = this.currentElement.outputs;
            this.recipe = this.currentElement.recipe;
            this.wizard = this.currentElement.wizard;
            //Select data items on the data mappings tree
            this.matchDataItemsWithDefinitions();
            //Select indexes and devices saved within elements
            this.matchIndexesAndDevices();
        },
        //Function is called when a new element is loaded or created, it binds elements signals to data explorer handlers
        //And invokes all the operations needed to manage the new element
        bindElement(element) {
            this.clearItems();
            this.currentElement = element;
            if (this.enabled) {
                //Called when the data items are changed within element (for instance when element automatically collects needed data items)
                //local data descriptors instances must be synchronized with element's and must be reactive.
                let self = this;
                this.currentElement.$on('dataItemsUpdated', () => {
                    this.$set(self, "dataItems", this.currentElement.dataItems);
                    this.$set(self, "filterItems", this.currentElement.filterItems);
                    this.$set(self, "aggregationItems", this.currentElement.aggregationItems);
                    this.$set(self, "functionItems", this.currentElement.functionItems);
                    this.$set(self, "rules", this.currentElement.rules);
                    this.$set(self, "formVariables", this.currentElement.formVariables);
                    this.$set(self, "outputs", this.currentElement.outputs);
                    this.$set(self, "recipe", this.currentElement.recipe);
                    this.$set(self, "wizard", this.currentElement.wizard);
                    //When data items change, select new items on the data mappings tree
                    this.matchDataItemsWithDefinitions();
                });
                this.currentElement.$on('OnFullScreen', (fullScreen) => {
                    if (fullScreen)
                        self.expandedElement = true;
                });
                if (this.currentElement.isReport)
                    this.allowItemsReordering = false;
            }
            //If element supports automatic data sourcing, collect and show only the data indexes that are compatible with current element
            this.checkElementCompatibleDataPatterns();
        },
        clearItems() {
            this.dataItems = [];
            this.filterItems = [];
            this.aggregationItems = [];
            this.functionItems = [];
            this.rules = [];
            this.outputs = [];
            this.formVariables = [];
            this.recipe = [];
            this.wizard = {};
            this.matchDataItemsWithDefinitions();
        },
        matchDataItemsWithDefinitions() {
            let self = this;
            this.dataItems.forEach(function (item) {
                Vue.set(item, "matched", false)
            });
            this.filterItems.forEach(function (item) {
                Vue.set(item, "matched", false)
            });
            //Try to merge selected items with the full mapping list populating the tree view
            let matchesCount = 0;   //Used to short out matching loop when all selectedItems were matched
            this.$utils.forEachItemInObject(this.mapping, function (item) {
                //we have a data item
                try {
                    if (item && (Object.areDefined(item.index, item.root, item.name) || (Object.areDefined(item.index, item.name) && item.isRoot))) {
                        Vue.set(item, "selectedForVisualization", false);
                        Vue.set(item, "selectedForFiltering", false);
                        //Clear all items checked state unless they are listed in selected items
                        //Process items until we matched selected items count then just keep setting all other to unchecked to save time
                        if (matchesCount < (self.dataItems.length + self.filterItems.length)) {
                            if (self.dataItems) {
                                let itemFound = self.dataItems.find(function (element) {
                                    return ((element.index === item.index || element.index + "*" === item.index || element.index === item.index + "*") &&
                                        ((element.root === item.root && element.name === item.name) ||
                                            (element.index === item.index && element.name === item.name && element.isRoot && item.isRoot)));
                                });
                                if (itemFound) {
                                    Vue.set(item, "selectedForVisualization", true);
                                    Vue.set(itemFound, "matched", true);
                                }
                            }
                            if (self.filterItems) {
                                let itemFound = self.filterItems.find(function (element) {
                                    return ((element.index === item.index || element.index + "*" === item.index || element.index === item.index + "*") &&
                                        element.root === item.root && element.name === item.name);
                                });
                                if (itemFound) {
                                    Vue.set(item, "selectedForFiltering", true);
                                    Vue.set(itemFound, "matched", true);
                                }
                            }

                            matchesCount += item.selectedForFiltering ? 1 : 0;
                            matchesCount += item.selectedForVisualization ? 1 : 0;

                        }
                    }
                } catch (ex) {
                    debugger
                }
            });
        },


        /**** COMPATIBLE INDEX PATTERNS AND AUTO DATA SOURCING MANAGEMENT ****/

        //When an element is loaded, this function collects all the data items compatible with the index patterns exposed by element (if any)
        checkElementCompatibleDataPatterns() {

            this.indexPatterns.singleSelect.clear();
            this.indexPatterns.multiSelect.clear();
            this.selectedSingleIndexPattern = -1;
            this.selectedMultiIndexPatterns.clear();

            //Check if we are showing a element, and it supports data patterns matching
            if (!this.enabled /*|| !this.dataExplorationMode.autoDataSourcing*/ || !Array.isUseful(this.dataExplorationMode.compatibleDataPatterns))
                return;

            this.indexPatterns = this.$datalayer.selectIndexesByPatterns(this.mapping, this.dataExplorationMode.compatibleDataPatterns);

            //Only one item matches, just select it!
            if (this.indexPatterns.singleSelect.length === 1) {
                this.selectedSingleIndexPattern = 0;
                this.selectedPatternChanged();
            }
            if (this.indexPatterns.multiSelect.length === 1) {
                this.selectedMultiIndexPatterns.push(0);
                this.selectedPatternChanged();
            }
        },
        selectedPatternChanged() {
            //We are loading element data from DB, do not override selected patterns
            if (this.currentElement.isLoading())
                return;
            this.currentElement.properties.dataPatterns.clear();
            let indexes = [];
            for (let i = 0; i < this.selectedMultiIndexPatterns.length; i++)
                indexes.push(this.indexPatterns.multiSelect[this.selectedMultiIndexPatterns[i]].index);
            if (this.selectedSingleIndexPattern > -1)
                indexes.push(this.indexPatterns.singleSelect[this.selectedSingleIndexPattern].index);
            //Changes on dataPatterns triggers element save, we collect the array locally and assign once to avoid save hammering
            this.currentElement.properties.dataPatterns = indexes;
        },
        selectedDevicesChanged(devices) {
            this.currentElement.properties.selectedDevices = devices;
        },
        //When a element is loaded, the index patterns and devices saved within element
        //are selected within the compatible index patterns and devices found.
        //Match may fail if saved data indexes and or devices aren't available any more in mappings
        matchIndexesAndDevices() {
            if (!this.enabled)
                return;

            this.selectedSingleIndexPattern = -1;
            this.selectedMultiIndexPatterns.clear();

            for (const selectedIndex of this.currentElement.properties.dataPatterns) {
                for (const [i, indexPattern] of this.indexPatterns.singleSelect.entries())
                    if (selectedIndex === indexPattern.index) {
                        this.selectedSingleIndexPattern = i;
                        break;
                    }
                for (const [i, indexPattern] of this.indexPatterns.multiSelect.entries())
                    if (selectedIndex === indexPattern.index)
                        this.selectedMultiIndexPatterns.push(i);
            }
        },

        /**** DATA MAPPINGS TREE MANAGEMENT ****/

        //Loads available data mappings from backend.
        //String items within mappings are reported in the format itemName.keyword in accordance to elastic search nomenclature for searchable fields.
        //Elastic queries requires the .keyword suffix when item is used for filtering (let's say the WHERE clauses in a SQL queries)
        //while it must be removed when item value is requested (SELECT clause in SQL analogy).
        //This function removes the .keyword ending for beauty reasons and relies on the query construction apis to add or not the .keyword suffix based on circumstances.
        loadDataMappings(force = false) {
            if (this.enabled)
                if (force)
                    this.refreshingMapping = true;
                else
                    this.$root.setLoading(true, this.$gettext("Loading data definitions..."));

            this.$datalayer.loadDataDefinitions(null, force)
                .then(data => {
                    if (Array.isUseful(data)) {
                        this.$utils.forEachItemInObject(data, function (item) {
                            if (item.name && typeof item.name === 'string' && item.name.endsWith(".keyword")) {
                                item.name = item.name.replace(".keyword", "");
                            }
                        });
                        this.groups = [];
                        for (let index of data)
                            if (index.group)
                                if (!this.groups.includes(index.group))
                                    this.groups.push(index.group);

                        this.mapping = data;
                    }
                })
                .catch(t => {
                    debugger
                    console.error(t);
                    this.$root.showErrorNotification(this.$gettext("Error in retrieving data definitions from DB."), true);
                })
                .finally(() => {
                    this.$root.setLoading(false);
                    this.refreshingMapping = false;
                });
        },
        //Updates the list of names of all elements stored in DB. It will be used to validate names of new elements avoiding duplicates
        loadFullSavedElementsList() {
            this.$dynamicElements.listAll(this.type, true)
                .catch(err => {
                    this.$root.showErrorNotification(err, true);
                    this.pendingError = true; //Prevents user from creating new elements since we can't check name existence
                });
        },
        init() {
            let type = this.$route.params.routeId;
            let title = "";
            switch (type) {
                case "reports":
                    title = this.$gettext("Data reports");
                    break;
                case "rules":
                    title = this.$gettext("Rules configuration");
                    break;
                case "forms":
                    title = !this.$config.options.forms.entities ? this.$gettext("Form configuration") : this.$gettext("Entities configuration");
                    break;
                case "recipes":
                    title = this.$gettext("Recipe configuration");
                    break;
                case "wizards":
                    title = this.$gettext("Wizard configuration");
                    break;
                default:
                    title = this.$gettext("Data exploration");
                    break;

            }
            this.$root.setCurrentPageTitle(title);
            this.currentElement = null;
            this.dataItems = [];
            this.filterItems = [];
            this.aggregationItems = [];
            this.functionItems = [];
            this.rules = [];
            this.outputs = [];
            this.formVariables = [];

            this.loadDataMappings();
            this.loadFullSavedElementsList();
            if (this.elementToLoad) {
                this.$refs.composer.setLoadingExternalItemState();
                setTimeout(this.waitForMappingThenOpen, 1000);
            } else if (this.elementToCreate) {
                this.$refs.composer.setLoadingExternalItemState();
                setTimeout(this.$refs.composer.createElement(this.elementToCreate.element), 3000)
            } else this.goToMainPage();
        },
        waitForMappingThenOpen() {
            if (Array.isUseful(this.mapping))
                this.$refs.composer.loadElement(this.elementToLoad);
            else {
                setTimeout(this.waitForMappingThenOpen, 1000);
            }
        },
        goToMainPage() {
            this.expandedElement = false;
            if (this.checkExitConditions())
                this.$refs.composer.setOpenState();
        },
        // enableElement() {
        //     let self = this;
        //     this.$root.showDialogBox(this.$gettext("Are you sure you want to enable this item and all it's dependencies?"), null, "Yes", self.currentElement.enableElement, "No", null);
        // },
        // disableElement() {
        //     let self = this;
        //     this.$root.showDialogBox(this.$gettext("Are you sure you want to disable this item and all it's dependencies?"), null, "Yes", self.currentElement.disableElement, "No", null);
        // },
        deleteElement() {
            let self = this;
            this.$root.showDialogBox(this.$gettext("Are you sure you want to permanently delete this item?"), null, "Yes", function () {
                self.deleteElementAndGoBack();
            }, "No", null);
        },
        deleteElementAndGoBack() {
            let self = this;
            this.$dynamicElements.delete(this.currentElement.properties.name, this.type)
                .then(() => {
                    self.currentElement.deleted = true;
                    self.goToMainPage();
                })
        },
        onItemOrderingChanged() {
            if (this.isReport && this.currentElement.reportTemplate)
                this.$root.showWarningNotification(this.$gettext("Items ordering was changed, this may cause a data references misalignment in excel template. Please check your template file."), true);
        },
    },
    watch: {
        saving() {
            if (!this.saving) {
                let self = this;
                setTimeout(() => {
                    if (self.currentElement)
                        self.currentElement.getHistory();
                }, 2000);
            }
        },
        type() {
            this.init();
        },
        dataExplorationMode: {
            handler: function () {
                this.userShowDataExplorer = this.enabled    //An element is loaded
                    && !this.dataExplorationMode.noDataExploration  //Data explorer is not fully disabled
                    && this.dataExplorationMode.requiresDataExploration //Element requires index selection
                    && !(this.dataExplorationMode.autoDataSourcing    //and, in case the element requires automatic data sourcing
                        && ((this.indexPatterns.singleSelect.length + this.indexPatterns.multiSelect.length) === 1));   //the number of compatible indexes is not 1 since in this case user can't do any further selection
            },
            deep: true
        },
        'dataExplorationMode.filterVariables': {
            handler: function () {
                if (this.dataExplorationMode.filterVariables !== "")
                    this.search = this.dataExplorationMode.filterVariables;
                else
                    this.search = "";
            }
        }
    },
    computed: {
        loaderGif() {
            return require('@/assets/avloader.png')
        },
        panelStyle() {
            let h = this.expandedElement || this.enabled ? 100 : 30;
            return {
                overflow: 'hidden',
                'max-height': 'calc(100% - ' + h + 'px)',
                'min-height': 'calc(100% - ' + h + 'px)'
            }
        },
        computedRadioId() {
            return this.toCamelCase(this.$router.history.current.name + 'Radio')
        },
        enabled() {
            return Object.isUseful(this.currentElement);
        },
        dataExplorationMode() {
            if (this.enabled)
                return this.currentElement.dataExplorationMode;
            return {};
        },
        elementToCreate() {
            return this.$route.query.elementToCreate;
        },
        elementToLoad() {
            return this.$route.query.elementToLoad;
        },
        formConfigurationMode() {
            if (this.enabled)
                return this.currentElement.formConfigurationMode;
            return {};
        },
        isForm() {
            return this.currentElement ? this.currentElement.isForm : false;
        },
        isRecipe() {
            return this.currentElement ? this.currentElement.isRecipe : false;
        },
        isRule() {
            return this.currentElement ? this.currentElement.isRule : false;
        },
        isReport() {
            return this.currentElement ? this.currentElement.isReport : false;
        },
        isWidget() {
            return this.currentElement ? this.currentElement.isWidget : false;
        },
        isQuery() {
            return this.currentElement ? this.currentElement.isQuery : false;
        },
        isWizard() {
            return this.currentElement ? this.currentElement.isWizard : false;
        },
        getItemStatus() {
            if (this.currentElement)
                return this.currentElement.getItemStatus();

            return {status: 0, message: ""};
        },
        disableStart() {
            return (this.isRule && this.currentElement.disableDeploy);
        },
        disableStop() {
            return (this.isRule && this.currentElement.disableStop);
        },
        showDataExplorer() {
            //Show data exploration tree only if:
            if (this.expandedElement)
                return false;
            if (this.isForm)
                return false;
            if (this.isWizard)
                return false
            return this.userShowDataExplorer;
        },
        visualizationTargets() {
            if (this.enabled)
                return this.currentElement.visualizationTargets;
            return null;
        },
        visualizationOptions() {
            if (this.enabled)
                return this.currentElement.visualizationOptions;
            return null;
        },
        preferredAggregations() {
            if (this.enabled)
                return this.currentElement.preferredAggregations;
            return null;
        },
        autoDataSourcing() {
            if (this.enabled)
                return this.dataExplorationMode.autoDataSourcing;
            return false;
        },
        deviceSelectMode() {
            if (this.enabled)
                return this.dataExplorationMode.deviceSelectMode;
            return false;
        },
        documentSelectMode() {
            if (this.enabled)
                return this.dataExplorationMode.documentSelectMode;
            return false;
        },
        minPercentLeftPanel() {
            if (!this.expandedElement && this.enabled && !this.dataExplorationMode.noDataExploration) {
                return 10;
            }
            return 0
        },
        defaultPercentLeftPanel() {
            if (!this.expandedElement && this.enabled && !this.dataExplorationMode.noDataExploration) {
                if (!this.showDataExplorer && !this.dataExplorationMode.noPreview)
                    return 40;
                else if (this.dataExplorationMode.noPreview)
                    return 70;
                else
                    return this.currentElement.isRule || this.currentElement.isForm ? 45 : 35;
            }
            return 0
        },
        defaultPercentDataExplorerPanel() {
            if (this.enabled && this.dataExplorationMode.noPreview)
                return 25;
            else return 20;
        },
        //Return the selected devices that were saved within current element
        //This is needed only for elements that require a device to source data automatically
        //This typically applies to all elements that supports the automatic data sourcing
        selectedDevices() {
            if (this.deviceSelectMode) {
                return this.currentElement.properties.selectedDevices;
            }
        },
        type() {
            return this.$route.params.routeId;
        },
        filteredMapping() {
            if (this.documentSelectMode) {
                let returning = [];
                if (this.indexPatterns) {
                    let allowedIndexes = this.indexPatterns.multiSelect.map((item) => {
                        return item.index
                    }).concat(this.indexPatterns.singleSelect.map((item) => {
                        return item.index
                    }));
                    for (let item of this.mapping) {
                        if (allowedIndexes.includes(item.index))
                            returning.push(item)
                    }
                }
                return returning;
            } else return this.mapping;
        },
        saving() {
            return this.currentElement && this.currentElement.saving
        },
        dropDownItems() {
            let returning = [false, false];
            if (!this.isForm && !this.dataExplorationMode.noDataExploration)
                returning[0] = true;
            if (this.isReport)
                returning[1] = true;
            // if(this.isRule && this.dataExplorationMode.ruleType === 'activity')
            //     returning[2] = true;
            return returning;
        },
        needsDeleteButton() {
            return this.currentElement && this.currentElement.properties.unDeletable
        },
        deployGrant() {
            let type = this.$route.params.routeId
            if ((this.getItemStatus.status === 0 || this.getItemStatus.status === -1) && type === "recipes")
                return this.$grants.get().recipes.canActivate
            else return this.getItemStatus.status === 0;
        },
        requestActivationGrant() {
            let type = this.$route.params.routeId
            //if user have grant for activation, he doesn't have to request activation
            if (this.getItemStatus.status === -1 && type === "recipes" && this.$grants.get().recipes.canActivate)
                return false
            else if (this.getItemStatus.status === -1 && type === "recipes")
                return this.$grants.get().recipes.canRequestActivation
            else return this.getItemStatus.status === -1;
        },
        activeVersions() {
            let activeVersions = this.currentElement.historyItems.filter(historyItem => historyItem.activeVersion > 0);
            activeVersions.sort((a, b) => {
                return b.activeVersion - a.activeVersion;
            });
            return activeVersions
        },
        historyItemsFormatted() {
            let arrayOfHistory = this.currentElement.historyItems
            let formattedHistoryItems = []
            for (let i = 0; i < arrayOfHistory.length; i++) {
                let historyObj = {};
                if (i === 0 || arrayOfHistory[i].sessionId !== arrayOfHistory[i - 1].sessionId) {
                    historyObj = {...arrayOfHistory[i]};
                    historyObj.children = arrayOfHistory.filter(historyItem => historyItem.sessionId === historyObj.sessionId);
                    //timestamps reformat
                    let sessionStartTime = new Date(historyObj.children[historyObj.children.length - 1]['@timestamp']).format();
                    let sessionEndTime = new Date(historyObj.children[0]['@timestamp']).format();
                    //revision reformat
                    let firstRevision = historyObj.children[historyObj.children.length - 1].version;
                    let lastRevision = historyObj.children[0].version;
                    //version reformat
                    let filteredVersions = historyObj.children.filter(item => item.activeVersion > 0).map(item => item.activeVersion);
                    historyObj.hasActiveVersion = historyObj.children.some(item => item.version === this.currentElement.deployedVersion);
                    historyObj.versions = filteredVersions.length > 0 ? filteredVersions.join(", ") : 0
                    historyObj.timestampStart = sessionStartTime;
                    historyObj.timestampEnd = sessionEndTime;
                    historyObj.revisions = firstRevision === lastRevision ? firstRevision : firstRevision + ' - ' + lastRevision;
                    formattedHistoryItems.push(historyObj);
                }
            }
            return formattedHistoryItems
        }
    },
    asyncComputed: {
        //Enumerate the devices available for each of the indexes selected by user
        //This is needed only for elements that requires a device to source data automatically
        //This typically applies to all elements that supports the automatic data sourcing
        availableDevices() {

            let promises = [];

            if (this.currentElement && this.dataExplorationMode.deviceSelectMode) {
                //Source devices from multiple selectable indexes
                for (let selectedIndex = 0; selectedIndex < this.selectedMultiIndexPatterns.length; selectedIndex++)
                    promises.push(this.$devices.getDevicesFromIndexPattern(this.indexPatterns.multiSelect[this.selectedMultiIndexPatterns[selectedIndex]], this.dataExplorationMode.enumerateLineInDevices));

                //Source devices from the single selectable index
                if (this.selectedSingleIndexPattern > -1)
                    promises.push(this.$devices.getDevicesFromIndexPattern(this.indexPatterns.singleSelect[this.selectedSingleIndexPattern], this.dataExplorationMode.enumerateLineInDevices));
            }

            return new Promise((resolve) => {
                Promise.all(promises)
                    .then((devs) => {
                        resolve([].concat.apply([], devs))
                    })
                    .catch(() => {
                        resolve([])
                    })
            });
        },
        canBeDeleted() {
            //Show delete button in data explorer only if element is special one with unDeletable flag set (like entities)
            //and element tells itself to be deletable (ex: no instances attached to entity)
            if (this.needsDeleteButton)
                return this.currentElement.canBeDeleted();
            else return true;
        },
        // canBeDisabled() {
        //     //Show disable button in data explorer only if element is special one with unDeletable flag set (like entities)
        //     return (this.currentElement && this.currentElement.properties.unDeletable && !this.currentElement.properties.disabled)
        // }
    }
}


</script>

<style>

</style>
