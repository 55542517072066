<template>
    <v-layout justify-start align-start column fill-height>
        <v-list style="width:100%;" three-line>
            <template v-for="(element, index) in filteredElements">
                <v-list-tile v-if="!$dynamicElements.isItemDeleted(element.properties.name, element.type)" class="headline" style="height: 160px; margin-top: 50px" :key="element.properties.name" data-qa-type="list-element" :data-qa-name="element.properties.name.toLowerCase().replaceAll(' ','-')">
                    <v-list-tile-action class="mt-4" v-if="$dynamicElements.hasThumbnail(type)">
                        <v-hover :disabled="Object.isUseful(element.lock)">
                            <v-card slot-scope="{ hover }" style="width: 270px; height: 160px; padding: 5px; vertical-align: middle" :id="'openItem_' + element.properties.name"
                                    :class="`elevation-${hover ? 20 : 5}`" @click="openElement(element)" :disabled="Object.isUseful(element.lock)">
                                <v-layout column justify-center fill-height align-center style="margin-top: 4px">
                                    <img style="max-width: 250px; max-height: 150px" :src="element.thumbnail"/>
                                </v-layout>

                            </v-card>
                        </v-hover>
                    </v-list-tile-action>
                    <v-list-tile-content style="height: 200px; max-width: calc(100% - 500px)">
                        <v-layout justify-center align-start column fill-height mx-3>
                            <div class="title mt-4 mb-2" style="text-align: start;">
                                <translate>Name</translate>: {{ element.properties.name }}
                            </div>
                            <div v-if="type !== 'rules' && element.properties.title" class="subheading my-0" style="text-align: start;">
                                <translate>Title</translate>: {{ element.properties.title }}
                            </div>
                            <div class="subheading my-0" >
                              <translate>Type</translate>: {{getType(element)}}
                            </div>
                            <div class="subheading my-0" v-if="element.properties.description" style="text-align: start;" >
                                <translate>Description</translate>: {{ element.properties.description }}
                            </div>
                            <div class="subheading my-0" :style="'text-align: start;   color: ' + itemStatus(element).color">
                                <translate>Status</translate>: {{ itemStatus(element).text }}
                            </div>
                            <div v-if="element.lock" class="subheading my-0" style="text-align: start; color: var(--av-red)">
                                <translate>Locked by</translate>: {{ element.lock.user }}
                            </div>
                        </v-layout>
                    </v-list-tile-content>

                    <v-list-tile-action >
                        <v-layout wrap row>
                            <av-button :text="$gettext('Clone')" :disabled="Object.isUseful(element.lock)" class="ma-2 px-3" iconColor="light" color="info"
                                       @click="cloneElement(element)" :id="'cloneItemButton_' + element.properties.name" buttonIcon="fas fa-clone"/>
                            <av-button :text="$gettext('Edit')" :disabled="Object.isUseful(element.lock)" class="ma-2 px-3" iconColor="light" color="info"
                                       @click="openElement(element)" :id="'editItemButton_' + element.properties.name" buttonIcon="fas fa-pen"/>
                            <av-button v-if="!cloning" :text="$gettext('DELETE')" :disabled="element.properties.unDeletable || Object.isUseful(element.lock) || (type === 'rules' && itemStatus(element).active)"
                                       noTooltipClasses="ma-2 px-3" tooltipClasses="ma-2 px-3" iconColor="light" color="error" @click="deleteElement(index)" :id="'deleteItem_' + element.properties.name"
                                       buttonIcon="fas fa-trash" :disabledTooltip="element.lock ? $gettext('Item is currently edited by another user') : $gettext('This item may have other dependant items in the system. Please open it to verify whether it is deletable or not')"/>
                            <v-spacer></v-spacer>
                        </v-layout>
                    </v-list-tile-action>
                </v-list-tile>
                <v-divider></v-divider>
            </template>
        </v-list>
    </v-layout>
</template>

<script>

    import RulesEngine from '@/api/rules.js';
    import Mutex from '@/api/mutex.js';

    export default {
        name: 'ElementsLoader',
        data: () => ({
            elements: [],
            runningRules: [],
            types: [],
            typesWithNames: [],
            tagFilters: [],
        }),
        props: {
            type: {
                default: null
            },
            filterByName: {
                default: ""
            },
            statusSelected: {
                default: ['Active', 'Draft']
            },
            typeSelected: {
                default: 'All'
            },
            tagSelected: {
                default: 'All'
            },
            cloning: false
        },
        methods: {
            openElement(element) {
                if(element.lock)
                    return;
                this.$emit('OpenElement', element);
            },
            cloneElement(element) {
                if(element.lock)
                    return;
                this.$emit('CloneElement', element);
            },
            deleteButtonLabel(view) {
                if(view.properties.deActivatable)
                    return this.$gettext("Deactivate");
                else return this.$gettext("Delete");
            },
            deleteElement(index) {
                let self = this;
                this.$root.showDialogBox(this.$gettext("Are you sure you want to delete item: {0}?").format(this.filteredElements[index].properties.name), null, "Yes", function () {
                    self.deleteAndReload(index);
                }, "No", null);
            },
            deleteAndReload(index) {
                let self = this;
                let itemName = this.filteredElements[index].properties.name;
                let itemType = this.$dynamicElements.getItemCategory(this.filteredElements[index].properties.type);
                this.$dynamicElements.delete(itemName, itemType)
                    .then(() => {
                        const findIndex = self.elements.findIndex(v => v.properties.name === itemName);
                        self.elements.removeAt(findIndex);
                    })
            },
            loadItems() {
                this.elements.clear();
                let self = this;
                //AT moment widgets and queries are managed together in a single view
                if (this.type === "widgets" || this.type === "queries")
                    this.type = "widgets";
                this.$dynamicElements.LoadItems(self.type, false, false, true)
                    .then(result => {
                        result.forEach(item => { item.type = self.type });
                        Mutex.areItemsLocked(self.type, result.map(item => item.properties.name))
                            .then(locks => {
                                if(Array.isUseful(locks) && locks.length === result.length) {
                                    for(let i = 0 ; i < locks.length ; i++)
                                        result[i].lock = locks[i];
                                    self.elements = result;
                                    //AT moment elements and queries are managed together in a single view
                                    if (self.type === "widgets") {
                                        this.$dynamicElements.LoadItems("queries", false, false, true)
                                            .then(result => {
                                                result.forEach(item => { item.type = "queries" });
                                                Mutex.areItemsLocked("queries", result.map(item => item.properties.name))
                                                    .then(locks => {
                                                        if(Array.isUseful(locks) && locks.length === result.length) {
                                                            for (let i = 0; i < locks.length; i++)
                                                                result[i].lock = locks[i];
                                                        }
                                                        result.forEach(item => {
                                                            self.elements.push(item);
                                                        });
                                                        self.elements.forEach(item => {
                                                            self.types.push(item.properties.type);
                                                            //TODO remove if statement when all elements have default tag property
                                                            if (item.properties.tags !== undefined) {
                                                                item.properties.tags.forEach(item => {
                                                                    if (item != null && item !== "" && self.tagFilters.indexOf(item) === -1)
                                                                        self.tagFilters.push(item);
                                                                })
                                                            }
                                                        })
                                                        self.typesWithNames = self.typesWithNames.filter(item => self.types.includes(item.type));
                                                        this.$emit('TypesAndTagsLoaded', { types: self.typesWithNames, tags: self.tagFilters })
                                                    });
                                            })
                                    } else {
                                        self.elements.forEach(item => {
                                            self.types.push(item.properties.type);
                                            //TODO remove if statement when all elements have default tag property
                                            if (item.properties.tags !== undefined) {
                                                item.properties.tags.forEach(item => {
                                                    if (item != null && item !== "" && self.tagFilters.indexOf(item) === -1)
                                                        self.tagFilters.push(item);
                                                })
                                            }
                                        })
                                        self.typesWithNames = self.typesWithNames.filter(item => self.types.includes(item.type));
                                        this.$emit('TypesAndTagsLoaded', { types: self.typesWithNames, tags: self.tagFilters })
                                    }
                                }
                            })
                    })
                    .catch(error => {
                        debugger
                        console.log(error);
                        this.$root.showErrorNotification(this.$gettext("Error in retrieving saved elements from DB."), true);
                    })
                    .finally(() => {
                        self.$root.setLoading(false)
                    })

            },
            async getRunningRule() {
                this.runningRules = await RulesEngine.getRunningRules();
            },

            checkIsRunningRule(name) {
                return this.runningRules.includes(name);
            },
            //checks the matching between the version of the deployed rule and its descriptor
            checkDescriptionVersion(descriptor) {
                return descriptor.version === ((descriptor.deployedVersion) ? descriptor.deployedVersion : -1);
            },
            itemStatus(item) {
                //TODO clean-up based on DynamicElementBase deploying framework
                if(this.type === 'rules') {
                    if (this.checkIsRunningRule(item.properties.name)) {
                        if (this.checkDescriptionVersion(item))
                            return {text: this.$gettext("This rule is running"), color: "var(--av-lightblue)", active: true};
                        else return {
                            text: this.$gettext("This rule is running with a different configuration"), color: "var(--av-orange)", active: true
                        };
                    } else return {text: this.$gettext("This rule is not running"), color: "", active: false}
                } else {
                    if(!this.$dynamicElements.isItemDeployed(item))
                        return {text: this.$gettext("Draft"), color: ""};
                    return {text: this.$gettext("Item is active"), color: "var(--av-lightblue)"};
                }
            },
            getType(element) {
                if (this.typesWithNames.find(item => item.type === element.properties.type))
                    return this.typesWithNames.find(item => item.type === element.properties.type).name;
                return ""
            }
        },
        mounted: function () {
            this.$root.setLoading(true, "");
            this.loadItems();
            if (this.type === "rules")
                this.getRunningRule();
            Object.keys(this.$dynamicElements.Types).forEach(e => this.typesWithNames.push({name: e, type: this.$dynamicElements.Types[e]}));
        },
        computed: {
            filteredElements() {
                if(!Array.isUseful(this.elements))
                    return this.elements;

                let duplicateElements = this.elements.map(item => item.properties.name).findDuplicates();
                if(Array.isUseful(duplicateElements)) {
                    this.$emit("error", this.$gettext("Duplicate element names found: {0}. This may happen in case items were manually edited before importing. Filters will be disabled. Please rename duplicate items").format(duplicateElements.join(",")));
                    return this.elements;
                }

                let filtered = this.elements;
                //filter recipes grants
                if (this.type === 'recipes') {
                    if (!this.$grants.get().recipes.canEditTemplateRecipes){
                        filtered = filtered.filter(item => item.properties.type !== 5000);
                    }
                    if (!this.$grants.get().recipes.canEditLineRecipes){
                        filtered = filtered.filter(item => item.properties.type !== 5002);
                    }
                    if (!this.$grants.get().recipes.canEditMachineRecipes){
                        filtered = filtered.filter(item => item.properties.type !== 5001);
                    }
                }
                if(this.filterByName){
                    filtered = filtered.filter((element)=>{
                        return this.filterByName.toLowerCase().split(' ').every(v => element.properties.name.toLowerCase().includes(v))
                    })
                }
                if (this.statusSelected.length === 1 && this.statusSelected[0] === "Active")
                    filtered = filtered.filter(item => this.$dynamicElements.isItemDeployed(item));
                if (this.statusSelected.length === 1 && this.statusSelected[0] === "Draft")
                    filtered = filtered.filter(item => !this.$dynamicElements.isItemDeployed(item));
                if (this.typeSelected !== 'All') {
                    let type = this.typesWithNames.filter(item => item.name === this.typeSelected);
                    filtered = filtered.filter(item => item.properties.type === type[0].type)
                }
                if (this.tagSelected !== 'All')
                    filtered = filtered.filter(item => item.properties.tags && item.properties.tags.includes(this.tagSelected))

                return filtered;
            }
        }
    }
</script>
