<template>
    <v-select
            v-on="$listeners"
            :items="filtering?filtered:(manageEveryone?cItems.filter(i=>{return i.key!=='all'}):cItems)"
            :value="cValue"
            ref="multiselect"
            :label="label"
            @input="update($event)"
            :placeholder="cPlaceholder"
            :item-text="itemText"
            chips
            :return-object="returnObject"
            :item-value="cItemValue"
            multiple
            v-on:blur="onBlur"
            v-bind="$attrs"
    >
        <template v-slot:prepend-item>
            <v-list-tile ripple @click="update(everyoneItemToSave)" v-if="manageEveryone">
                <v-list-tile-action>
                    <v-icon :color="colorEveryone">{{ iconEveryone }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                    <v-list-tile-title>{{ everyOneItem.length>0?everyOneItem[0][itemText]:'' }}</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>
            <v-list-tile ripple @click="toggle">
                <v-list-tile-action>
                    <v-icon :color="color">{{ icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                    <v-list-tile-title>{{ lblSelect }}</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>
            <v-divider class="mt-2"></v-divider>
            <v-list-tile ripple v-if="filterable">
                <v-list-tile-action>
                    <v-icon >fa fa-search</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                        <v-text-field solo dense flat class="osk" placeholder="Search" v-model="search"
                                      hide-details clearable style="width: 100%;" ></v-text-field>
                </v-list-tile-content>
            </v-list-tile>
            <v-divider class="mt-2" v-if="filterable"></v-divider>
        </template>
    </v-select>
</template>

<script>
    export default {
        name: "av-multiselect",
        props: {
            value: {
                default: () => {
                    return []
                }
            },
            items:{
                default:()=>{
                    return []
                }
            },
            placeholder: {
                default:()=>{
                    return ""
                }
            },
            itemText: {
                required:true,
                default:()=>{
                    return ""
                }
            },
            itemValue:{
                required:false,
                default:()=>{
                    return ""
                }
            },
            itemSearch:{
                required:false,
                default:()=>{
                    return ""
                }
            },
            filterable:{
                required:false,
                default:()=>{
                    return false
                }
            },
            label: {
                required:true,
                default:()=>{
                    return ""
                }
            },
            manageEveryone:{
                default:()=>{
                    return false
                }
            },
            everyoneText:{
                default:()=>{
                    return "Everyone"
                }
            },
            returnObject:{
                default:()=>{
                    return true
                }
            },
            keepSelected: {
                required:false,
                default: () => {
                    return []
                }
            }
        },
        data:()=>{
            return {
                search:"",
                filtered:[],
                filtering:false,
                searchItem:"",
                values:[]
            }
        },
        mounted() {
            this.wrapValues()
            this.searchItem = this.itemSearch;
            if (this.filterable && this.itemSearch === "") {
                this.searchItem = this.itemText;
            }

            //if keepselected defined disable items
            if (this.keepSelected){
                let label=this.itemText?this.itemText:"show";
                let value=this.itemValue?this.itemValue:"key"
                let cks = this.$refs.multiselect.content.querySelectorAll("div[role='listitem']");
                for (let c = 0; c < cks.length; c++) {
                    let title=cks[c].querySelector("div[class='v-list__tile__title']")
                    //disable keepselected items
                    if (this.items.find(i=>{return i[label]===title.innerText}) && this.keepSelected.includes(this.items.find(i=>{return i[label]===title.innerText})[value])){
                        cks[c].style["pointer-events"]="none";
                        cks[c].style["opacity"]="0.5";
                    }
                }
            }

        },
        computed: {
            allSelected() {
                let diff=this.manageEveryone?1:0;
                return this.cValue && this.cValue.length - diff === this.items.length - diff
            },
            someSelected() {
                let diff=this.manageEveryone?1:0;
                return this.cValue && this.cValue.length - diff > 0 && !this.allSelected
            },
            color(){
                let diff=this.manageEveryone?1:0;
                return this.cValue.length - diff> 0 ? 'primary' : ''
            },
            colorEveryone(){
                return this.cValue.find(v=>{return v.key==='all'}) ? 'primary' : ''
            },
            icon() {
                if (this.allSelected) return 'fas fa-times'
                if (this.someSelected) return 'far fa-minus-square'
                return 'far fa-square'
            },
            lblSelect(){
                return this.allSelected ? this.$gettext("Unselect all") : this.$gettext("Select all")
            },
            cItemValue(){
                return this.itemValue!==""?this.itemValue:"key"
            },
            cItems(){
                if (this.manageEveryone){
                    let everyOneSelected={}
                    everyOneSelected[this.cItemValue]="all"
                    everyOneSelected[this.itemText]=this.everyoneText
                    return [everyOneSelected,...this.items]
                }
                return this.items
            },
            cValue(){
                return this.values
            },
            everyOneItem(){
                return this.cItems.filter(i=>{return i[this.cItemValue]==='all'}).length>0?this.cItems.filter(i=>{return i[this.cItemValue]==='all'}):[]
            },
            everyoneItemToSave(){
                return this.values.filter(i=>{return i[this.cItemValue]==='all'}).length>0?[]:this.cItems.filter(i=>{return i[this.cItemValue]==='all'})
            },
            iconEveryone() {
                if (this.values.find(v=>{return v[this.cItemValue]==="all"})) return 'fas fa-check-square'
                return 'far fa-square'
            },
            cPlaceholder(){
                return this.cValue.find(v=>{return v[this.cItemValue]==='all'}) ? this.cValue.find(v=>{return v[this.cItemValue]==='all'})[this.itemText]:this.placeholder
            }
        },
        methods: {
            toggle() {
                this.$nextTick(() => {
                    //replicate click of checkbox element of multiselect
                    let value=this.itemValue?this.itemValue:"key"
                    let keepSelectedValues=this.items.filter(i=>{return this.keepSelected.includes(i[value])})
                    this.values=this.allSelected?keepSelectedValues:this.cItems.filter(i=>{return i.key!=="all"})

                    this.emitValues()
                    // let cks = this.$refs.multiselect.content.querySelectorAll("input[role='checkbox']");
                    // let indexToStart=this.manageEveryone?1:0;
                    // for (let c = indexToStart; c < cks.length; c++) {
                    //     let title=cks[c].querySelector("div[class='v-list__tile__title']")
                    //     if (title.innerText!==this.everyoneText) {
                    //         if ((!this.allSelected && !cks[c].checked) || (this.allSelected))
                    //             cks[c].click()
                    //     }
                    // }
                })
            },
            async update(returning) {
                //TODO this is managing the case where datamodel changed over time so that old values are not
                //available anymore in the options. Check whether this is the correct place to handle and if it would need an option
                //let returning = this.$refs.multiselect.value;
                let wrap= new Promise(resolve=>{
                    if(this.returnObject){
                        for(let index = 0 ; index < returning.length ; index++) {
                            if(this.cItems.findItem(returning[index]) < 0) {
                                returning.removeAt(index);
                                index--;
                            }
                        }
                    }

                    let everyOneSelected=returning.find(r=>{return r[this.cItemValue]==="all"})
                    if (everyOneSelected){
                        this.values=[everyOneSelected]
                        let cks = this.$refs.multiselect.content.querySelectorAll("div[role='listitem']");
                        this.enableCheckBoxSelection(false)
                        this.emitValues()
                        return
                    }else{
                        //add keepSelected to values
                        let value=this.itemValue?this.itemValue:"key"
                        let items=this.items.filter(i=>{return this.keepSelected.includes(i[value])}).map(i=>{ return i[value] })
                        if(items.length>0) {
                            items.forEach(i => {
                                if (!returning.find(r => {
                                    return r === i
                                })) {
                                    returning.push(i)
                                }
                            })

                        }

                    }
                    resolve(true)
                })
                wrap.then(()=>{
                    this.enableCheckBoxSelection(true)

                    this.values=returning
                    this.emitValues();
                })

            },
            emitValues(){
                let valuesToEmit=[]
                if(this.returnObject){
                    valuesToEmit=this.values
                }else{
                    if(this.values.length>0 && typeof this.values[0] === 'object'){
                        valuesToEmit= this.values.map(v=>{return v[this.cItemValue]})
                    }else{
                        valuesToEmit = this.values;
                    }

                }

                this.$emit('input', valuesToEmit);
                this.$emit('change',valuesToEmit)
            },
            enableCheckBoxSelection(enabled){
                let label=this.itemText?this.itemText:"show";
                let value=this.itemValue?this.itemValue:"key"
                let cks = this.$refs.multiselect.content.querySelectorAll("div[role='listitem']");
                let self=this;
                for (let c = 0; c < cks.length; c++) {
                    let title=cks[c].querySelector("div[class='v-list__tile__title']")
                    let item=this.items.find(i=>{return i[label]===title.innerText})
                    let condition=self.keepSelected.length>0?(item && !self.keepSelected.includes(item[value])):true
                    if (title.innerText!==this.everyoneText && condition){
                        cks[c].style["pointer-events"]=enabled?"":"none";
                        cks[c].style["opacity"]=enabled?"":"0.5";
                    }
                }
            },
            onBlur() {
                this.search = "";
                this.filtered = [];
            },
            onClearSearch() {
                this.search = "";
            },
            wrapValues(){
                if (!Array.isUseful(this.values)  && Array.isUseful(this.value) ){
                    for (let j=0;j<this.value.length;j++){
                        let v=this.value[j]
                        if (typeof v !== 'object'){
                            let self=this
                            if (self.cItems.length > 0 && typeof self.cItems[0] === 'object'){
                                this.values.push(self.cItems.find(i=>{return i[self.cItemValue]===v}))
                            }else{
                                this.values.push(v)
                            }

                        }else{
                            this.values.push(v)
                        }
                    }
                    if (this.values.length>0 && this.values[0].key==="all"){
                        this.enableCheckBoxSelection(false)
                    }
                }
            }
        },
        watch: {
            search() {
                if (this.search && this.search.length >= 3) {
                    this.filtering = true
                    this.filtered = this.items.filter(i => {
                        return i[this.searchItem].toLowerCase().includes(this.search.toLowerCase())
                    })
                } else {
                    this.filtered = [];
                    this.filtering = false;
                }
            },
            value(){
                this.wrapValues()
            }
        }
    }
</script>

<style scoped>
div.v-text-field__details{
    display: none !important;
}
</style>