import Vue from 'vue';

let dateTimeDiffInSeconds = function (dateA, dateB) {
    return parseInt((dateB-dateA)/1000)
}

let clearHandledTokens = function () {
    let retval = []
    for (let i = 0; i < Vue.prototype.$store.state.commonStore.handedWebSocketTokens.length; i++) {
        if (dateTimeDiffInSeconds(Vue.prototype.$store.state.commonStore.handedWebSocketTokens[i].Date, new Date()) > 5) {
            // if the websocket request has been handler since 5secs remove it. this way is used to avoid to manage more than one time the same notification
            continue
        }
        retval.push(Vue.prototype.$store.state.commonStore.handedWebSocketTokens[i])
    }
    Vue.prototype.$store.state.commonStore.handedWebSocketTokens = retval
}

let uuidAlreadyHandled = function (uuid) {
    for (let i = 0; i < Vue.prototype.$store.state.commonStore.handedWebSocketTokens.length; i++) {
        if (Vue.prototype.$store.state.commonStore.handedWebSocketTokens[i].UUID === uuid) {
            return true
        }
    }
    return false
}

let addUUIDToHandledRequest = function (uuid) {
    Vue.prototype.$store.state.commonStore.handedWebSocketTokens.push({ UUID: uuid, Date: new Date() })
}

export default {
    state: {
        pageReady: false,
        handedWebSocketTokens: [],
        baseURL: '',
    },
    getters: {
        shared (state) {
            return Vue.prototype.$store.state.shared
        },
        selectedLanguage (state) {
            if (Vue.prototype.$store.state.shared.preferences === undefined || Vue.prototype.$store.state.shared.preferences === null) {
                return 'en_GB'
            }
            for (let i = 0; i < Vue.prototype.$store.state.shared.preferences.length; i++) {
                if (Vue.prototype.$store.state.shared.preferences[i].ParameterName.toLowerCase() !== 'language') {
                    continue
                }
                return Vue.prototype.$store.state.shared.preferences[i].ParameterValue
            }
            return 'en_GB'
        },
        printerDisabled (state) {
            if (Vue.prototype.$store.state.shared.settings.DisablePrinters === undefined) {
                return false
            }
            return Vue.prototype.$store.state.shared.settings.DisablePrinters === 'true'
        },
        customerLogo (state) {
            if (Vue.prototype.$store.state.shared.settings.CustomerLogo !== undefined) {
                return Vue.prototype.$store.state.shared.settings.CustomerLogo
            } else {
                return ''
            }
        },
        useOrchestratorAsProxy (state) {
            if (Vue.prototype.$store.state.shared.settings.UseOrchestratorAsProxy !== undefined) {
                return Vue.prototype.$store.state.shared.settings.UseOrchestratorAsProxy === 'true'
            } else {
                return true
            }
        },
        title (state) {
            if (Vue.prototype.$store.state.shared.settings.Title !== undefined) {
                return Vue.prototype.$store.state.shared.settings.Title
            } else {
                return 'AntaresVision'
            }
        },
        applicationName (state) {
            if (Vue.prototype.$store.state.shared.settings.ApplicationName !== undefined) {
                return Vue.prototype.$store.state.shared.settings.ApplicationName
            } else {
                return 'AntaresVision'
            }
        },
        instanceName (state) {
            if (Vue.prototype.$store.state.shared.settings.InstanceName !== undefined) {
                return Vue.prototype.$store.state.shared.settings.InstanceName
            } else {
                return 'AntaresVision'
            }
        },
        useExternalReportGenerator (state) {
            if (Vue.prototype.$store.state.shared.settings.UseExternalReportServer !== undefined) {
                return Vue.prototype.$store.state.shared.settings.UseExternalReportServer === 'true'
            } else {
                return false
            }
        },
        externalReportGeneratorServerConfigured (state) {
            if (Vue.prototype.$store.state.shared.settings.ExternalReportServer !== undefined) {
                return Vue.prototype.$store.state.shared.settings.ExternalReportServer !== ''
            } else {
                return false
            }
        }
    },
    mutations: {
        SOCKET_ONMESSAGE (state, message) {
            if (uuidAlreadyHandled(message.UUID)) {
                clearHandledTokens()
                return
            }
            addUUIDToHandledRequest(message.UUID)
            if (Vue.prototype.$store.state.webSocketHooks[message.Scope.toLowerCase()] === undefined) {
                clearHandledTokens()
                return
            }
            if (message.Message !== '') {
                message.Message = JSON.parse(decodeURIComponent(escape(atob(message.Message).replace(/\\/g, "\\\\"))))
            }
            Vue.prototype.$store.state.webSocketHooks[message.Scope.toLowerCase()](message)
            clearHandledTokens()
        },
        SOCKET_ONOPEN (state) {
            Vue.prototype.$store.state.socket.status = true
            Vue.prototype.$store.state.socket.message = ''
        },
        SOCKET_ONCLOSE (state) {
            Vue.prototype.$store.state.socket.status = false
            Vue.prototype.$store.state.socket.message = 'Closed'
        },
        SOCKET_ONERROR (state, event) {
            Vue.prototype.$store.state.socket.message = 'Error: ' + event
        },
        SOCKET_RECONNECT (state) {
            Vue.prototype.$store.state.socket.status = true
        },
        SOCKET_RECONNECT_ERROR (state) {
            Vue.prototype.$store.state.socket.status = false
        },
        setPageReady (state, readyStatus) {
            state.pageReady = readyStatus
        },
        setWebSocketHook (state, hook) {
            Vue.prototype.$store.state.webSocketHooks[hook.Scope.toLowerCase()] = hook.Function
        },
        setAlarmViewed (state, index) {
            let f = Vue.prototype.$store.state.webSocketHooks['raiseonrequesttostorechange']
            if (f !== undefined) {
                f('setAlarmViewed', index)
            }
        },
        setAlarmNotNew (state, index) {
            let f = Vue.prototype.$store.state.webSocketHooks['raiseonrequesttostorechange']
            if (f !== undefined) {
                f('setAlarmNotNew', index)
            }
        },
        addAlarm (state, alarm) {
            let f = Vue.prototype.$store.state.webSocketHooks['raiseonrequesttostorechange']
            if (f !== undefined) {
                f('addAlarm', alarm)
            }
        },
        setToken (state, newToken) {
            Vue.prototype.$store.state.shared.token = newToken
        },
        setUser (state, userName) {
            Vue.prototype.$store.state.shared.loggedUserName = userName
        },
        setGroups (state, groups) {
            Vue.prototype.$store.state.shared.groups = groups
        },
        setUserPreferences (state, preferences) {
            let f = Vue.prototype.$store.state.webSocketHooks['raiseonrequesttostorechange']
            if (f !== undefined) {
                f('setUserPreferences', preferences)
            }
        },
        updateSharedState (state, sharedState) {
            Vue.prototype.$store.state.shared = sharedState
        },
        registerIntervalID (state, intervalID) {
            Vue.prototype.$store.state.intervalIDList.push(intervalID)
        },
        registerPrivateIntervalID (state, intervalID) {
            Vue.prototype.$store.state.privateIntervalIDList.push(intervalID)
        },
        clearPrivateIntervalIDList (state) {
            for (let i = 0; i < Vue.prototype.$store.state.privateIntervalIDList.length; i++) {
                window.clearInterval(Vue.prototype.$store.state.privateIntervalIDList[i])
            }
            Vue.prototype.$store.state.privateIntervalIDList = []
        },
        clearIntervalIDList (state) {
            for (let i = 0; i < Vue.prototype.$store.state.intervalIDList.length; i++) {
                window.clearInterval(Vue.prototype.$store.state.intervalIDList[i])
            }
            Vue.prototype.$store.state.intervalIDList = []
        },
        setOptions (state, options) {
            let f = Vue.prototype.$store.state.webSocketHooks['raiseonrequesttostorechange']
            if (f !== undefined) {
                f('setOptions', options)
            }
        },
        setSettings (state, settings) {
            let f = Vue.prototype.$store.state.webSocketHooks['raiseonrequesttostorechange']
            if (f !== undefined) {
                f('setSettings', settings)
            }
        },
        setBaseURL (state, baseURL) {
            Vue.prototype.$store.state.commonStore.baseURL = baseURL
            console.log('baseURL set: ' + Vue.prototype.$store.state.commonStore.baseURL)
        }
    },
    actions: {
        setPageReady (context, readyState) {
            context.commit('setPageReady', readyState)
        },
        addAlarm (context, alarm) {
            context.commit('addAlarm', alarm)
        },
        setAlarmViewed (context, index) {
            context.commit('setAlarmViewed', index)
        },
        setAlarmNotNew (context, index) {
            context.commit('setAlarmNotNew', index)
        },
        clearSession (context) {
            context.commit('setToken', '')
            context.commit('setUser', '')
            context.commit('setGroups', [])
        },
        setSession (context, session) {
            context.commit('setToken', session.token)
            context.commit('setUser', session.userName)
            context.commit('setGroups', session.groups)
        },
        saveModules (context, modules) {
            context.commit('saveModules', modules)
        },
        addToParamsMap (context, param) {
            context.commit('AddToParamsMap', param)
        },
        languages (context, languages) {
            context.commit('languages', languages)
        },
        selectedLanguage (context, newValue) {
            context.commit('selectedLanguage', newValue)
        },
        callingApi (context, newValue) {
            context.commit('callingApi', newValue)
        },
        setUserPreferences (context, preferences) {
            context.commit('setUserPreferences', preferences)
        },
        registerIntervalID (context, intervalID) {
            context.commit('registerIntervalID', intervalID)
        },
        registerPrivateIntervalID (context, intervalID) {
            context.commit('registerPrivateIntervalID', intervalID)
        },
        clearIntervalIDList (context) {
            context.commit('clearIntervalIDList')
        },
        clearPrivateIntervalIDList (context) {
            context.commit('clearPrivateIntervalIDList')
        },
        setOptions (context, options) {
            context.commit('setOptions', options)
        },
        setSettings (context, settings) {
            context.commit('setSettings', settings)
        },
        SetWebSocketHook (context, hook) {
            context.commit('setWebSocketHook', hook)
        },
        updateSharedState (context, sharedStaste) {
            context.commit('updateSharedState', sharedStaste)
        },
        setBaseURL (context, baseURL) {
            context.commit('setBaseURL', baseURL)
        }
    }

};