<template>
    <v-container v-if="this.$root.startedUp" id="ContentContainer" grid-list-md text-xs-center pa-3 ma-0
                 class="page-container" style="max-width: 100%; overflow: hidden">
        <v-card class="mt-0 av-card" elevation="0" style="height: 80px;">
            <v-layout row wrap justify-end px-2>
                <av-select class="mt-4 mx-2 control" :label="$gettext('Select workorder states to show')"
                           v-model="visualizationStates" :items="states"
                           multiple style="max-width: 350px"
                           @change="loadWorkorders">
                </av-select>
                <av-text-field class="mt-4 mx-2" style="max-width: 200px;" :label="$gettext('Filter by Id')"
                               v-model="filterById"></av-text-field>
                <av-select v-if="$config.isL3()" class="mt-4 mx-2" style="max-width: 150px"
                           :label="$gettext('Filter by line')" v-model="lineSelected"
                           :items="lineFilters"></av-select>
                <v-spacer></v-spacer>
                <v-btn v-if="showButtonNewWorkOrders" class="mt-4 mx-2" @click="newWorkorder" color="info">
                    <av-icon id="newWorkorderBtn" light small left>fa-plus</av-icon>
                    <translate>New Workorder</translate>
                </v-btn>
            </v-layout>
        </v-card>
        <v-card class="av-card" elevation="0"
                :style="'margin-top: 16px; overflow: auto; height:' + linesTableHeight + 'px'">
            <v-card-text class="av-card-content" style="overflow: hidden">
                <v-data-table v-if="!loading"
                              :headers="lineHeaders"
                              :items="lineStatus"
                              class="elevation-0 subheading fixed-header v-table__overflow"
                              ma-0 pa-0
                              item-key="Name"
                              hide-actions>
                    <template v-slot:items="props">
                        <tr style="height:65px;">
                            <td v-if="props.item.isSubline" class="text-xs-left subheading">
                                <av-icon class="mx-3 mt-2">fas fa-caret-square-right</av-icon>
                                <translate>Subline:</translate>
                                {{ props.item.Name }}
                            </td>
                            <td v-else class="text-xs-left subheading">
                                {{ props.item.Name }}
                            </td>
                            <td class="text-xs-left subheading">
                                {{ props.item.WorkflowStatus }}
                            </td>
                            <td class="text-xs-left subheading">
                                {{ props.item.Workorder }}
                            </td>
                            <td class="text-xs-left subheading"
                                :style="'opacity: {0}'.format(props.item.isSubline ? 0.5 : 1)"
                                v-if="!hideOperationMode">
                                {{ getOperationMode(props.item.OperationMode) }}
                                <av-button
                                    v-if="!isLineActive(props.item) && operationModeVisibility === 'show' && !props.item.isSubline && canChangeOperationMode"
                                    :disabled="hasPendingStatus" class="pa-0"
                                    flat v-ripple="false" size="35px" title="" buttonIcon="fa-cog"
                                    iconColor="blue" text=""
                                    @click="statusExpanded = false, props.expanded = !props.expanded, operationModeExpanded = props.expanded"/>
                            </td>
                            <td class="text-md-left text-xs-left subheading" :style="styleCell" v-if="$config.isL2()">
                                <v-layout v-if="!hasPendingStatus" justify-start align-center>
                                    <av-button v-if="props.item.actionsGroup && props.item.actionsGroup.children.length > 1" flat v-ripple="false"
                                               size="35px" :title="props.item.actionsGroup.text"
                                               :buttonIcon="props.item.actionsGroup.icon"
                                               :iconColor="props.item.actionsGroup.color"
                                               :text="props.item.actionsGroup.text"
                                               class="pa-0"
                                               @click="props.expanded = !props.expanded; operationModeExpanded = false; statusExpanded = props.expanded"/>
                                    <av-button v-if="props.item.actionsGroup && props.item.actionsGroup.handler" flat v-ripple="false" size="35px"
                                               :title="props.item.actionsGroup.text"
                                               :buttonIcon="props.item.actionsGroup.icon"
                                               :iconColor="props.item.actionsGroup.color"
                                               :text="props.item.actionsGroup.text"
                                               class="pa-0"
                                               @click="handleFunctionCall(props.item.actionsGroup.handler, props.item, props.item.actionsGroup.handlerInfo.flow)"/>
                                </v-layout>
                                <v-layout v-else justify-left align-center>
                                    <label> {{ pendingStatus }}</label>
                                    <img :src="loaderGif" style="width:40px; height:40px;"/>
                                </v-layout>
                            </td>
                            <td class="text-md-left text-xs-left" :style="styleCell" v-if="canResetLine">
                                <av-button v-if="$grants.get().workordersPage.canResetWorkorder"
                                           :disabled="hasPendingStatus" class="pa-0" flat v-ripple="false" size="35px"
                                           :title="'Reset {appName}'.format({appName: $config.appName})"
                                           buttonIcon="fa-retweet" iconColor="red" text=""
                                           @click="resetLine(props.item)"/>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:expand="props">
                        <v-card style="height: 130px">
                            <v-card-text v-if="operationModeExpanded">
                                <v-container fluid>
                                    <v-layout row wrap justify-center>
                                        <template v-for="(mode) in operationModes">
                                            <v-flex xs12 sm6 md3 order-md3 order-sm1>
                                                <v-btn flat v-ripple="false" :disabled="mode === operationMode"
                                                       @click="props.expanded = !props.expanded; operationModeExpanded = false; $root.showDialogBox($gettext('Are you sure you want to change operation mode to {0}?').format(mode), null, 'Yes', ()=>{saveOperationMode(mode)}, 'No', null)"
                                                       size="50px">
                                                    <v-layout column>
                                                        <av-icon color="blue" size="50">fa-cog</av-icon>
                                                        <label class="subheading font-weight-bold mt-2">
                                                            {{ mode }}
                                                        </label>
                                                    </v-layout>
                                                </v-btn>
                                            </v-flex>
                                        </template>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            <v-card-text v-if="statusExpanded && props.item.actionsGroup">
                                <v-container fluid>
                                    <v-layout row wrap justify-center>
                                        <template v-for="action in props.item.actionsGroup.children">
                                            <v-flex xs12 sm6 md3 order-md3 order-sm1>
                                                <v-btn flat v-ripple="false"
                                                       @click="props.expanded = !props.expanded; statusExpanded = false; handleFunctionCall(action.handler, props.item, action.handlerInfo.flow)"
                                                       size="50px">
                                                    <v-layout column>
                                                        <av-icon :color="action.color" size="50">{{ action.icon }}
                                                        </av-icon>
                                                        <label class="subheading font-weight-bold mt-2">
                                                            {{ action.text }}
                                                        </label>
                                                    </v-layout>
                                                </v-btn>
                                            </v-flex>
                                        </template>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-card class="mt-3 av-card" elevation="0"
                :style="'height: calc(100% - 112px - {0}px)'.format(linesTableHeight)">
            <v-card-text class="av-card-content" style="height: 100%">
                <v-data-table v-if="!loading"
                              :headers="workorderHeaders"
                              :items="filteredWorkorders"
                              :expand="lineTableExpanded"
                              class="elevation-0 subheading fixed-header v-table__overflow"
                              ma-0 pa-0
                              :custom-sort="customSort"
                              item-key="Id"
                              hide-actions>
                    <template v-slot:items="props">
                        <tr :class="itemRowBackground(props.item)" style="height: 65px">
                            <template v-for="item in workorderHeaders">
                                <td v-if="item.value === '_actions'" class="text-md-left text-xs-left"
                                    :style="styleCell">
                                    <av-button v-if="Array.isUseful(actionsGroup(props.item).children)" :text="''" flat
                                               v-ripple="false" size="35px" :title="actionsGroup(props.item).text" icon
                                               :buttonIcon="actionsGroup(props.item).icon" :iconColor="actionsGroup(props.item).color"
                                               @click="props.expanded = !props.expanded; actionsExpanded[props.index] = props.expanded;"/>
                                    <av-button v-else :text="''" flat v-ripple="false" size="35px"
                                               :title="actionsGroup(props.item).text" icon
                                               :buttonIcon="actionsGroup(props.item).icon"
                                               :iconColor="actionsGroup(props.item).color"
                                               @click="handleFunctionCall(actionsGroup(props.item).handler, props.item);"></av-button>
                                </td>
                                <td v-else-if="item.value === '_details'" class="text-md-left text-xs-left"
                                    :style="styleCell">
                                    <av-button v-if="!detailsGroupOneChild" :text="''" flat v-ripple="false" size="35px"
                                               :title="detailsGroup.text" icon
                                               :buttonIcon="detailsGroup.icon" :iconColor="detailsGroup.color"
                                               @click="props.expanded = !props.expanded; detailsExpanded[props.index] = props.expanded;"/>
                                    <av-button v-else :text="''" flat v-ripple="false" size="35px"
                                               :title="detailsGroupOneChild.text" icon
                                               :buttonIcon="detailsGroupOneChild.icon"
                                               :iconColor="detailsGroupOneChild.color"
                                               @click="handleFunctionCall(detailsGroupOneChild.handler, props.item);"></av-button>
                                </td>
                                <td v-else class="text-xs-left subheading">
                                    {{ props.item[item.value] }}
                                </td>
                            </template>
                        </tr>
                    </template>
                    <template v-slot:expand="props">
                        <v-card>
                            <v-card-text v-if="detailsExpanded[props.index]">
                                <v-container fluid>
                                    <v-layout row wrap justify-center>
                                        <template v-for="action in detailsGroup.children">
                                            <v-flex xs12 sm6 md3 order-md3 order-sm1>
                                                <v-btn flat v-ripple="false"
                                                       @click="handleFunctionCall(action.handler, props.item); props.expanded = false; detailsExpanded = {}"
                                                       size="50px">
                                                    <v-layout column>
                                                        <av-icon :color="action.color" size="50">{{ action.icon }}
                                                        </av-icon>
                                                        <label
                                                            class="subheading font-weight-bold mt-2">{{
                                                                action.text
                                                            }}</label>
                                                    </v-layout>
                                                </v-btn>
                                            </v-flex>
                                        </template>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                            <v-card-text v-else-if="actionsExpanded[props.index]">
                                <v-container fluid>
                                    <v-layout row wrap justify-center>
                                        <template v-for="action in actionsGroup(props.item).children">
                                            <v-flex xs12 sm6 md3 order-md3 order-sm1>
                                                <v-btn flat v-ripple="false"
                                                       @click="handleFunctionCall(action.handler, props.item); props.expanded = false; actionsExpanded = {}"
                                                       size="50px">
                                                    <v-layout column>
                                                        <av-icon :color="action.color" size="50">{{ action.icon }}
                                                        </av-icon>
                                                        <label
                                                            class="subheading font-weight-bold mt-2">{{
                                                                action.text
                                                            }}</label>
                                                    </v-layout>
                                                </v-btn>
                                            </v-flex>
                                        </template>
                                    </v-layout>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="selectLineDialog" width="720px" height="350px" :fullscreen="$vuetify.smAndDown"
                  :persistent="true" id="line-selection-dialog">
            <v-card id="line-selection-dialog-card">
                <v-toolbar card dense>
                    <v-toolbar-title class="headline text-xs-center">
                        <translate>Select line to assign workorder</translate>
                    </v-toolbar-title>
                </v-toolbar>
                <v-layout column pa-3>
                    <v-spacer/>
                    <v-spacer/>
                    <v-select :items="assignableLines" v-model="selectedLineToAssign"
                              :label="$gettext('Select a subline to start new workorder')"/>
                    <v-card-actions id="antares-annotation-dialog-card-actions">
                        <v-spacer></v-spacer>
                        <v-btn id="antares-annotation-dialog-card-actions-yes" color="green darken-1" flat="flat"
                               @click="selectLine(true)" v-translate>
                            OK
                        </v-btn>
                        <v-btn id="antares-annotation-dialog-card-actions-no" color="red darken-1" flat="flat"
                               @click="selectLine(false)" v-translate>
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-layout>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>

import AvProgress from '@/components/av-components/av-progress'
import Translations from "../Translations";
import OperationModes from "@/views/configuration/OperationModes";

export default {
    name: "WorkordersPage",
    components: {Translations, AvProgress},
    data() {
        return {
            workflow: null,
            workorderForms: [],
            workorders: [],
            mapping: [],
            dataPatterns: [],
            lineFilters: [],
            lineSelected: "All",
            lineStatus: [],
            filterById: "",
            loading: false,
            interval: null,

            pendingStatus: "",

            //TODO clean-up operation modes management
            operationModes: [],
            operationMode: "",
            operationModesValues: null,
            currentOperationMode: null,
            operationModeVisibility: "",
            initialStateOperationMode: 0, //with 0 the state is: no operation mode, with 1 there is an operation mode

            sublines: {},
            selectedLineToAssign: "",
            selectedWorkorderToAssign: null,
            selectLineDialog: false,

            visualizationProfile: {},

            lineTableExpanded: true,
            operationModeExpanded: false,
            statusExpanded: false,
            detailsExpanded: {},
            actionsExpanded: {},
            icons: {
                start: {
                    icon: 'fa-play',
                    color: 'blue'
                },
                abort: {
                    icon: 'fa-ban',
                    color: 'red'
                },
                pause: {
                    icon: 'fa-pause',
                    color: 'blue'
                },
                complete: {
                    icon: 'fa-check',
                    color: 'blue'
                },
                reset: {
                    icon: 'fa-repeat',
                    color: 'yellow'
                },
                stop: {
                    icon: 'fa-stop',
                    color: 'blue'
                },
            },
            states: [this.$workorders.WorkorderStatus.Planned, this.$workorders.WorkorderStatus.Running, this.$workorders.WorkorderStatus.Suspended, this.$workorders.WorkorderStatus.Completed, this.$workorders.WorkorderStatus.Aborted, this.$workorders.WorkorderStatus.Rejected],
            visualizationStates: [this.$workorders.WorkorderStatus.Running, this.$workorders.WorkorderStatus.Planned, this.$workorders.WorkorderStatus.Suspended]
        }
    },

    async mounted() {
        this.loading = true;
        this.$root.setLoading(this.loading, "");
        try {
            this.$root.setCurrentPageTitle(this.$gettext("Workorders"));
            await this.loadWorkflow();
            await this.loadVisualizationProfile();
            if (this.$config.isL2()) {
                await this.loadCurrentOperationMode();
                await this.loadOperationModes();
                await this.loadWorkOrderForms();
                await this.loadSublines();
                let physicalLineId = await this.$settings.getLineSettings().physicalLineId;
                await this.getLineInfo(physicalLineId)
            } else if (this.$config.isL3()) {
                this.dataPatterns.push(this.$defines.getDataPatternDescriptor(["production", "counters"], true));
                await this.getLinesInfoL3(true);
            }
            await this.loadWorkorders();
            this.interval = setInterval(async () => {
                this.loadWorkorders();
            }, 5000)
        } finally {
            this.loading = false;
            this.$root.setLoading(this.loading, "");
        }
    },
    beforeDestroy() {
        clearInterval(this.interval);
        this.interval = null;
    },
    computed: {
        hasPendingStatus() {
            return (this.pendingStatus !== "");
        },
        workorderHeaders() {
            let headers = [];
            if (this.visualizationProfile && this.visualizationProfile.selectedDataItems) {
                for (let item of this.visualizationProfile.selectedDataItems) {
                    headers.push({
                        text: item.text,
                        class: "subheading",
                        value: ((item.root ? item.root + "." : "") + item.name)
                    })
                }
                if (this.$config.isL3()) {
                    headers.unshift({
                        text: "Line",
                        class: "subheading",
                        value: "PhysicalLineId"
                    });
                } else {
                    headers.push({
                        text: this.$gettext("Actions"),
                        class: "subheading",
                        value: '_actions'
                    })
                }
                headers.push({
                    text: this.$gettext("Details"),
                    class: "subheading",
                    value: '_details'
                })
            }


            return headers;
        },
        lineHeaders() {
            let headers = [
                {
                    text: this.$gettext("Line Id"),
                    class: "subheading",
                    value: "LineId"
                },
                {
                    text: this.$gettext("Line Status"),
                    class: "subheading",
                    value: "LineStatus"
                },
                {
                    text: this.$gettext("Workorder"),
                    class: "subheading",
                    value: 'Workorder'
                },
                {
                    text: this.$gettext("Operation Mode"),
                    class: "subheading",
                    value: 'Operation Mode'
                },
                {
                    text: this.$gettext("Operation"),
                    class: "subheading",
                    value: 'Operation'
                }
            ];
            if (this.canResetLine)
                headers.push({
                    text: this.$gettext("Reset {appName}").format({appName: this.$config.appName}),
                    class: "subheading",
                    value: 'Reset'
                });
            if (this.hideOperationMode)
                headers = headers.filter(header => header.value !== "Operation Mode")
            if (this.$config.isL3())
                headers = headers.filter(header => header.value !== "Operation")


            return headers;
        },
        assignableLines() {
            return this.lineStatus.filter(line => line.IsIdle && !line.Workorder)
        },
        canResetLine() {
            return (Array.isUseful(this.lineStatus) && (!this.lineStatus[0].IsIdle || this.lineStatus[0].Workorder) && this.$config.isL2())
        },
        currentOperationModeSelected() {
            let operationMode = this.operationMode;
            if (operationMode === "AvDefaultOperationMode" || this.initialStateOperationMode === 0) {
                operationMode = this.$gettext("No operation mode");
                if (Array.isUseful(this.operationModes))
                    this.operationModeVisibility = "show";
                else
                    this.operationModeVisibility = "hidden";
            } else {
                if (!Array.isUseful(this.operationModes))
                    this.operationModeVisibility = "readOnly";
                else if (Array.isUseful(this.operationModes) && this.operationModes.length === 1)
                    if (operationMode === this.operationModes[0])
                        this.operationModeVisibility = "readOnly";
                    else
                        this.operationModeVisibility = "show";
                else
                    this.operationModeVisibility = "show";
            }
            return operationMode;
        },

        showButtonNewWorkOrders() {
            return Array.isUseful(this.workorderForms) && this.currentOperationMode.workordersCreationInAVionics;
        },

        loaderGif() {
            return require('@/assets/avloader.png')
        },

        filteredWorkorders() {
            let filtered = this.workorders;
            if (this.filterById) {
                filtered = filtered.filter((wo) => {
                    return this.filterById.toLowerCase().split(' ').every(v => wo.Id.toLowerCase().includes(v));
                })
            }
            if (this.lineSelected !== 'All') {
                filtered = filtered.filter(wo => wo.PhysicalLineId.includes(this.lineSelected));
            }
            return filtered;
        },

        linesTableHeight() {
            let height = this.lineStatus.length * 65 + 120;
            if (height > 400) {
                height = 400;
            }
            if (this.operationModeExpanded || this.statusExpanded) {
                return 130 + height
            } else {
                return height
            }
        },
        hideOperationMode() {
            if (this.operationModeVisibility === "hidden")
                return true
            return this.$config.isL3() && !this.lineStatus.some(line => line.OperationMode !== 'AvDefaultOperationMode');
        },
        detailsGroup() {
            let detailsGroup = {
                icon: 'fa-list',
                text: '',
                color: 'blue',
                grants: null,
                handler: null,
                children: [
                    {
                        icon: 'fa-pencil',
                        text: this.$gettext('Edit'),
                        color: 'blue',
                        grants: null,
                        handler: 'editWorkorderFields',
                    },
                    {
                        icon: 'fa-list',
                        text: this.$gettext('View details'),
                        color: 'blue',
                        grants: null,
                        handler: 'workorderDetails',
                    }
                ]
            }

            if (this.$config.isL3())
                detailsGroup.children = detailsGroup.children.filter(detailGroup => detailGroup.handler !== 'editWorkorderFields');

            return detailsGroup;
        },
        detailsGroupOneChild() {
            return this.detailsGroup.children.length > 1 ? null : this.detailsGroup.children[0];
        },
        actionsGroup() {
            return workorder => {
                let actionsGroup = {
                    icon: 'fa-sign-in-alt',
                    text: '',
                    color: 'blue',
                    grants: null,
                    handler: null,
                    children: [],
                }
                if (!this.$config.isL3()) {
                    if (Array.isUseful(this.assignableLines) && ['planned', 'suspended', 'running'].includes(workorder.StatusString)){
                        actionsGroup.children.push({
                            icon: 'fa-sign-in-alt',
                            text: this.$gettext('Assign to Line'),
                            color: 'blue',
                            grants: null,
                            handler: 'assignWorkorder',
                        })
                    }

                    if (workorder.StatusString=='planned' && this.hasRejectGrants) {
                        actionsGroup.children.push({
                            icon: 'fa-eject',
                            text: this.$gettext('Reject Workorder'),
                            color: 'blue',
                            grants: '',
                            handler: 'cancelWorkorder',
                        })
                    }
                    if (workorder.StatusString=='rejected' && this.hasRejectGrants){
                        actionsGroup.children.push({
                            icon: 'fa-recycle',
                            text: this.$gettext('Restore Workorder'),
                            color: 'blue',
                            grants: '',
                            handler: 'restoreWorkorder',
                        })
                    }
                    if (actionsGroup.children.length == 1) {
                        actionsGroup.icon = actionsGroup.children[0].icon
                        actionsGroup.text = actionsGroup.children[0].text
                        actionsGroup.color =actionsGroup.children[0].color
                        actionsGroup.grants = actionsGroup.children[0].grants
                        actionsGroup.handler = actionsGroup.children[0].handler
                        actionsGroup.children.pop()
                    }
                }
                return actionsGroup;
            }
        },
        canChangeOperationMode(){
            return this.$grants.get() && this.$grants.get().workordersPage.canSelectOperationMode
        },
        hasRejectGrants(){
            let ggrants = this.$grants.get();
            debugger
            //return this.$grants.workordersPage.canResetWorkorder
            return ggrants && ggrants.workordersPage.canResetWorkorder
        },
    },
    methods: {
        getOperationMode(operationMode) {
            if (this.$config.isL3())
                return operationMode !== 'AvDefaultOperationMode' ? operationMode : '';
            if (this.$config.isL2())
                return this.currentOperationModeSelected;
        },
        async getLinesInfoL3(forceReload = false) {
            let self = this;

            if (forceReload)
                this.mapping = await this.$datalayer.loadDataDefinitions(null, true);
            else
                this.mapping = await this.$datalayer.loadDataDefinitions();

            let indices = self.$datalayer.selectIndexesByPatterns(self.mapping, self.dataPatterns).multiSelect.map((x) => x.index);

            let dataItems = [];
            indices.forEach(index => {
                dataItems.push({
                    index: index,
                    root: "",
                    name: 'Workflow',
                    representations: [{
                        type: this.$defines.allAggregations.last.id,
                        aggregationWindow: 0,
                        target: index.split('~|~')[2],
                        filters: []
                    }]
                });
            });
            let queryDescriptor = self.$datalayer.getDataQueryDescriptor(dataItems, []);
            self.$datalayer.getDataBlob(queryDescriptor, self.$dateTime.getRfc3339TimeStamp(new Date(Date.now() - 60000)), self.$dateTime.getRfc3339TimeStamp(new Date()))
                .then(result => {
                    let data = self.$datalayer.unwrapDataSets(dataItems, [], [], result);
                    if (Array.isUseful(data)) {
                        self.lineStatus = [];
                        data.forEach(line => {
                            self.lineStatus.push({
                                Name: line.target,
                                OperationMode: line.data[0].y.OperationMode,
                                WorkflowStatus: line.data[0].y.WorkflowStatusString,
                                Workorder: line.data[0].y.WorkorderID
                            });
                        });
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        isWorkorderActive(workorder) {
            if (!Object.isUseful(workorder))
                return false;
            let wfState = this.resolveWorkorderWorkflowState(workorder);
            if (wfState && Object.isUseful(this.workflow.States[wfState])) {
                if (!Object.isUseful(this.workflow.States[wfState].AdditionalInfo))
                    return false;
                let isActiveState = this.workflow.States[wfState].AdditionalInfo.IsActiveState;
                return (Object.isUseful(isActiveState) ? isActiveState : false);
            }
            return false;
        },

        handleFunctionCall(...args) {
            let function_name = [...args][0];
            args.shift();
            return this[function_name](...args)
        },

        //At moment, we simplified workorders table visualization, we expect to find either 0 documents (no customization)
        //or a single one with name "default". In the future this could either be made dynamic by integrating page with generic table
        //or just support multiple profiles with selection dropdown
        async loadVisualizationProfile() {
            let customProfile = await this.$settings.load("workordersVisualizationOptions", "default")
            if (customProfile && customProfile.selectedDataItems)
                this.visualizationProfile = customProfile;
            else
                this.visualizationProfile = {
                    selectedDataItems: [
                        {
                            text: this.$gettext("Id"),
                            name: "Id"
                        },
                        {
                            text: this.$gettext("Recipe/Product"),
                            name: "RecipeId"
                        },
                        {
                            text: this.$gettext("Quantity To Produce"),
                            name: 'QuantityToProduce'
                        },
                        {
                            text: this.$gettext("Material"),
                            name: 'Material'
                        },
                        {
                            text: this.$gettext("Lot"),
                            name: 'Lot'
                        }
                    ]
                }
            this.visualizationProfile.selectedDataItems.push({
                text: this.$gettext("Status"),
                name: 'StatusString'
            })
        },

        async loadWorkflow() {
            //TODO this call shall never fail, yet, if happens, for instance due to problems on Workorders-microservice
            // TODO we shall give an error and not show any control or any workorder to disallow any action
            this.workflow = await this.$workflows.getCurrentWorkFlow();
        },

        async loadOperationModes() {
            this.operationModesValues = await this.$settings.loadOperationModesSettings();
            if (Object.isUseful(this.operationModesValues)) {
                if (this.operationModesValues.hasOwnProperty("OperationModes")) {
                    this.operationModes = Object.keys(this.operationModesValues["OperationModes"]).filter(x => x !== "default" && x !== "workFlows");
                }
            }
        },

        async loadWorkOrderForms() {
            this.workorderForms.clear();
            let self = this;
            this.$dynamicElements.LoadItems("forms", false, true, false, false)
                .then(result => {
                    if (Array.isUseful(result)) {
                        for (let form of result)
                            if (form.properties.type === self.$dynamicElements.Types.FormWorkorder)
                                self.workorderForms.push(form.properties.name);
                    }

                })
                .catch(error => {
                    console.error(error);
                    this.$root.showErrorNotification(this.$gettext("Error in retrieving saved forms from DB."), true);
                })
        },

        async loadSublines() {
            //Get the list of subline descriptors from DB
            let self = this;
            this.$set(this, "sublines", {});
            this.$settings.loadTypeMap(this.$settings.SublinesSettings)
                .then(sublines => {
                    self.$set(self, "sublines", sublines);
                })
                .catch(err => {
                    console.error(err)
                    self.$root.showErrorNotification(self.$gettext("Unable to retrieve sublines info"), true);
                })
        },

        async loadWorkorders() {

            let workorders = [];
            let line = "";
            let newPendingStatus = "";

            if (this.$config.isL2()) {
                line = await this.$settings.getLineSettings().physicalLineId;
                await this.loadCurrentOperationMode();
                await this.getLineInfo(line);
            } else if (this.$config.isL3()) {
                await this.getLinesInfoL3();
            }
            await this.loadWorkorderLineFilters();

            workorders = await this.getWorkordersByStatus();

            if (workorders.length > 0) {

                for (let i = 0; i < workorders.length; i++) {
                    //Check WOs transitions in pending status
                    if (workorders[i].PendingTransitionName)
                        newPendingStatus = workorders[i].PendingTransitionName
                }
                this.workorders = [...workorders]
            } else {
                this.workorders = [];
            }

            this.pendingStatus = newPendingStatus;
        },

        async getWorkordersByStatus() {
            let workorders = [];
            for (const state of this.visualizationStates) {
                let workorder = await this.$workorders.getWorkordersByStatus(state);
                workorders = workorders.concat(workorder);
            }
            return workorders
        },
        styleCell(index) {
            if (index === 0) {
                return "min-width: 200px";
            } else return {
                border: this.type === "custom" ? "1px solid #eee" : "",
            }
        },
        async workorderDetails(data) {
            try {
                data.Transitions = await this.$workorders.getWorkorderLogs(data.Id);
                data.BillOfMaterials = await this.$workorders.getWorkorderBom(data.Id);
            } catch (err) {
                debugger
                console.log(err)
            }
            this.$router.push({name: 'workorderDetails', params: {data: data}})
        },
        itemRowBackground(item) {
            return this.isWorkorderActive(item) ? 'custom-bg' : '';
        },
        customSort(items, index, isDesc) {
            if (Array.isUseful(items)) {
                //The workorder with status running must be the first element
                //Pop the running wo
                let runningWo = items[0];
                if (this.isWorkorderActive(runningWo))
                    items.shift();
                items.sort(function (a, b) {
                    if (!isDesc) {
                        return a[index] < b[index] ? -1 : 1;
                    } else {
                        return b[index] < a[index] ? -1 : 1;
                    }

                });
                //Put the running wo on top
                if (this.isWorkorderActive(runningWo))
                    items.unshift(runningWo);
            }
            return items;
        },

        editWorkorderFields(workorder) {
            this.$workorders.openWorkorderFieldsEditingDialog(this, this.$root.userName, workorder, this.saveModifiedFields);
        },

        changeLineStatus(line, flow) {
            let newStatus = flow.Destination;
            this.$root.showDialogBox(this.$gettext("Are you sure you want to set this line to {0} status?").format(newStatus), null, this.$gettext("Yes"), () => {
                this.callBack(line, flow)
            }, this.$gettext("No"), null);
        },

        async callBack(line, flow) {
            this.statusExpanded = false;
            this.operationModeExpanded = false;
            let workorder = await this.$workorders.get(line.Workorder);
            workorder.RequestedTransition = flow.Id;
            let physicalLineId = "";
            if (this.$config.isL2())
                physicalLineId = await this.$settings.getLineSettings().physicalLineId;
            if (this.$config.isL3())
                physicalLineId = workorder.PhysicalLineId;

            this.executeStateTransition(workorder, physicalLineId, flow);
        },

        async saveModifiedFields(workorder, line, listForAuditsTrail, workorderValues) {
            let workorderId = workorder.Id;
            let message = "";
            let self = this;

            this.$workorders.updateWorkorder(workorderId, workorderValues)
                .then(async () => {
                    await self.loadWorkorders();
                    message = self.$config.isL3() ? self.$gettext("Saved changes for workorder {0} in line {1}").format(workorder.Id, workorder.PhysicalLineId) : self.$gettext("Saved changes for workorder {0}").format(workorder.Id);
                    self.$root.showNotification(message, true, false);
                    listForAuditsTrail.forEach((value, key) => {
                        self.$audits.save(self.$root.userName, self.$audits.items().changeWorkorderFields, value.old, value.new, line + "." + workorder.Id + "." + key)
                            .catch(() => {
                                debugger
                                self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                            });
                    });
                })
                .catch(err => {
                    debugger
                    message = self.$config.isL3() ? self.$gettext("An error occurred while saving changes for workorder {0} in line {1}").format(workorder.Id, workorder.PhysicalLineId) : self.$gettext("An error occurred while saving changes for workorder {0}").format(workorder.Id);
                    self.$root.showErrorNotification(message, true, false);

                })
        },

        async executeStateTransition(workorder, lineId, flow) {
            let self = this;
            if (!Object.isUseful(workorder.StatusString))
                return;
            let workOrderToSave = self.$utils.detach(workorder);
            delete workOrderToSave.flows;
            this.$workorders.requestWorkorderTransition(workOrderToSave, lineId)
                .then(async () => {
                    console.log("State transition requested on {0}, transition {1}".format(workOrderToSave.Id, flow.Id))
                    //Avoid reloading workorders immediately as it will temporary clear pending if triggers too quickly
                    if (self.interval) {
                        clearInterval(self.interval);
                        self.interval = null;
                        self.interval = setInterval(async () => {
                            await self.loadWorkorders();
                        }, 5000)
                    }
                    self.pendingStatus = flow.Id;
                })
                .catch(err => {
                    debugger
                    self.pendingStatus = "";
                    let message = self.$gettext("An error occurred while executing transition {0} for workorder {1} on line {2}").format(flow.Id, workorder.Id, workorder.PhysicalLineId);
                    if (workorder.hasOwnProperty("Transition")) {
                        if (workorder.Transition[workorder.Transition.length - 1].hasOwnProperty("CommandError")) {
                            let error = workorder.Transition[workorder.Transition.length - 1].CommandError;
                            //TODO better error messaging from backend
                            if (error.includes("cannot retrieve recipe"))
                                message = self.$gettext("Cannot retrieve recipe {0} for workorder {1} on line {2}").format(workorder.RecipeId, workorder.Id, workorder.PhysicalLineId);
                        }
                    }
                    self.$root.showErrorNotification(message, true, false);
                    console.error(err)
                })
                .finally(async () => {
                    if (!self.pendingStatus) {
                        await self.loadWorkorders();
                    }
                })
        },
        async loadCurrentOperationMode() {
            let self = this;
            this.$workorders.getCurrentOperationMode()
                .then((result) => {
                    self.currentOperationMode = Object.keys(result).length > 0 ? result : null;
                    if (Object.isUseful(self.currentOperationMode)) {
                        self.operationMode = self.currentOperationMode.name;
                        self.initialStateOperationMode = 1;
                    } else {
                        self.operationMode = "";
                        self.initialStateOperationMode = 0;
                    }
                    if (self.initialStateOperationMode === 0 && self.operationModes.length === 1) {
                        self.operationModes.unshift(self.$gettext("No operation mode"));
                    }
                })
                .catch(err => {
                    debugger
                    self.initialStateOperationMode = 0;
                    self.$root.showErrorNotification(self.$gettext("An error occurred while loading current operation mode"), true, false);
                })
        },
        saveOperationMode(operationMode) {
            this.operationMode = operationMode;
            let self = this;
            this.$workorders.saveOperationMode(this.operationMode, this.operationModesValues)
                .then(() => {
                    self.$audits.save(self.$root.userName, self.$audits.items().productionManagementCurrentOperationModeChange,
                        Object.isUseful(self.currentOperationMode) ? self.currentOperationMode.name : "", self.operationMode, "")
                        .catch(() => {
                            debugger
                            self.$root.showErrorNotification(self.$gettext("An error occurred while saving audits to DB"), true);
                        });
                })
                .catch(() => {
                    debugger
                    self.$root.showErrorNotification(self.$gettext("An error occurred while saving current operation mode"), true, false);

                })
                .finally(async () => {
                    await this.loadCurrentOperationMode();
                    await this.loadOperationModes();
                });
        },
        async newWorkorder() {
            if (this.workorderForms.length > 1) {
                let self = this;
                this.$workorders.openWorkorderFormSelectionDialog(this, this.$root.userName, this.workorderForms,
                    (form) => {
                        if (form) self.$router.push({
                            name: 'forms',
                            params: {routeId: form, backPath: {name: "workordersPage"}}
                        })
                    });
            } else {
                this.$router.push({
                    name: 'forms',
                    params: {routeId: this.workorderForms[0], backPath: {name: "workordersPage"}}
                });
            }
            await this.loadWorkorders();
        },
        async loadWorkorderLineFilters() {
            this.lineFilters = [];
            this.workorders.map(wo => {
                if (!this.lineFilters.includes(wo.PhysicalLineId)) {
                    this.lineFilters.push(wo.PhysicalLineId);
                }
            });
            this.lineFilters.unshift("All");
        },
        resolveWorkorderWorkflowState(wo) {
            let workflowState = '';
            if (wo.WorkflowStatusString) {
                workflowState = wo.WorkflowStatusString;
                if (!(this.workflow.States[workflowState])) {
                    console.error("Unable to resolve workflow state: " + workflowState);
                    //TODO evaluate prompting error to user since this could be temporary
                    workflowState = "";
                }
                return workflowState;
            }

            //handle planned and created state
            if (this.workflow.InternalStates[workflowState])
                return '';

            if (wo.StatusString) {
                let states = this.workflow.States;
                for (const state in states) {
                    if (states[state].AdditionalInfo.Actions && states[state].AdditionalInfo.Actions.Workorder && states[state].AdditionalInfo.Actions.Workorder.WorkOrderStatusString === wo.StatusString)
                        return state
                }
            }

            return workflowState;
        },
        /* Line status handling */
        async getPossibleLineFlows(line) {
            let lineStatus = line.WorkflowStatus;
            if (!lineStatus)
                return null;
            return await this.$workflows.getPossibleFlowsByState(lineStatus);
        },
        async getAllowedLineFlows(lineId) {
            return await this.$workflows.getAllowedLineFlows(lineId);
        },

        async getLineInfo(lineId) {
            let lineStatus = [];
            let lineInfo = await this.$workorders.getLineInfo(lineId);
            lineStatus.push(lineInfo);
            for (let subline in this.sublines) {
                let sublineInfo = await this.$workorders.getLineInfo(lineId, subline);
                sublineInfo.isSubline = true;
                lineStatus.push(sublineInfo)
            }
            lineStatus.forEach(async (line, index) => {
                if (line.Workorder) {
                    line.actionsGroup = {
                        icon: 'fa-exchange',
                        text: this.$gettext('change status'),
                        color: 'blue',
                        children: []
                    };
                    if (this.isLineActive(line)) {
                        await this.getAllowedLineFlows(line.Workorder)
                            .then(allowedFlows => {
                                this.remapFlowChildrenObject(allowedFlows, line)
                            })
                    } else {
                        await this.getPossibleLineFlows(line)
                            .then(allowedFlows => {
                                this.remapFlowChildrenObject(allowedFlows, line)
                            })
                    }
                }
                this.$set(this.lineStatus, index, line);
            })
        },
        remapFlowChildrenObject(possibleFlows, workorder) {
            if (Array.isUseful(possibleFlows)) {
                workorder.flows = possibleFlows;
                for (let flow of possibleFlows) {
                    let childrenObj = {
                        icon: this.icons[flow.Properties.ShowAction.toLowerCase()].icon,
                        text: flow.Properties.Name,
                        color: this.icons[flow.Properties.ShowAction.toLowerCase()].color,
                        handler: 'changeLineStatus',
                        handlerInfo: {
                            flow: flow
                        }
                    };
                    workorder.actionsGroup.children.push(childrenObj)
                }
                if (workorder.actionsGroup.children.length === 1) {
                    workorder.actionsGroup = {...workorder.actionsGroup.children[0]};
                    workorder.actionsGroup.children = []
                }
            }
        },
        isLineActive(lineInfo) {
            if (!Object.isUseful(lineInfo))
                return false;
            let lineStatus = '';
            if (lineInfo.WorkflowStatus) {
                lineStatus = lineInfo.WorkflowStatus
            } else {
                return false;
            }

            if (lineStatus && Object.isUseful(this.workflow.States[lineStatus])) {
                if (!Object.isUseful(this.workflow.States[lineStatus].AdditionalInfo))
                    return false;
                let isActiveState = this.workflow.States[lineStatus].AdditionalInfo.IsActiveState;
                return (Object.isUseful(isActiveState) ? isActiveState : false);
            }
            return false;
        },
        async resetLine(line) {
            this.$root.showDialogBox(this.$gettext("Are you sure you want to reset line status to idle? This will reset {appName} to idle and shall only be executed to recover hard misalignments between {appName} and line states. Be sure that all machines are idle to prevent further misalignments.").format({appName: this.$config.appName}), null,
                this.$gettext("Yes"), async () => {
                    await this.$workorders.resetLine(line.Name);
                    this.lineStatus = this.lineStatus.filter(item => item.Workorder !== line.Workorder)
                },
                this.$gettext("No"), null);
        },
        async assignWorkorder(workorder) {
            this.selectedLineToAssign = "";
            this.selectedWorkorderToAssign = workorder;
            if (Array.isUseful(this.assignableLines)) { //Redundant, button shall be disabled, we shouldn't get here
                if (this.assignableLines.length > 1)
                    this.selectLineDialog = true;
                else this.selectLine(true)
            }
        },
        async cancelWorkorder(workorder) {
            //alessio inserisci messaggio di conferma
            this.$root.showDialogBox(this.$gettext('Are you sure you want to reject workorder {0}?').format(workorder.Id), null, this.$gettext("Yes"), ()=>{
                this.$workorders.rejectWorkorder(workorder.PhysicalLineId,workorder.Id)
            }, this.$gettext("No"), null)
        },
        async restoreWorkorder(workorder) {
            //alessio inserisci messaggio di conferma
            this.$root.showDialogBox(this.$gettext('Are you sure you want to restore workorder {0}?').format(workorder.Id), null, this.$gettext("Yes"), ()=>{
                this.$workorders.recoverWorkorder(workorder.PhysicalLineId,workorder.Id)
            }, this.$gettext("No"), null)
        },

        async selectLine(result) {
            this.selectLineDialog = false;
            if (result) {
                let subline = "";
                if (this.selectedLineToAssign && this.lineStatus.find(line => line.Name === this.selectedLineToAssign).isSubline)
                    subline = this.selectedLineToAssign;
                await this.$workorders.assignWorkorderToLine(this.selectedWorkorderToAssign.PhysicalLineId, this.selectedWorkorderToAssign.Id, subline);
                await this.getLineInfo(this.selectedWorkorderToAssign.PhysicalLineId);
            }
        }
    },
}
</script>

<style scoped>
.custom-bg {
    background-color: lightgreen;
}

.v-btn:before {
    opacity: 0 !important;
}

.v-ripple__container {
    opacity: 0 !important;
}
</style>
